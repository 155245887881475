/* eslint-disable max-len */

import React from 'react'
import { createPortal } from 'react-dom'

import DeleteSiteUI from './DeleteSiteUI'

type OwnProps = {
    childOf?: React.ReactElement
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DeleteSite.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'DeleteSite' implicitly has type 'any' because it ... Remove this comment to see the full error message
const DeleteSite = ({ childOf, ...props }: Props) =>
    childOf ? createPortal(<DeleteSiteUI {...props} />, childOf) : <DeleteSiteUI {...props} />

DeleteSite.defaultProps = {
    childOf: null,
}

export default DeleteSite
