import { format, subMonths, isAfter, parseISO, differenceInCalendarMonths, startOfMonth } from 'date-fns'

export const getDateOptions = (getDateForLastMonth: Function | null, initialDate: Date | null) => {
    const initDate = initialDate ? initialDate : parseISO('2019-03-01')
    let getMonthsThroughThisDate = startOfMonth(new Date())

    if (getDateForLastMonth) {
        getMonthsThroughThisDate = getDateForLastMonth()
    }

    const numberOfMonthsFromInit = differenceInCalendarMonths(getMonthsThroughThisDate, initDate)
    let dateOptions: any = []
    let nextDateValue = getMonthsThroughThisDate

    // eslint-disable-next-line
    for (let i = 0; i < numberOfMonthsFromInit; i++) {
        if (isAfter(nextDateValue, initDate)) {
            dateOptions = [
                ...dateOptions,
                { value: format(nextDateValue, 'yyyy-MM-dd'), label: format(nextDateValue, 'MMM yyyy') },
            ]
        }
        // subtract one month
        nextDateValue = subMonths(nextDateValue, 1)
    }

    return dateOptions
}
