import React from 'react'

import styles from './disclaimer.module.scss'

const Disclaimer: React.FC<{ isSiteStats?: boolean }> = ({ isSiteStats }) => (
    <div className={styles.disclaimer}>
        <span>DISCLAIMER:</span> The data you are currently seeing is an estimate of your traffic only. Data is
        updated daily at 3pm Eastern time for the previous day‘s traffic. Please do not rely on this for a final
        payment calculation.
        {isSiteStats &&
            ' Only active contributors (within 60 days) are displayed in the Site Stats table, but all site views and page views are counted.'}{' '}
        Tier 4 only applies to site experts on soccer sites.
    </div>
)

export default Disclaimer
