import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import LogRocket from 'logrocket'

import PayoneerModal from './ui/PayoneerModal'
// import MaintenanceModal from './ui/MaintenanceModal'
import HeaderNav from './ui/HeaderNav'
import Loading from '../../framework/Loading'
import { useAuth0 } from '../../../react-auth0-spa'
import { getCurrentUser } from '../../../api/global'
import GAPageView from '../../utils/GAPageView'

// @ts-expect-error ts-migrate(2614) FIXME: Module '"*.module.scss"' has no exported member 'l... Remove this comment to see the full error message
import { layout } from './authorizedLayout.module.scss'

type Props = {
    component: ((...args: any[]) => any) | any
    loading: boolean
}

const AuthorizedLayout = ({ component: Component, loading, ...props }: Props) => {
    const location = useLocation()
    const [tokenLoaded, setTokenLoaded] = useState(false)

    /**
     * Get and set JWT from Auth0
     */
    const { getTokenSilently, user } = useAuth0()
    const { name, sub, email } = user || {}

    /**
     * Get current user data
     */
    const { isLoading: currentUserLoading, data = {} } =
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'globalState' does not exist on type 'Def... Remove this comment to see the full error message
        useSelector(({ globalState }) => globalState.currentUser) || {}

    useEffect(() => {
        const cb = async () => {
            const token = await getTokenSilently()
            localStorage.setItem('fs_app_token', token)

            if (!currentUserLoading && data?.id !== sub) {
                await getCurrentUser()
            }

            setTokenLoaded(true)
        }

        if (!loading) {
            cb()
        }
    }, [currentUserLoading, data, getTokenSilently, loading, sub])

    useEffect(() => {
        if (user && process.env.NODE_ENV === 'production') {
            /**
             * Identify LogRocket User
             */
            LogRocket.identify(sub, {
                email,
                name,
            })

            /**
             * Add a LogRocket session URL to every HelpScout support request:
             */
            LogRocket.getSessionURL((sessionURL) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'Beacon' does not exist on type 'Window &... Remove this comment to see the full error message
                window.Beacon &&
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'Beacon' does not exist on type 'Window &... Remove this comment to see the full error message
                    // eslint-disable-next-line
                    window.Beacon('identify', {
                        email,
                        name,
                        sessionURL,
                    })
            })
        }
    }, [email, name, sub, user])

    /**
     * Get Nav title from microservice path name
     */
    const microservice = loading ? '' : location.pathname.split('/')[1]

    const makeUI = () => {
        if (loading || currentUserLoading) {
            return <Loading loading={loading || currentUserLoading} />
        }

        return (
            <>
                <Component {...props} tokenLoaded={tokenLoaded} />
                <PayoneerModal />
                {/* <MaintenanceModal /> */}
            </>
        )
    }

    return (
        <>
            <GAPageView pageIdentifier={location.pathname} />
            {/* @ts-expect-error ts-migrate(2322) FIXME: Property 'title' does not exist on type '(Intrinsi... Remove this comment to see the full error message */}
            <HeaderNav title={microservice} />
            <div className={layout}>{makeUI()}</div>
        </>
    )
}

export default AuthorizedLayout
