import { CAN_MANAGE_INVOICES, CAN_AUDIT_INVOICES } from '../../lib/utils/permissions'

export const statusOptions = [
    { value: 'all', label: 'Any Status', permission: CAN_MANAGE_INVOICES },
    { value: 'flagged', label: 'Flagged', permission: CAN_AUDIT_INVOICES },
    { value: 'ineligible', label: 'Ineligible', permission: CAN_MANAGE_INVOICES },
    { value: 'error', label: 'Error', permission: CAN_MANAGE_INVOICES },
    { value: 'pending', label: 'Pending', permission: CAN_MANAGE_INVOICES },
    { value: 'audited', label: 'Audited', permission: CAN_AUDIT_INVOICES },
    { value: 'approved', label: 'Approved', permission: CAN_MANAGE_INVOICES },
    { value: 'export', label: 'Export', permission: CAN_MANAGE_INVOICES },
    { value: 'processing', label: 'Processing' },
    { value: 'submitted', label: 'Submitted' },
    { value: 'no_account', label: 'No Account' },
    { value: 'paid', label: 'Paid', permission: CAN_MANAGE_INVOICES },
]
