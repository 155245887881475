import React from 'react'

import { isAuthorized } from '..'
import NavItem from './NavItem'
import store from '../../../store'

type OwnProps = {
    navItems: {
        label: string
        path: string
        icon?: string
    }[]
    className?: string
    activeClassName?: string
    textClass?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof BuildNavItems.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'BuildNavItems' implicitly has type 'any' because ... Remove this comment to see the full error message
export const BuildNavItems = ({ navItems, className, activeClassName, textClass, iconSize }: Props) => {
    if (!navItems) return null

    const url = window.location.href

    const items = navItems.reduce(
        (acc: any, { label, path, icon, permission, showOn = '', showNotOn = '' }: any) => {
            // Use the last url of the users table to maintain filtering
            const getPath = () => {
                if (label === 'Users' || label === 'Manage Users') {
                    return store.getState().userState.currentUsersTableUrl
                }

                return path
            }

            const getDisplayLabel = () => {
                if (showOn && !url.includes(showOn)) {
                    return null
                }

                if (showNotOn && url.includes(showNotOn)) {
                    return null
                }
                return label
            }

            const displayLabel = getDisplayLabel()

            if (!displayLabel) return acc

            if (permission) {
                return isAuthorized({ permission })
                    ? [
                          ...acc,
                          <NavItem
                              className={className}
                              path={getPath()}
                              activeClassName={activeClassName}
                              icon={icon}
                              iconSize={iconSize}
                              textClass={textClass}
                              displayLabel={displayLabel}
                              key={getPath()}
                              data-testid={displayLabel}
                          />,
                      ]
                    : acc
            }

            return [
                ...acc,
                <NavItem
                    className={className}
                    path={getPath()}
                    activeClassName={activeClassName}
                    icon={icon}
                    iconSize={iconSize}
                    textClass={textClass}
                    displayLabel={displayLabel}
                    key={getPath()}
                    data-testid={displayLabel}
                />,
            ]
        },
        [],
    )

    return items
}

BuildNavItems.defaultProps = {
    className: '',
    activeClassName: '',
    textClass: '',
    iconSize: null,
}

export default BuildNavItems
