/**
 * Deprecated; too complex.
 */

import axios from 'axios'
import { http } from '..'
import handleError from '../handleError'

let cancel: any
let prevReq: any

/**
 * Automatically cancel previous request if another is made.
 * All params call anonymous functions except loadingFunc.
 *
 * @param {func} [apiMethodFunc] API method to call. Usable within components.
 * If not provided, will build request using axiosInstance, method, requestUrl, and config.
 * @param {func} [axiosInstance = http] Instance to use.
 * @param {'get'|'delete'|'post'|'put'} [method = 'get'] API method to call. Usable within components
 * @param {string} [requestUrl] Url to call
 * @param {object} [requestConfig] Optional axios config
 * @param {func} [thenAlwaysDo] What to always do on promise success
 * @param {func} [isCancel] Function created in useCancellableEffect hook which returns a bool.
 * Used to avoid setting state after request.
 * @param {func} [cancelInstanceSetter] useState setter func. Can be used instead of isCancel
 * if desire is to actually cancel the request.
 * @param {func} [thenDoIfNotCancelled] What is do on promise success if not canceled via isCancel
 * @param {func} [loadingFunc] State setter func
 * @param {func} [finallyFunc] What to do always at the end of promise chain
 *
 */
const makeRequest = ({
    apiMethodFunc,
    axiosInstance = http,
    method = 'get',
    requestUrl,
    requestConfig = {},
    thenAlwaysDo,
    isCancel = null,
    cancelInstanceSetter,
    thenDoIfNotCancelled,
    loadingFunc,
    errorFunc,
    finallyFunc,
}: any) => {
    const doRequest = async () => {
        const methodAndCall = `${method} - ${requestUrl}`

        if (prevReq === methodAndCall && cancel) {
            cancel.cancel('Request cancelled')
        }

        cancel = axios.CancelToken.source()
        prevReq = methodAndCall

        if (cancelInstanceSetter) {
            cancelInstanceSetter(cancel)
        }

        loadingFunc && loadingFunc(true)

        const handlePromiseResponse = async (promise: any) =>
            promise
                .then(async (res: any) => {
                    thenAlwaysDo && thenAlwaysDo(res)

                    if (isCancel && isCancel()) return false

                    if (thenDoIfNotCancelled) {
                        await thenDoIfNotCancelled(res)
                    }

                    if (isCancel && !isCancel()) {
                        return res
                    }

                    return res
                })
                .catch((e: any) => {
                    if (axios.isCancel(e)) {
                        console.log(e.message) // eslint-disable-line
                        return e
                    }

                    return (
                        ((isCancel && !isCancel()) || true) &&
                        ((loadingFunc && loadingFunc(false)) || true) &&
                        errorFunc &&
                        errorFunc(e)
                    )
                })
                .finally(
                    () =>
                        ((isCancel && !isCancel()) || true) &&
                        ((loadingFunc && loadingFunc(false)) || true) &&
                        finallyFunc &&
                        finallyFunc(),
                )

        if (apiMethodFunc) {
            try {
                const res = apiMethodFunc()
                return handlePromiseResponse(res)
            } catch (e) {
                handleError(e, loadingFunc, true)
            }
        }

        const res = axiosInstance({
            method,
            url: requestUrl,
            cancelToken: cancel.token,
            ...requestConfig,
        })

        return handlePromiseResponse(res)
    }

    return doRequest()
}

export default makeRequest
