import { parse } from 'url'

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../__mocks__/users' or its ... Remove this comment to see the full error message
import users from '../../../__mocks__/users'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../__mocks__/roles' or its ... Remove this comment to see the full error message
import roleList from '../../../__mocks__/roles'

/**
 * Utils
 */

const getDemoUsers = (url: any) => {
    const { pathname } = parse(!url.includes('://') ? `http://${url}` : url)

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (pathname.includes('users')) return users

    return { error: 'users not found' }
}

const getDemoRoles = (url: any) => {
    const { pathname } = parse(!url.includes('://') ? `http://${url}` : url)

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    if (pathname.includes('roles')) return roleList

    return { error: 'Roles not found' }
}

/**
 * Demo Adapter (For Axios)
 */

export const demoAdapter = async (config: any) => {
    let data = {}
    const status = 200
    const { url } = config

    if (url === '/users') {
        data = getDemoUsers(url)
    } else if (url === '/roles') {
        data = getDemoRoles(url)
    } else {
        data = await fetch(url)
    }

    return {
        data,
        status,
        statusText: 'OK',
        headers: {
            server: 'nginx/1.15.1',
            date: 'Mon, 10 Sep 2018 15:32:17 GMT',
            'content-type': 'text/html; charset=UTF-8',
            'transfer-encoding': 'chunked',
            connection: 'close',
            'x-powered-by': 'PHP/7.0.30',
        },
        config,
        request: {},
    }
}
