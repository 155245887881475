import React from 'react'
/* eslint-disable */
// Uncomment commented sections to have icon in a circle
const SvgSubmittedCircle = (props: any) => <svg viewBox="0 0 24 24" {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    {/* <path d="M12 0A12 12 0 1 1 0 12 12 12 0 0 1 12 0zm0 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2z" /> */}
    <path
        d="M19.35 10.04A7.49 7.49 0 0012 4C9.11 4 6.6 5.64 5.35 8.04A5.994 5.994 0 000 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM10 17l-3.5-3.5 1.41-1.41L10 14.17 15.18 9l1.41 1.41L10 17z"
        //  transform="scale(.73) translate(4.3 3.62)"
    />
</svg>

export default SvgSubmittedCircle
