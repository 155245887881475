import ReactGA from 'react-ga'

const GA_ID = 'UA-27080047-52'

let initialized = false

export const initialize = () => {
    if (initialized) return

    /**
     * Run GA in debug mode if `?gaDebug` query parameter exists
     * This provides detailed logging in the console
     */
    const debug = window.location.search.toLowerCase().includes('gadebug')

    ReactGA.initialize(GA_ID, {
        debug,
        gaOptions: {
            name: 'adminAppTracker',
        },
    })

    initialized = true
}

export const pageView = ({ currentUserId }: { currentUserId: string }) => {
    if (!initialized) return

    const gaSet = (key: string, value: any, trackers: string[]) => {
        ReactGA.set({ [key]: value ? String(value) : null }, trackers)
    }

    gaSet('dimension1', currentUserId, ['adminAppTracker'])
    gaSet('dimension2', currentUserId, ['adminAppTracker'])
    gaSet('dimension3', currentUserId, ['adminAppTracker'])

    if (typeof window !== 'object') return

    const reportedPath = window.location.pathname + window.location.search

    ReactGA.pageview(reportedPath, ['adminAppTracker'])
}
