/* eslint-disable */
import React from 'react'

type OwnProps = {
    fill?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Payments.defaultProps;
/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7022) FIXME: 'Payments' implicitly has type 'any' because it do... Remove this comment to see the full error message
const Payments = ({ fill, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 29 19.7" fill={fill} {...rest}>
        <path
            d="M26.9,0H2.1C0.9,0,0,0.9,0,2.1v1h29v-1C29,0.9,28.1,0,26.9,0z M0,17.6c0,1.1,0.9,2.1,2.1,2.1h24.9
			c1.1,0,2.1-0.9,2.1-2.1V7.2H0V17.6z M22.8,13.5h3.1v3.1h-3.1V13.5z M3.6,13.5h9.3c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5
			H3.6c-0.3,0-0.5-0.2-0.5-0.5C3.1,13.7,3.3,13.5,3.6,13.5z M3.6,15.5h5.2c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5H3.6
			c-0.3,0-0.5-0.2-0.5-0.5S3.3,15.5,3.6,15.5z"
        />
    </svg>
)

Payments.defaultProps = {
    fill: '',
}

export default Payments
