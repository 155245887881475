import axios from 'axios'

// For use with payments
const paymentsHttp = axios.create({
    baseURL: `${
        window.location.host.includes('localhost') ? `http://localhost:${process.env.REACT_APP_PAYMENTS_PORT}` : ''
    }/payments/api`,
})

// Middleware Request
paymentsHttp.interceptors.request.use((req) => {
    // Auth Token
    const token = localStorage.getItem('fs_app_token')
    if (token) {
        req.headers = {
            ...req.headers,
            Authorization: `Bearer ${token}`,
        }
    }
    return req
})

// Middleware Response
paymentsHttp.interceptors.response.use(
    (response) => response,
    (error) => {
        if (!error.response) return Promise.reject(error.message)
        const { status, data } = error.response

        // eslint-disable-next-line
        return Promise.reject({ status, message: data.msg })
    },
)

export default paymentsHttp
