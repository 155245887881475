import React, { Suspense } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LogRocket from 'logrocket'

// import Loading from '../ui/Loading'
import store from '../store'
import { Router, BuildRoutes, ScrollToTop, ErrorBoundary, SuspenseComponent } from '../lib/utils'
import GAInit from '../lib/utils/GAInit'

import { Auth0Provider } from '../react-auth0-spa'
import config from '../auth_config'

if (window.location.href.includes('app.fansided')) {
    LogRocket.init('8xihre/fs-app-console')
}

// Sets up container for react-toastify alerts
toast.configure({
    autoClose: 10000,
})

/**
 * Sets the initial loaded URL in local storage to be used by Auth0 redirect
 */
const setInitialUrl = () => {
    // This should avoid capturing the Auth0 authorized redirect URL
    if (window.location.href.includes('?code=')) {
        return
    }
    window.localStorage.setItem('fs_initial_url', window.location.href)
}
setInitialUrl()

/**
 * Gets the initial URL the app was loaded on
 * @returns {string | null}
 */
const getInitialUrl = () => {
    const url = window.localStorage.getItem('fs_initial_url')

    if (!url) {
        return null
    }

    window.localStorage.removeItem('fs_initial_url')

    const initialUrl = new URL(url)

    return { pathname: initialUrl.pathname, search: initialUrl.search }
}

const App = () => (
    <ReduxProvider store={store}>
        <Router>
            <Auth0Provider
                domain={config.domain}
                client_id={config.clientId}
                connection={config?.connection}
                audience={config.audience}
                redirect_uri={window.location.origin}
                getInitialUrl={getInitialUrl}
            >
                <ScrollToTop>
                    <ErrorBoundary>
                        <GAInit />
                        {/* We can control which components are lazy-loaded at the route level by
                        using React.lazy in our navItem objects. */}

                        {/* On reasonably fast connections, putting Loading
                         as the fallback here results in a flash of the loading component.
                         It looks cleaner to show nothing instead.
                         Once React comes out with maxDuration prop equivalent, we can leverage that. */}
                        <Suspense fallback={<SuspenseComponent />}>
                            <BuildRoutes />
                        </Suspense>
                    </ErrorBoundary>
                </ScrollToTop>
            </Auth0Provider>
        </Router>
    </ReduxProvider>
)

export default App
