import React, { useEffect, useState, useRef } from 'react'
import cx from 'classnames'

import Icon from '../../../../../../../lib/framework/Icon'
import { smartTrimWithEllipses } from '../../../../../../../lib/utils'
import { Button } from '../../../../../../../lib/framework'
import styles from './editableInputField.module.scss'

type OwnProps = {
    value?: string | number
    type?: string
    step?: string
    icon?: string
    siteId?: string
    iconFill?: string
    className?: string
    inputClassName?: string
    maxLength?: number
    handleSubmit: (...args: any[]) => any
    disabled?: boolean
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof EditableInputField.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'EditableInputField' implicitly has type 'any' bec... Remove this comment to see the full error message
const EditableInputField = ({
    value,
    type,
    className,
    siteId,
    inputClassName,
    handleSubmit,
    icon,
    iconFill,
    maxLength,
    disabled,
}: Props) => {
    const [editing, setEditing] = useState(false)
    const [newValue, setNewValue] = useState(value)
    const [submitting, setSubmitting] = useState(false)
    const inputRef = useRef()

    useEffect(() => {
        setNewValue(value)
    }, [value])

    useEffect(() => {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        inputRef.current && inputRef.current.select() // eslint-disable-line
    }, [editing])

    const submit = async (e: any) => {
        e.preventDefault()

        if (submitting === true) return
        // Prevents making an api call if nothing changes in input field
        if (value === newValue) return
        setSubmitting(true)

        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        inputRef.current.blur()

        await handleSubmit(value, newValue, setNewValue, siteId).finally(() => setSubmitting(false))
    }

    const startEditing = () => setEditing(true)

    return (
        <>
            {!editing ? (
                <Button
                    linkStyle
                    disabled={submitting || disabled}
                    className={cx(styles.plainMode, className && className)}
                    /**
                     * Need both event handlers because Safari and Firefox don't focus buttons on click
                     */
                    /* eslint-disable */
                    //  c.f. https://stackoverflow.com/questions/42758815/safari-focus-event-doesnt-work-on-button-element
                    /* eslint-enable */
                    // @ts-expect-error ts-migrate(2322) FIXME: Property 'onFocus' does not exist on type 'Intrins... Remove this comment to see the full error message
                    onFocus={startEditing}
                    onClick={startEditing}
                >
                    {maxLength ? smartTrimWithEllipses(newValue, maxLength) : newValue}
                    {icon && !disabled && <Icon icon={icon} fill={iconFill} size={0.7} className={styles.icon} />}
                </Button>
            ) : (
                <form onSubmit={submit} className={styles.form}>
                    <input
                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'HTMLIn... Remove this comment to see the full error message
                        ref={inputRef}
                        className={cx(styles.input, inputClassName && inputClassName)}
                        type={type}
                        value={newValue}
                        disabled={submitting}
                        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                        onKeyDown={(e) => e.key === 'Enter' && inputRef.current.blur()}
                        onBlur={(e) => {
                            setEditing(false)
                            submit(e)
                        }}
                        onChange={(e) => {
                            setNewValue(type !== 'number' ? e.target.value : parseFloat(e.target.value))
                        }}
                    />
                </form>
            )}
        </>
    )
}

EditableInputField.defaultProps = {
    value: null,
    className: '',
    inputClassName: '',
    siteId: null,
    type: 'text',
    icon: null,
    iconFill: '#999',
    maxLength: null,
    disabled: false,
}

export default EditableInputField
