import { createReducer, createAction } from 'redux-act'

import store from '../store'
import { setDateOptions } from '../lib/utils/storeUtils'

const actions = {
    setExpanded: createAction('SET_EXPANDED'),
}
const initialState = {
    dateOptions: setDateOptions(),
    expanded: {},
}

export default createReducer(
    {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'setDateOptions' does not exist on type '... Remove this comment to see the full error message
        [actions.setDateOptions]: (state, dateOptions) => ({
            ...state,
            dateOptions,
        }),
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [actions.setExpanded]: (state, expanded) => ({
            ...state,
            expanded,
        }),
    },
    initialState,
)

/**
 * Setters
 */

// @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
export const setExpanded = (expanded: any) => store.dispatch(actions.setExpanded(expanded))
