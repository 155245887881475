/**
 *
 *
 * @param {string} str
 * @param {number} maxLen
 * @param {string} [separator=' ']
 * @returns
 */
const smartTrimWithEllipses = (str: any, maxLen: any, separator = ' ') => {
    if (!str || str.length <= maxLen) return str
    let subStr = str.substr(0, str.lastIndexOf(separator, maxLen))

    if (!subStr.length) {
        subStr = str.substr(0, maxLen)
    }

    return subStr.concat('...')
}

export default smartTrimWithEllipses
