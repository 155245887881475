import {
    setSiteList,
    setVoltaxSitesList,
    setSiteListLoading,
    setRoleList,
    setRoleListLoading,
    setPermissionList,
    setPermissionListLoading,
    setCurrentUser,
    setCurrentUserLoading,
} from '../state/globalState'
import { http, handleError } from '../lib/utils'
import { createVoltaxList } from './utils/createVoltaxList'

export const getCurrentUser = async (loading = true) => {
    try {
        setCurrentUserLoading(loading)
        const res = await http.get('/users/currentUser')

        // @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'AxiosRespo... Remove this comment to see the full error message
        const { user } = res

        setCurrentUser(user)
    } catch (e) {
        return handleError(e, setCurrentUserLoading)
    }
}

export const getSiteList = async () => {
    try {
        setSiteListLoading(true)

        const { data } = await http.get('/sites')

        const modifiedData = data.map(({ blogId: id, logo, name, vertical, url, voltaxSlug }: any) => ({
            id,
            logo,
            name,
            vertical,
            url,
            voltaxSlug,
        }))

        setSiteList(modifiedData)
        setVoltaxSitesList(createVoltaxList(modifiedData))

        return modifiedData
    } catch (e) {
        return handleError(e, setSiteListLoading, false, true)
    }
}

export const getRoleList = async () => {
    try {
        setRoleListLoading(true)
        const res = await http.get('/roles')
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'roles' does not exist on type 'AxiosResp... Remove this comment to see the full error message
        const { roles } = res

        setRoleList(roles)
        return roles
    } catch (e) {
        return handleError(e, setRoleListLoading, false, true)
    }
}

export const getPermissionList = async () => {
    try {
        setPermissionListLoading(true)
        const res = await http.get('/roles/permissions')

        // @ts-expect-error ts-migrate(2339) FIXME: Property 'permissions' does not exist on type 'Axi... Remove this comment to see the full error message
        const { permissions } = res

        setPermissionList(permissions)
        return permissions
    } catch (e) {
        return handleError(e, setPermissionListLoading, false, true)
    }
}
