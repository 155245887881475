import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import SiteStats from './layouts/SiteStats/SiteStats'
import UserStats from './layouts/UserStats/UserStats'
import NotFound from '../lib/layouts/NotFound'

const PaymentsRouter = () => {
    return (
        <>
            <Helmet>
                <title>FS App Console - Payments</title>
            </Helmet>
            <main>
                <Routes>
                    <Route path="site" element={<SiteStats />} />
                    <Route path="site/:siteId" element={<SiteStats />} />
                    <Route path="user" element={<UserStats />} />
                    <Route path="user/:userId" element={<UserStats />} />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </main>
        </>
    )
}

export default PaymentsRouter
