import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import cx from 'classnames'

import api from '../../../api'
import DropdownIndicator from '../DropdownIndicator'
import { Dropdown } from '../../../lib/framework'
import { checkPermsAndFormatOptions, getUserData, customRoleDeleteToast } from '../../../lib/utils'

import { formatCurrentRoles } from '../../../lib/utils/formatCurrentRolesAsDropdownValues'

import styles from './secondaryRoleSelect.module.scss'

type Props = {
    userRoles: UserRoles
    siteId: string
    roleList: IRoleList
    className?: string
    containerClassName?: string
    siteName: string
    userId: string
    isDisabled?: boolean
    isLoading?: boolean
    hideDropdownIndicator?: boolean
}

const SecondaryRoleSelect: React.FC<Props> = ({
    userRoles,
    siteId,
    roleList,
    className,
    containerClassName,
    siteName,
    userId,
    isDisabled,
    isLoading,
    hideDropdownIndicator,
    ...rest
}) => {
    const [loading, setLoading] = useState(false)
    const [selectedUserName, setSelectedUserName] = useState('this user')
    const { firstName, lastName } = getUserData(userId)

    useEffect(() => {
        firstName && lastName && setSelectedUserName(`${firstName} ${lastName}`)
    }, [firstName, lastName])
    /**
     *
     * @param {string} action
     * @param {string} removedValue
     * @param {string} option
     */
    const updateRole = async ({ action, removedValue, option }: any) => {
        const makeRoleObjAndCallApi = async () => {
            setLoading(true)

            if (action === 'remove-value') {
                const roles = Array.of(removedValue.value)

                await api.users.deleteUserRoles({
                    id: userId,
                    roles,
                    siteId,
                    type: 'secondary',
                })
            } else {
                const roles = Array.of(option.value)

                await api.users.addUserRoles({
                    id: userId,
                    roles,
                    sites: [siteId],
                    type: 'secondary',
                })
            }

            setLoading(false)
        }

        if (action === 'remove-value') {
            /**
             * Don't allow deletion of "Base" role.
             */
            if (removedValue.value === 22) return toast.error("Base role can't be deleted")

            customRoleDeleteToast({
                siteName,
                roleName: removedValue.label,
                userName: selectedUserName,
                removeFunction: makeRoleObjAndCallApi,
            })
        } else {
            makeRoleObjAndCallApi()
        }
    }

    // These are the values that display in the input, AKA the user's current secondary roles
    const values = roleList.length
        ? formatCurrentRoles(userRoles, roleList, siteId)
        : [{ label: 'Loading...', value: 'loading' }]

    const getPermittedRoles = checkPermsAndFormatOptions({ roleList, siteId, type: 'secondary' })

    return (
        <Dropdown
            name={`SecondaryRoleSelect - ${siteName}`}
            className={cx(styles.secondaryRoleSelect, className && className)}
            isMulti
            // value takes an array of objects with value and label keys
            value={values}
            options={getPermittedRoles}
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
            onChange={(newRoles, meta) => updateRole(meta, siteName)}
            components={{ ...(!hideDropdownIndicator ? { DropdownIndicator } : { DropdownIndicator: null }) }}
            isLoading={loading || isLoading}
            isDisabled={loading || isDisabled}
            containerClassName={cx(containerClassName && containerClassName)}
            {...rest}
        />
    )
}

SecondaryRoleSelect.defaultProps = {
    className: '',
    containerClassName: '',
    isDisabled: false,
    isLoading: false,
    hideDropdownIndicator: false,
}

const stateToProps = ({ globalState }: any) => ({
    roleList: globalState.roleList.data,
})

export default connect(stateToProps)(SecondaryRoleSelect)
