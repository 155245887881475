import React from 'react'

type OwnProps = {
    fill?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Facebook.defaultProps

/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7022) FIXME: 'Facebook' implicitly has type 'any' because it do... Remove this comment to see the full error message
const Facebook = ({ fill, ...rest }: Props) => (
    <svg
        width="1792"
        height="1792"
        viewBox="0 0 1792 1792"
        xmlns="http://www.w3.org/2000/svg"
        fill={fill}
        {...rest}
    >
        <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z" />
    </svg>
)

Facebook.defaultProps = {
    fill: '',
}

export default Facebook
