import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from '../../../react-auth0-spa'

import AuthorizedLayout from '../../layouts/AuthorizedLayout'

type Props = {
    path: string
    component: ((...args: any[]) => any) | any
}

const AuthenticatedRoute = ({ path, component, ...rest }: Props) => {
    const { loading, isAuthenticated, loginWithRedirect } = useAuth0()

    useEffect(() => {
        if (loading || isAuthenticated) {
            return
        }
        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: path },
            })
        }
        fn()
    }, [loading, isAuthenticated, loginWithRedirect, path])

    return (
        <Route
            element={<AuthorizedLayout loading={loading || !isAuthenticated} component={component} {...rest} />}
            path={path}
            {...rest}
        />
    )
}

export default AuthenticatedRoute
