import React from 'react'

type OwnProps = {
    fill?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Home.defaultProps

/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7022) FIXME: 'Home' implicitly has type 'any' because it does n... Remove this comment to see the full error message
const Home = ({ fill = 'none', ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={fill} {...rest}>
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
)

Home.defaultProps = {
    fill: '',
}

export default Home
