import React from 'react'

import DetailItem from '../DetailItem'
import { numberWithCommas } from '../../utils/format/number'
import styles from './topPosts.module.scss'

type Props = {
    posts: any[]
}

const TopPosts = ({ posts }: Props) => {
    const topPosts = posts.length > 10 ? posts.slice(0, 10) : posts

    return (
        <div className="owl">
            <h3 className={styles.headerExpanded}>Top Posts</h3>
            <hr />
            <div className="owl-small">
                {topPosts.map((post) => (
                    <DetailItem key={post.url} label={post.title} to={post.url}>
                        {numberWithCommas(post.views)}
                    </DetailItem>
                ))}
            </div>
        </div>
    )
}

export default TopPosts
