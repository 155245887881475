import React, { Children } from 'react'
import cx from 'classnames'

import styles from './frame.module.scss'

type Props = {
    children: React.ReactNode[] | React.ReactNode
    className?: string
    textClassName?: string
    heading?: string
}

const Frame: React.FC<Props> = ({
    children: childrenWithPossibleInvalidElements,
    className,
    textClassName,
    heading,
    ...rest
}) => {
    const children = [childrenWithPossibleInvalidElements].flat().filter(Boolean)

    const numberOfChildren = Children.count(children)

    if (numberOfChildren === 1)
        return (
            <div className={cx(styles.frame, styles.oneChild, className && className)} {...rest}>
                {heading && <h2 className={styles.heading}>{heading}</h2>}
                <section>{children}</section>
            </div>
        )

    if (numberOfChildren === 2)
        return (
            <div className={cx(styles.frame, className && className)} {...rest}>
                {heading && (
                    <section className={cx(styles.textSection, textClassName && textClassName)}>
                        <h2 className={styles.heading}>{heading}</h2>
                    </section>
                )}

                <section className={styles.buttonSection}>
                    {Children.map(children, (child, i) => i === 0 && child)}
                    {Children.map(children, (child, i) => i === 1 && child)}
                </section>
            </div>
        )

    // Children > 3
    return (
        <div className={cx(styles.frame, className && className)} {...rest}>
            {heading && (
                <section className={cx(styles.textSection, textClassName && textClassName)}>
                    <h2 className={styles.heading}>{heading}</h2>
                </section>
            )}

            <section className={styles.multiButtonSection}>
                {Children.map(children, (child, i) => i === 0 && child)}
                {Children.map(children, (child, i) => i > 0 && <div className={styles.multiButtons}>{child}</div>)}
            </section>
        </div>
    )
}

Frame.defaultProps = {
    className: '',
    textClassName: '',
    heading: '',
}

export default Frame
