import React, { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { format, startOfMonth, endOfMonth, subMonths, eachMonthOfInterval, parseISO } from 'date-fns'
import { useQueryString } from '../../../lib/utils/hooks'

import UserStatsHeader from './ui/UserStatsHeader'
import Disclaimer from '../../ui/Disclaimer/Disclaimer'
import api from '../../../api'
import StatsTable from './ui/StatsTable'
import PaymentHistory from './ui/PaymentHistory'

import { isAuthorized } from '../../../lib/utils'
import { CAN_VIEW_CONTRACTOR_PAYMENTS } from '../../../lib/utils/permissions'

type UserStatsProps = {
    currentUserId: string
    sitesMeta: SitesMeta
    rolesMeta: string[]
    voltaxSitesList: VoltaxSiteObject[]
}

const UserStats = ({ currentUserId, sitesMeta, rolesMeta, voltaxSitesList }: UserStatsProps) => {
    const [dateFilter, setDateFilter] = useQueryString('date', format(new Date(), 'yyyy-MM-dd'))
    const startDate = format(startOfMonth(parseISO(dateFilter)), 'yyyy-MM-dd')
    const endDate = format(endOfMonth(parseISO(dateFilter)), 'yyyy-MM-dd')

    const currentDate = Date.now()
    const paymentsHistoryEndDate = format(startOfMonth(currentDate), 'yyyy-MM-dd')
    const paymentsHistoryStartDate = format(subMonths(parseISO(paymentsHistoryEndDate), 6), 'yyyy-MM-dd')
    const lastSixMonths = useMemo(
        () =>
            eachMonthOfInterval({
                start: parseISO(paymentsHistoryStartDate),
                end: parseISO(paymentsHistoryEndDate),
            }),
        [paymentsHistoryEndDate, paymentsHistoryStartDate],
    )

    const { userId = currentUserId } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (userId !== currentUserId && !isAuthorized({ permission: CAN_VIEW_CONTRACTOR_PAYMENTS })) {
            navigate('/404')
        }

        const getSingleUserData = async () => {
            await api.users.getUser(userId).catch(() => {
                navigate('/404')
            })
        }

        if (userId !== currentUserId) {
            getSingleUserData()
        }
    }, [currentUserId, navigate, userId])

    return (
        <>
            <UserStatsHeader />
            <div className="owl-extra-large contentBox">
                <Disclaimer />
                <StatsTable
                    currentUserId={currentUserId}
                    userId={userId}
                    sitesMeta={sitesMeta}
                    rolesMeta={rolesMeta}
                    dateFilter={dateFilter}
                    setDateFilter={setDateFilter}
                    startDate={startDate}
                    endDate={endDate}
                    voltaxSitesList={voltaxSitesList}
                />
                <PaymentHistory
                    userId={userId}
                    lastSixMonths={lastSixMonths}
                    paymentsHistoryStartDate={paymentsHistoryStartDate}
                    paymentsHistoryEndDate={paymentsHistoryEndDate}
                />
            </div>
        </>
    )
}

const stateToProps = ({ globalState }: any) => ({
    currentUserId: globalState.currentUser?.data?.id,
    rolesMeta: globalState.currentUser?.data?.rolesMeta,
    sitesMeta: globalState.currentUser?.data?.sitesMeta,
    voltaxSitesList: globalState.voltaxSitesList?.data,
})

export default connect(stateToProps)(UserStats)
