import React, { useRef, useState } from 'react'
import cx from 'classnames'

import api from '../../../api'
import { Button } from '..'
import Icon from '../Icon'
import { ImageUploadModal } from './ImageUploadModal'
import styles from './imageUpload.module.scss'

type Props = {
    className?: string
    labelClassName?: string
    hintClassName?: string
    disabled?: boolean
    hint?: string
    id?: string
    userId?: string
    name: string
    required?: boolean
}

const ImageUpload: React.FC<Props> = ({
    className,
    labelClassName,
    hintClassName,
    disabled,
    hint,
    id,
    userId,
    name,
    required,
}) => {
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const fileInput = useRef<any>(null)

    const uploadImage = async (file: any) => {
        if (!file) return

        if (!userId) throw new Error('Upload failed!')

        await api.users.uploadImage(file, userId, setLoading)

        if (fileInput.current) {
            fileInput.current.value = ''
        }
        setIsOpen(false)
    }

    const triggerInputFile = () => fileInput.current.click()
    return (
        <>
            <ImageUploadModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                triggerInputFile={triggerInputFile}
                id={userId}
            />
            <Button
                className={cx(className, styles.imageUpload)}
                onClick={() => setIsOpen(true)}
                disabled={disabled}
            >
                <input
                    id={id || name}
                    name={name}
                    type="file"
                    className={cx(styles.input)}
                    onChange={(e: any) => {
                        uploadImage(e.currentTarget.files[0])
                    }}
                    required={required}
                    ref={fileInput}
                    tabIndex={-1}
                />

                <label htmlFor={id || name} className={cx(labelClassName && labelClassName, styles.label)}>
                    {loading ? (
                        <div className={styles.spinner}>
                            <span className={styles.bounce1} />
                            <span className={styles.bounce2} />
                            <span className={styles.bounce3} />
                        </div>
                    ) : (
                        <Icon icon="Pencil" fill="#999" />
                    )}
                </label>

                {hint && (
                    <div className={hintClassName}>
                        <em>{hint}</em>
                    </div>
                )}
                <br />
            </Button>
        </>
    )
}

ImageUpload.defaultProps = {
    className: '',
    labelClassName: '',
    hintClassName: '',
    disabled: false,
    hint: '',
    id: '',
    userId: '',
    required: false,
}

export default ImageUpload
