import React from 'react'

import styles from './buttonGroup.module.scss'

type Props = {
    className?: string
    children?: React.ReactNode[] | React.ReactNode
}

const ButtonGroup: React.FC<Props> = (props) => {
    const { children, className, ...rest } = props
    return (
        <div className={className ? `${styles.buttonGroup} ${className}` : `${styles.buttonGroup}`} {...rest}>
            {children}
        </div>
    )
}

ButtonGroup.defaultProps = {
    className: '',
    children: [],
}

export default ButtonGroup
