import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { format, startOfMonth } from 'date-fns'

import Loading from '../../../lib/framework/Loading'
import { Table } from '../../../lib/framework'
import tableColumns from '../../utils/flatRateTableColumns'
import { usePaymentsSiteList, useQueryString } from '../../../lib/utils/hooks'
import FlatRateHeader from './FlatRateHeader'
import { ManageFlatRateContext } from './ManageFlatRateContext'

const INITIAL_DATE = format(startOfMonth(new Date()), 'yyyy-MM-dd')
const INITIAL_SITE = '-1'

type Props = {
    data: any
    isLoading: boolean
}

const FlatRate: React.FC<Props> = ({ data, isLoading }) => {
    const [date, setDate] = useQueryString('date', INITIAL_DATE)
    const [site, setSite] = useQueryString('site', INITIAL_SITE)
    const [search, setSearch] = useState('')

    /**
     * Get list of all sites
     */
    const withUnassigned = false
    const [siteOptions, sitesLoading] = usePaymentsSiteList(withUnassigned)

    const loading = !Array.isArray(data) || isLoading || sitesLoading
    let useData = loading ? [] : data

    if (search) {
        useData = useData.filter(
            (row: any) =>
                row.name.toLowerCase().includes(search.toLowerCase()) ||
                row.title.toLowerCase().includes(search.toLowerCase()),
        )
    }
    const pageSize = loading ? 10 : data.length

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        if (params.get('date')) return

        setDate(INITIAL_DATE)
    }, [date, setDate])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        if (params.get('site')) return

        setSite(INITIAL_SITE)
    }, [site, setSite])

    /**
     * @param {object} option Date object
     */
    const filterDate = (option: any) => setDate(option.value)

    /**
     * @param {object} option Site object
     */
    const filterSite = (option: any) => setSite(option.value)

    const filterSearch = (e: any) => setSearch(e.target.value)

    const handleClear = () => {
        setDate(INITIAL_DATE)
        setSite(INITIAL_SITE)
        setSearch('')
    }

    const contextValues = {
        date,
        site,
        search,
        filterDate,
        filterSite,
        filterSearch,
        handleClear,
    }

    return (
        <ManageFlatRateContext.Provider value={contextValues}>
            <FlatRateHeader siteOptions={siteOptions} loading={loading} />
            <div className="contentBox">
                <Loading loading={loading} />
                <Helmet>
                    <title>FS App Console - Flat Rate</title>
                </Helmet>
                <Table
                    loading={loading}
                    loadingText={''}
                    data={useData}
                    columns={tableColumns()}
                    noDataText={<span>Nothing here</span>}
                    defaultSorted={[
                        {
                            id: 'pubDate',
                            desc: true,
                        },
                    ]}
                    showPageSizeOptions={false}
                    showPagination={false}
                    /* @ts-ignore */
                    pageSize={!loading && pageSize}
                    minRows={9}
                />
            </div>
        </ManageFlatRateContext.Provider>
    )
}

const stateToProps = ({ paymentsState: { flatRateState } }: any) => ({
    data: flatRateState.flatRateData.data,
    isLoading: flatRateState.flatRateData.isLoading,
})

export default connect(stateToProps)(FlatRate)
