import React from 'react'

type OwnProps = {
    fill?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Search.defaultProps
/* eslint-disable */
// @ts-expect-error ts-migrate(7022) FIXME: 'Search' implicitly has type 'any' because it does... Remove this comment to see the full error message
const Search = ({ fill, ...rest }: Props) => (
    <svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18" fill={fill} {...rest}>
        <path d="M18 16.5l-5.14-5.18h-.35a7 7 0 1 0-1.19 1.19v.35L16.5 18l1.5-1.5zM12 7A5 5 0 1 1 2 7a5 5 0 0 1 10 0z"></path>
    </svg>
)

Search.defaultProps = {
    fill: '',
}

export default Search
