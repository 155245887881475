/* eslint react/prop-types: 0 */

import React from 'react'
import { Tooltip } from 'react-tippy'

import { isAuthorized } from '../../lib/utils'
import { statusOptions } from '../utils/statusOptions'
import { CAN_MANAGE_INVOICES, CAN_AUDIT_INVOICES } from '../../lib/utils/permissions'
import StatusToggle from '../ui/StatusToggle'
import { numberWithCommas } from './format/number'
import { setOverrideValue } from '../../state/invoiceState'
import EditableCellInputField from '../ui/EditableCellInputField'
import EditableCellSelectField from '../ui/EditableCellSelectField'

const buildValues = ({ siteId, status }: any) =>
    statusOptions.map((option) => {
        if (option.value === 'all') return false

        /* Special case:
         * Allow only 'audited' and 'export' if approved
         */
        if (status === 'approved' && !['export', 'audited'].includes(option.value)) {
            return (
                <option disabled key={option.value} value={option.value}>
                    {option.label}
                </option>
            )
        }

        if (option.permission && isAuthorized({ permission: option.permission, siteId })) {
            return (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            )
        }
        // If not auth'd for status, show, but disable as option
        return (
            <option disabled key={option.value} value={option.value}>
                {option.label}
            </option>
        )
    })

const tableColumns = (handleStatusChange: any, siteOptions: any) => [
    {
        Cell: ({ row }: any) => (
            <span className="center-content">
                <StatusToggle id={row._original.id} siteId={row._original.siteId} status={row.status} />
            </span>
        ),
        width: 50,
        className: 'center-content',
        headerClassName: 'center-content',
    },
    {
        Header: 'Payee',
        columns: [
            {
                Header: 'Name',
                accessor: 'name',
                headerClassName: 'sortable',
                width: 130,
            },
            {
                Header: 'Type',
                accessor: 'type',
                headerClassName: 'sortable',
                width: 100,
            },
        ],
    },
    {
        Header: 'Vertical',
        Cell: ({ row }: any) => row.vertical.toUpperCase(),
        accessor: 'vertical',
        headerClassName: 'sortable',
        width: 100,
    },
    {
        Header: 'Site Name',
        accessor: 'siteId',
        Cell: ({ row: { siteId } }: any) => {
            const { label: siteName = '' } = siteOptions.find((site: any) => site.value === String(siteId)) || {}

            return siteName
        },
        width: 150,
        headerClassName: 'sortable',
        className: 'right-content',
    },
    {
        Header: 'Publication Count',
        headerClassName: 'publication-count',
        columns: [
            {
                Header: () => (
                    <Tooltip title="User Publication Count" position="top" arrow delay={500}>
                        User
                    </Tooltip>
                ),
                Cell: ({ row }: any) => numberWithCommas(row.publishCount),
                accessor: 'publishCount',
                width: 80,
                headerClassName: 'sortable',
                className: 'right-content',
            },
            {
                Header: () => (
                    <Tooltip title="Site Publication Count" position="top" arrow delay={500}>
                        Site
                    </Tooltip>
                ),
                Cell: ({ row }: any) => numberWithCommas(row.sitePublishCount),
                accessor: 'sitePublishCount',
                width: 80,
                headerClassName: 'sortable',
                className: 'right-content',
            },
        ],
    },
    {
        Header: 'Views',
        headerClassName: 'views',
        columns: [
            {
                Header: () => (
                    <Tooltip title="Page Views" position="top" arrow delay={500}>
                        Page Views
                    </Tooltip>
                ),
                accessor: 'views',
                Cell: ({ row }: any) => numberWithCommas(row.views),
                width: 120,
                headerClassName: 'sortable',
                className: 'right-content',
            },
            {
                Header: () => (
                    <Tooltip title="Site Views" position="top" arrow delay={500}>
                        Site Views
                    </Tooltip>
                ),
                accessor: 'siteViews',
                Cell: ({ row }: any) => numberWithCommas(row.siteViews),
                width: 120,
                headerClassName: 'sortable',
                className: 'right-content',
            },
        ],
    },
    {
        Header: 'Status',
        accessor: 'status',
        headerClassName: 'sortable',
        Cell: ({ row }: any) =>
            isAuthorized({
                permission: CAN_AUDIT_INVOICES,
                siteId: row.siteId,
            }) && (
                <EditableCellSelectField
                    onChange={(e: any) => {
                        return handleStatusChange(row._original.id, e.target.value)
                    }}
                    value={row.status}
                    values={buildValues(row)}
                    disabled={row.status === 'paid' || row.status === 'no_account'}
                />
            ),

        width: 100,
    },
    {
        Header: 'Payment',
        columns: [
            {
                Header: 'Amount',
                Cell: ({ row }: any) => {
                    const n = (row._original.payAmount || 0).toFixed(2)
                    // Zero out payments if ineligible
                    if (row._original.status === 'ineligible') {
                        return '$0.00'
                    }
                    return `$${numberWithCommas(n)}`
                },
                width: 100,
                headerClassName: 'sortable',
                className: 'right-content',
                id: 'amount',
                // Accessor tells react-table what value to sort by
                accessor: (d: any) => d.payAmount,
            },
            {
                Header: 'Override',
                Cell: ({ row }: any) => {
                    const n = row._original.override
                        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
                        ? `$${parseFloat(row._original.override || 0, 10).toFixed(2)}`
                        : '$'
                    const disableStatusSelect = [
                        'paid',
                        'approved',
                        'export',
                        'processing',
                        'submitted',
                        'no_account',
                    ].includes(row.status)

                    return (
                        isAuthorized({
                            permission: CAN_MANAGE_INVOICES,
                            siteId: row.siteId,
                        }) && (
                            <EditableCellInputField
                                type="number"
                                displayValue={numberWithCommas(n)}
                                value={n}
                                onSubmit={(value: any) => setOverrideValue(row._original.id, value)}
                                disabled={disableStatusSelect}
                            />
                        )
                    )
                },
                width: 100,
                headerClassName: 'sortable',
                className: 'right-content',
            },
        ],
    },
]

export default tableColumns
