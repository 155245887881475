import React from 'react'

type OwnProps = {
    fill?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Plus.defaultProps

/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7022) FIXME: 'Plus' implicitly has type 'any' because it does n... Remove this comment to see the full error message
const Plus = ({ fill, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 15 15" fill={fill} {...rest}>
        <path d="M13.5,6h-12C0.7,6,0,6.7,0,7.5S0.7,9,1.5,9h12C14.3,9,15,8.3,15,7.5S14.3,6,13.5,6z" />
        <path d="M6,1.5v12C6,14.3,6.7,15,7.5,15S9,14.3,9,13.5v-12C9,0.7,8.3,0,7.5,0S6,0.7,6,1.5z" />
    </svg>
)

Plus.defaultProps = {
    fill: '',
}

export default Plus
