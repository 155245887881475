import React, { useState, useEffect, useRef } from 'react'
import { useField } from 'formik'

import styles from './currency.module.scss'

const Currency = (props: any) => {
    const [editing, setEditing] = useState(false)
    const ref = useRef<any>()

    useEffect(() => {
        if (editing) ref?.current.focus() // eslint-disable-line
    }, [editing])

    const [{ name, value, onBlur }, , { setValue }] = useField(props)

    const toCurrency = (number: any) => {
        if (!number) return '$'

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })

        return formatter.format(number)
    }

    const handleBlur = (e: any) => {
        onBlur?.(e) // eslint-disable-line
        const newValue = !value || typeof value !== 'number' ? '' : value.toFixed(2)
        setValue(newValue || '')
        setEditing(false)
    }

    const handleFocus = () => setEditing(true)

    const handleChange = (e: any) => {
        const {
            target: { value },
        } = e
        // Allow for typing decimal
        const newValue = value === '.' ? value : parseFloat(value)
        // If 0, clear input
        setValue(newValue || '')
    }

    return editing ? (
        <input
            type="number"
            step="0.01"
            ref={ref}
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            className={styles.input}
            key="numberCurrency"
        />
    ) : (
        <input
            type="text"
            name={name}
            value={toCurrency(value)}
            onClick={handleFocus}
            onFocus={handleFocus}
            readOnly
            className={styles.input}
            key="textCurrency"
        />
    )
}

export default Currency
