import React from 'react'

import ExpandedInvoiceStatsRow from '../ExpandedInvoiceStatsRow'
import { Table } from '../../../../../lib/framework'
import Loading from '../../../../../lib/framework/Loading'
import globalInvoiceStatsColumns from '../../../../utils/globalInvoiceStatsColumns'

import styles from './globalStats.module.scss'

type Props = {
    loading: boolean
    data: string[]
}

const GlobalStats = ({ data, loading }: Props) => (
    <>
        <Loading loading={loading} />
        <Table
            loading={loading}
            loadingText=""
            data={data}
            // For each month of invoice data we add up total invoices and amounts
            resolveData={(d: any) =>
                d && d.length
                    ? d.map((row: any) =>
                          row.reduce(
                              (a: any, b: any) => {
                                  return {
                                      date: b.date,
                                      invoices: a.invoices + b.invoices,
                                      amount: a.amount + b.amount,
                                  }
                              },
                              { invoices: 0, amount: 0 },
                          ),
                      )
                    : []
            }
            className={styles.statsTable}
            columns={globalInvoiceStatsColumns}
            SubComponent={({ index }: any) => {
                const expandedData = data[index]

                return <ExpandedInvoiceStatsRow data={expandedData} index={index} isGlobal />
            }}
        />
    </>
)
export default GlobalStats
