import { useEffect, useState } from 'react'

import useSiteList from './useSiteList'
import { CAN_AUDIT_INVOICES, CAN_MANAGE_INVOICES } from '../permissions'
import { isAuthorized } from '../authorization'

const usePaymentsSiteList = (withUnassigned: boolean): [PaymentsSiteListType, boolean, ReactSelectOption[]] => {
    const [globalList = [], sitesLoading] = useSiteList()

    const [list = [], setList] = useState<PaymentsSiteListType>([])

    useEffect(() => {
        if (!list?.length && globalList?.length) {
            const permittedSites = globalList
                .filter((site) => isAuthorized({ permission: CAN_AUDIT_INVOICES, siteId: site.id }))
                .sort((a, b) => (b.name.toLowerCase() <= a.name.toLowerCase() ? 1 : -1))

            const siteList =
                isAuthorized({ permission: CAN_MANAGE_INVOICES, siteId: '0' }) && withUnassigned
                    ? [{ id: '0', name: 'Unassigned' }, ...permittedSites]
                    : permittedSites

            const withAllSites = [{ id: '-1', name: 'All Sites' }, ...siteList]

            const siteOptions = withAllSites.map((item) => ({
                value: item.id,
                label: item.name,
                vertical: item?.vertical,
            }))

            setList(siteOptions)
        }
        // eslint-disable-next-line
    }, [list.length, globalList.length])

    const verticals = [...new Set(list.map(({ vertical }) => vertical).filter(Boolean))] as string[]

    const verticalList = verticals.map((vertical: string) => ({
        value: vertical,
        label: vertical,
    }))

    return [list, sitesLoading, verticalList]
}

export default usePaymentsSiteList
