import React from 'react'

import { Button } from '../../../../lib/framework'
import Icon from '../../../../lib/framework/Icon'

import styles from './addSiteUI.module.scss'

type OwnProps = {
    handleToggle: (...args: any[]) => any
    disabled?: boolean
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof AddSiteButton.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'AddSiteButton' implicitly has type 'any' because ... Remove this comment to see the full error message
const AddSiteButton = ({ handleToggle, disabled }: Props) => {
    return (
        <div className={styles.addSiteButton}>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Property 'id' does not exist on type 'IntrinsicAtt... Remove this comment to see the full error message */}
            <Button id="trUserDetailAddSite" type="plus" onClick={handleToggle} disabled={disabled}>
                <Icon icon="Plus" fill="#999" />
            </Button>

            <label htmlFor="trUserDetailAddSite" className={styles.addSiteText}>
                Add Site
            </label>
        </div>
    )
}

AddSiteButton.defaultProps = {
    disabled: false,
}

export default AddSiteButton
