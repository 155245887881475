// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/yup` if it exists or add a... Remove this comment to see the full error message
import * as Yup from 'yup'

const nameRegExp = /^[a-z\s-.']+$/i

export const UpdatePasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(12, 'Must have at least 12 characters.')
        .matches(/[A-Z]/, 'Must have at least 1 uppercase letter.')
        .matches(/[a-z]/, 'Must have at least 1 lowercase letter.')
        .matches(/\d/i, 'Must have at least 1 number.')
        .required('Password is required.'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match.')
        .required('Confirm Password is required.'),
})

export const UserDetailsSchema = Yup.object().shape({
    firstName: Yup.string().matches(nameRegExp, 'Only letters allowed!').required('Required'),
    lastName: Yup.string().matches(nameRegExp, 'Only letters allowed!').required('Required'),
    displayName: Yup.string(),
    email: Yup.string().email('Please enter a valid email address.').required('Required'),
    bio: Yup.string(),
    twitter: Yup.string(),
    facebook: Yup.string(),
    instagram: Yup.string(),
})

export const validateInvoice = Yup.object().shape({
    name: Yup.string().matches(nameRegExp, 'Only letters allowed.').required('Required'),
    username: Yup.string().required('Required'),
    payAmount: Yup.number().required('Required'),
    type: Yup.string().required('Required'),
    status: Yup.object().required('Required'),
    comment: Yup.string().required('Required'),
    site: Yup.object().required('Required'),
    date: Yup.string().nullable(),
    publishCount: Yup.string(),
    sitePublishCount: Yup.string(),
    views: Yup.string(),
    siteViews: Yup.string(),
})

export const AddUserSchema = Yup.object().shape({
    firstName: Yup.string().matches(nameRegExp).required('First Name Required'),
    lastName: Yup.string().matches(nameRegExp).required('Last Name Required'),
    email: Yup.string().email('Please enter a valid email').required('Email is required'),
})
