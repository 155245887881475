import React from 'react'

type OwnProps = {
    fillLight?: string
    fillDark?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof FansidedAppLogo.defaultProps

/* eslint-disable max-len */

// @ts-expect-error ts-migrate(7022) FIXME: 'FansidedAppLogo' implicitly has type 'any' becaus... Remove this comment to see the full error message
const FansidedAppLogo = ({ fillLight, fillDark, ...props }: Props) => (
    <svg viewBox="0 0 1000 1000" width="24" height="24" {...props}>
        <title>Fansided App Logo</title>
        <polygon
            fill={fillLight}
            points="199.5,199.5 287.9,199.5 287.9,146.4 146.4,146.4 146.4,287.9 199.5,287.9 "
        />
        <polygon
            fill={fillLight}
            points="800.5,199.5 800.5,287.9 853.6,287.9 853.6,146.4 712.1,146.4 712.1,199.5 "
        />
        <polygon
            fill={fillLight}
            points="199.5,800.5 199.5,712.1 146.4,712.1 146.4,853.6 287.9,853.6 287.9,800.5 "
        />
        <polygon
            fill={fillLight}
            points="800.5,800.5 712.1,800.5 712.1,853.6 853.6,853.6 853.6,712.1 800.5,712.1 "
        />
        <polygon fill={fillDark} points="75,500 137.5,437.5 100,400 0,500 100,600 137.5,562.5 " />
        <polygon fill={fillDark} points="500,75 562.5,137.5 600,100 500,0 400,100 437.5,137.5 " />
        <polygon fill={fillDark} points="500,925 437.5,862.5 400,900 500,1000 600,900 562.5,862.5 " />
        <polygon fill={fillDark} points="925,500 862.5,562.5 900,600 1000,500 900,400 862.5,437.5 " />
        <g>
            <path
                fill={fillDark}
                d="M535.5,395l8.9-23.5c5.4-13.7,10.8-27.4,15.7-41.5H381.9C336.5,443.9,288.6,555.4,244,670
                c36.6-2.2,73.6-4,111-5.4c20.1-47.3,37.6-97.2,58.5-143.7H479l24.6-65l-64.9-0.2c8.2-20.2,15.4-41.5,24.7-60.7H535.5z"
            />
            <path
                fill={fillDark}
                d="M754,362.2c-2-5.6-8.6-13.2-13.7-16.7c-18-12.4-50.5-16.9-81.7-14.9c-32.9,2.1-65.6,13.7-85.9,35.2
                c-9.7,10.2-15.7,23.3-21.5,37.6c-8.7,21.7-21.4,52.6-7.2,76.4c12.2,20.3,41.1,34,59.7,48.3c6.8,5.2,15.8,12.7,17.3,19.1
                c4.2,17.7-9,51.3-23.9,57.3c-6,2.4-16,3.2-19.1-2.4c-5.2-9.5,12.6-52.3,15.4-60.7h-87.1c-6.4,25-18.9,45.2-18.5,72.1
                c0.7,38,40.3,47.6,77,47.7c42.2,0.1,84-11.2,111-37.6c13.1-12.7,21.1-28.6,27.4-46.5c6.5-18.4,13.8-40.9,11.9-62.6
                c-2.1-24.1-24.3-38.5-41.2-50.1c-6.7-4.6-14.4-10-22.7-15.5c-6.7-4.5-18.1-11.6-19.7-19.1c-2.7-12.5,3.7-26.3,8.4-34
                c2.9-4.9,19-20.7,28-10.1c4.8,5.6-0.8,17.7-2.4,22.7c-2.6,7.9-5.6,15-6.4,21.5h82c0.3,0,0.5,0,0.8,0
                C746.4,406.9,761.3,383.2,754,362.2z"
            />
        </g>
    </svg>
)

FansidedAppLogo.defaultProps = {
    fillLight: '#999999',
    fillDark: '#5A97C1',
}

export default FansidedAppLogo
