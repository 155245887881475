import React from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './userIconMenu.module.scss'

const UserIconMenu = () => {
    const navigate = useNavigate()

    return (
        <div className={styles.userIconMenu}>
            {/*
             * Disabled until Auth set up with the new users app
             */}
            {/* <NavLink to="/users/myprofile">My Profile</NavLink> */}
            <button onClick={() => navigate('/logout')} type="button">
                Logout
            </button>
        </div>
    )
}

export default UserIconMenu
