import React, { useEffect, useContext } from 'react'
import { format, parseISO } from 'date-fns'

import { ManageFlatRateContext } from './ManageFlatRateContext'
import { Dropdown, Input } from '../../../lib/framework'
import api from '../../../api'
import { Button } from '../../../lib/framework'
import { getDateOptions } from '../../../lib/utils'

import baseStyles from '../PrimaryHeader/primaryHeader.module.scss'
import styles from './flatRate.module.scss'
import { getDateForLastMonth } from '../../../lib/utils'

type Props = {
    siteOptions: {
        value: string
        label: string
        vertical?: string
    }[]
    loading: boolean
}

const FlatRateHeader: React.FC<Props> = ({ loading, siteOptions }) => {
    const { date, site, search, filterDate, filterSite, filterSearch, handleClear } = useContext(
        ManageFlatRateContext,
    )

    const siteDropdownValue = siteOptions?.find((s: any) => s.value === String(site))
    let dateLabel
    try {
        dateLabel = format(parseISO(date), 'MMM yyyy')
    } catch (e) {
        dateLabel = format(getDateForLastMonth(), 'MMM yyyy')
    }
    const dateDropdownValue = {
        label: dateLabel,
        value: date,
    }

    const dateOptions = getDateOptions(null, parseISO('2021-02-01'))

    useEffect(() => {
        api.payments.flatRate.getAll({
            date,
            site,
        })
    }, [date, site])

    return (
        <header className={`${baseStyles.primaryHeader} horizontal-owl`}>
            <div className={`${baseStyles.filterDropdowns} horizontal-owl`}>
                <div className={baseStyles.date}>
                    <Dropdown value={dateDropdownValue} options={dateOptions} onChange={filterDate} name="date" />
                </div>
                <div className={styles.site}>
                    <Dropdown
                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Object... Remove this comment to see the full error message
                        value={siteDropdownValue}
                        options={siteOptions}
                        onChange={filterSite}
                        name="site"
                        isDisabled={loading}
                        isLoading={loading}
                    />
                </div>
                <div className={styles.filterName}>
                    {/* @ts-ignore */}
                    <Input
                        containerClassName={styles.filterNameInput}
                        value={search}
                        name="searchField"
                        handleChange={filterSearch}
                        label="Search by post or user"
                    />
                </div>
                <div className={baseStyles.clearFilters}>
                    <Button type="primary" onClick={handleClear}>
                        Clear Filters
                    </Button>
                </div>
            </div>
        </header>
    )
}

FlatRateHeader.defaultProps = {
    siteOptions: [],
}

export default FlatRateHeader
