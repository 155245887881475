import store from '../../../store'

/**
 *
 * Need to pass (permission && siteId) OR permissionSets
 *
 * @param {string} [permission] A string permission name to check
 *  against user permissions.
 * @param {string} [siteId] The site to check perm on
 *
 * @returns {bool} Returns true only if all provided permissions are matched.
 */
const isAuthorized = ({ permission, siteId }: { permission?: string; siteId?: string }) => {
    if (!permission) {
        console.error('No permission provided!') // eslint-disable-line
        return false
    }

    const { data: currentUser } = store.getState().globalState?.currentUser || {}

    if (!currentUser || !Object.keys(currentUser).length) return false
   
    const { permissions: userPermissions } = currentUser

    /**
     * If they don't have the permission on any site, return false
     */
    if (!userPermissions?.[permission]) {
        return false
    }

    /**
     * If they have the permission and we're not checking site, return true
     */
    if (!siteId) return true

    /**
     * Return true if they have the permission on the site passed or 0
     */
    return userPermissions[permission].includes(siteId.toString()) || userPermissions[permission].includes('0')
}

export default isAuthorized
