import React, { useState, useContext } from 'react'
import { Helmet } from 'react-helmet'

import { UsersDetailContext } from '../../UsersDetailContext'
import UpdatePassword from '../../../UpdatePassword'
import { Button, Frame } from '../../../../../lib/framework'

import styles from './passwordAndSecurityTab.module.scss'

const PasswordAndSecurityTab = () => {
    const { isOwnProfile } = useContext(UsersDetailContext)
    const [changingPassword, setChangingPassword] = useState(false)

    const handlePasswordModal = () => setChangingPassword(!changingPassword)

    return (
        <>
            <Helmet>
                <title>FS App Console - Password And Security</title>
            </Helmet>

            {/* Currently only allowing users to update their own password, no admin password updates */}
            {isOwnProfile ? (
                <>
                    <Frame className={styles.frame} heading="Change Password">
                        <div>
                            FanSided takes security seriously. Help us shut out the bad guys by protecting your
                            account.
                        </div>
                        <Button onClick={handlePasswordModal}>Change Password</Button>
                    </Frame>
                </>
            ) : (
                <div>Nothing to see here!</div>
            )}

            {/* Render modal outside of form to prevent submitting main form when updating password */}
            {changingPassword && <UpdatePassword handleModal={handlePasswordModal} isOwnProfile={isOwnProfile} />}
        </>
    )
}

export default PasswordAndSecurityTab
