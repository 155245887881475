const logo = 'https://s3.amazonaws.com/fs-app-spa-images/default-avatar.png'

export const getSiteListWithGlobalRolesOption = (sites: ISiteList): ISiteList => [
    {
        name: 'Global Roles',
        logo,
        id: '0',
    },
    ...sites,
]
