import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { subMonths, startOfMonth, format } from 'date-fns'
import { Tooltip } from 'react-tippy'

import PaymentsStatsHeader from '../../../ui/AnalyticsHeader/AnalyticsHeader'
import { Dropdown } from '../../../../lib/framework'
import Icon from '../../../../lib/framework/Icon'
import Button from '../../../../ManagePayments/ui/Button'
import DatePickerButton from '../../../../lib/framework/DatePickerButton/DatePickerButton'
import { useSiteList } from '../../../../lib/utils/hooks'
import Image from '../../../../lib/framework/Image'
import { imageService, isAuthorized } from '../../../../lib/utils'
import { CAN_VIEW_SITE_ANALYTICS } from '../../../../lib/utils/permissions'

import styles from '../../../ui/AnalyticsHeader/analyticsHeader.module.scss'
import GAPageView from '../../../../lib/utils/GAPageView'

type Props = {
    sitesMeta: SitesMeta
    displayName: string
    profileImg: string
    dateFilter: string
    setDateFilter: ISetterFunc
    setStats: ISetterFunc
    currentUserId: string
    rolesMeta: string[]
    voltaxSitesList: VoltaxSiteObject[]
}

const SiteStatsHeader: React.FC<Props> = ({
    displayName,
    profileImg,
    sitesMeta,
    dateFilter,
    setDateFilter,
    setStats,
    currentUserId,
    rolesMeta,
    voltaxSitesList,
}) => {
    let navigate = useNavigate()
    let { siteId } = useParams()

    const [siteOptions, setSiteOptions] = useState([])
    const [prevSite, setPrevSite] = useState({ label: 'Loading...' })
    const [nextSite, setNextSite] = useState({ label: 'Loading...' })
    const [site, setSite] = useState({ label: '', value: siteId })
    const siteIds = sitesMeta.map((site: SiteMeta) => site.id)
    const hasVoltaxRole = !!voltaxSitesList.filter((site: SiteMeta) => siteIds.includes(site.id)).length
    const pageIdentifier = window.location.pathname + window.location.search

    /**
     * Fetch the global siteList if it doesn't exist
     */
    const [siteList, sitesLoading] = useSiteList()

    /**
     *
     * @param {object} site
     */
    const handleChangeSite = useCallback(
        (site) => {
            const search = window.location.search

            navigate(`/payments/site/${site.value}${search}`)
            setSite(site)
        },
        // eslint-disable-next-line
        [],
    )

    // const sitesMetaIds = sitesMeta.map((site) => site.id)
    const sitesMetaIds = sitesMeta.map((site) => site.id)

    /**
     * Set site options for dropdown
     * Initialize site dropdown to first option
     */
    useEffect(() => {
        if (!siteList?.length || siteOptions.length || !sitesMetaIds.length) return

        let options

        const getSiteOptions = () => {
            // returns entire site list for system admins

            if (sitesMetaIds.includes('0')) {
                return siteList.map((s) => {
                    return { label: s.name, value: s.id }
                })
            }

            // returns list of sites in sitesMeta
            // @ts-expect-error ts-migrate(2769) FIXME: Type '{ label: string; value: string; }' is not as... Remove this comment to see the full error message
            const userSites = siteList.reduce((sites, site) => {
                if (
                    sitesMetaIds.includes(site.id) &&
                    isAuthorized({ permission: CAN_VIEW_SITE_ANALYTICS, siteId: site.id })
                ) {
                    return [...sites, { label: site.name, value: site.id }]
                }
                return sites
            }, [])

            return userSites
        }

        options = getSiteOptions()

        // @ts-expect-error ts-migrate(2345) FIXME: Type 'undefined' is not assignable to type 'SetSta... Remove this comment to see the full error message
        setSiteOptions(options)

        if (siteId) {
            const site = siteList.find((s) => s.id === siteId)

            site && Object.keys(site).length && handleChangeSite({ label: site.name, value: site.id })
        } else {
            const site = options?.[0]

            site && handleChangeSite(site)
        }
        // eslint-disable-next-line
    }, [siteList.length, siteOptions.length, sitesMetaIds.length, handleChangeSite, siteId])

    useEffect(() => {
        if (!siteOptions.length) return

        /**
         *
         * @param {string} dir The direction - 'prev' or 'next
         * @returns {object} The new site object
         */
        const findNewSite = (dir: any) => {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'never'.
            const currentSiteIndex = siteOptions.findIndex((s) => s?.value === site.value)

            const getNewSiteIndex = () => {
                if (currentSiteIndex === -1) return {}

                if (currentSiteIndex === 0) {
                    return dir === 'next' ? currentSiteIndex + 1 : siteOptions.length - 1
                }
                if (currentSiteIndex === siteOptions.length - 1) {
                    return dir === 'next' ? 0 : currentSiteIndex - 1
                }

                return dir === 'next' ? currentSiteIndex + 1 : currentSiteIndex - 1
            }

            const newSiteIndex = getNewSiteIndex()

            if (!newSiteIndex && newSiteIndex !== 0) return {}

            // @ts-expect-error ts-migrate(2538) FIXME: Type '{}' cannot be used as an index type.
            const newSite = siteOptions[newSiteIndex] || { label: 'None' }

            return newSite
        }

        setPrevSite(findNewSite('prev'))
        setNextSite(findNewSite('next'))
    }, [site.value, siteOptions])

    /**
     *
     * @param {object} option Site object
     */
    const filterSite = (option: any) => handleChangeSite({ label: option.label, value: option?.value })

    /**
     *
     * @param {string} dir 'prev' or 'next'
     * @returns {func} This returns the anoynomous function that would otherwise be inlined as the event handler
     */
    const cycleSites = (dir: any) => () => filterSite(dir === 'next' ? nextSite : prevSite)

    const toolTipText = {
        prevSiteTitle: prevSite.label,
        nextSiteTitle: nextSite.label,
    }

    return (
        <PaymentsStatsHeader>
            <GAPageView
                rolesMeta={rolesMeta}
                hasVoltaxRole={hasVoltaxRole}
                userId={currentUserId}
                pageIdentifier={pageIdentifier}
            />
            <div className={styles.usersStatsHeaderContainer}>
                <div>
                    <Image
                        src={imageService(profileImg, 45, 45)}
                        placeholder={imageService(profileImg, 16, 16)}
                        alt="Profile"
                        width={45}
                        height={45}
                    />
                </div>
            </div>
            <div>{displayName}</div>
            <div>
                <DatePickerButton
                    onChange={(d) => {
                        setStats([])
                        setDateFilter(format(d, 'yyyy-MM-dd'))
                    }}
                    value={dateFilter}
                    // @ts-expect-error ts-migrate(2322) FIXME: Property 'minDate' does not exist on type 'Intrins... Remove this comment to see the full error message
                    minDate={subMonths(startOfMonth(new Date()), 2)}
                />
            </div>
            <div className={styles.site}>
                <Dropdown
                    value={site}
                    options={siteOptions}
                    onChange={filterSite}
                    name="site"
                    isDisabled={sitesLoading}
                    isLoading={sitesLoading}
                />
                <Tooltip title={toolTipText.prevSiteTitle} className={styles.cycleTooltip}>
                    <Button
                        className={styles.cycleSitesButtons}
                        onClick={cycleSites('prev')}
                        disabled={sitesLoading}
                    >
                        <Icon icon="CaretDown" fill="#333" />
                    </Button>
                </Tooltip>
                <Tooltip title={toolTipText.nextSiteTitle} className={styles.cycleTooltip}>
                    <Button
                        className={`${styles.cycleSitesButtons} ${styles.next}`}
                        onClick={cycleSites('next')}
                        disabled={sitesLoading}
                    >
                        <Icon icon="CaretDown" fill="#333" />
                    </Button>
                </Tooltip>
            </div>
        </PaymentsStatsHeader>
    )
}

const stateToProps = ({ globalState }: any) => ({
    currentUserId: globalState.currentUser?.data?.id,
    rolesMeta: globalState.currentUser?.data?.rolesMeta,
    sitesMeta: globalState.currentUser?.data?.sitesMeta,
    displayName: globalState.currentUser?.data?.displayName,
    profileImg: globalState.currentUser?.data?.profilePic,
    voltaxSitesList: globalState.voltaxSitesList?.data,
})

export default connect(stateToProps)(SiteStatsHeader)
