/* eslint-disable react/prop-types */
import React from 'react'

export const makeStyledFilters = (columns: any) => {
    const styledColumns = []

    for (const column in columns) {
        const newCol = {
            Filter: ({ filter, onChange }: any) => (
                <input
                    type="text"
                    placeholder={
                        typeof columns[column]['Header'] === 'string'
                            ? `Filter by ${columns[column]['Header'].toLowerCase()}...`
                            : `Filter results...`
                    }
                    onChange={(event) => onChange(event.target.value)}
                    value={filter ? filter.value : ''}
                    style={{
                        width: '100%',
                    }}
                />
            ),
            ...columns[column],
        }
        styledColumns.push(newCol)
    }
    return styledColumns
}
