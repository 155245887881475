import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import api from '../../../api'
import Loading from '../../framework/Loading'

type Props = {
    tokenLoaded?: boolean
}

/**
 * @param {object} props
 * @param {boolean} props.tokenLoaded
 */
const SSORedirect = ({ tokenLoaded }: Props) => {
    const [loading, setLoading] = useState(true)
    const nav = useNavigate()

    useEffect(() => {
        if (!tokenLoaded) {
            return
        }

        const asyncRedirectFunc = async () => {
            const siteId = new URLSearchParams(window.location.search).get('siteId')
            const redirectPath = new URLSearchParams(window.location.search).get('redirectPath')

            if (!siteId) {
                return
            }

            // @ts-expect-error ts-migrate(2345) FIXME: Type 'string' is not assignable to type 'null | un... Remove this comment to see the full error message
            return api.auth.ssoAuth(siteId, setLoading, redirectPath).catch(() => {
                nav('/404')
            })
        }
        asyncRedirectFunc()
    }, [nav, tokenLoaded])

    return <Loading fadeIn loading={loading} />
}

export default SSORedirect
