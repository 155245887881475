/* eslint-disable */
// @ts-expect-error ts-migrate(6133) FIXME: 'useEffect' is declared but its value is never rea... Remove this comment to see the full error message
import React, { useEffect, useCallback } from 'react'
// @ts-expect-error ts-migrate(6133) FIXME: 'Field' is declared but its value is never read.
import { Formik, Form, Field } from 'formik'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import cx from 'classnames'

import api from '../../../api'
import { isAuthorized, validateInvoice } from '../../../lib/utils'
import { CAN_AUDIT_INVOICES, CAN_MANAGE_INVOICES } from '../../../lib/utils/permissions'
import { statusOptions } from '../../utils/statusOptions'

import { Button, Currency, InputFormik, DropdownFormik } from '../../../lib/framework'
import RadioSelectGroup from './ui/RadioSelectGroup'

import styles from './createInvoice.module.scss'
import { usePaymentsSiteList } from '../../../lib/utils/hooks'

const DatePicker = React.lazy(() => import(/* webpackChunkName: 'DatePicker' */ '../../ui/DatePicker'))

const typeOptions = [
    {
        value: 'contributor',
        label: 'Contributor',
    },
    {
        value: 'site_expert',
        label: 'Expert',
    },
    {
        value: 'external/freelance',
        label: 'External/Freelance',
    },
    {
        value: 'podcast',
        label: 'Podcast',
    },
]

const CreateInvoice = () => {
    /**
     * Get list of all sites
     */
    const withUnassigned = true
    const [siteOptions, gettingSiteOptions] = usePaymentsSiteList(withUnassigned)

    const siteValues = siteOptions.filter((option) => {
        if (option.value === '-1') return false
        if (option.value === '0' && !isAuthorized({ permission: CAN_MANAGE_INVOICES })) {
            return false
        }

        return isAuthorized({ permission: CAN_AUDIT_INVOICES, siteId: option.value })
    })

    const handleSubmit = async (values: any, {
        setSubmitting,
        resetForm
    }: any) => {
        const {
            site: { value: siteId, vertical },
            status: { value: status },
        } = values
        /**
         * Additional permission checks
         */
        // Check if current user is authorized to create invoices for this site
        if (siteId && !isAuthorized({ permission: CAN_AUDIT_INVOICES, siteId })) {
            toast.error('You are not permitted to create invoices for this site!')
            return
        }

        if (status) {
            const { permission: statusPermission } = statusOptions.find((option) => option.value === status) || {}

            // Check if current user is authorized for this status on this site
            if (!isAuthorized({ permission: statusPermission, siteId })) {
                toast.error('You do not have permission to assign that status!')
                return
            }
        }

        const newValues = { ...values, status, siteId, vertical }
        try {
            const { invoice } = await api.payments.invoices.createInvoice(newValues, setSubmitting)
            resetForm()

            api.payments.invoices.createComment(invoice.id, `Invoice manually created`, 'system')
        } catch (e) {
            console.error(e) // eslint-disable-line
        }
    }

    const buildStatusValues = ({
        site
    }: any) => {
        const { value: siteId } = site || {}
        return statusOptions.filter((option) => {
            if (!siteId || !option.permission) return false

            if (siteId === 0 && (option.value === 'pending' || option.value === 'audited')) {
                return true
            }

            if (option.value === 'all') return false

            if (!isAuthorized({ permission: CAN_MANAGE_INVOICES, siteId }) && option.value !== 'flagged')
                return false

            return isAuthorized({ permission: option.permission, siteId })
        })
    }

    const isStatusDisabled = ({
        site
    }: any) => {
        const { value: siteId } = site || {}

        if (!siteId) return true

        if (siteId === 0) return !isAuthorized({ permission: CAN_MANAGE_INVOICES })

        return !isAuthorized({ permission: CAN_AUDIT_INVOICES, siteId })
    }

    const InvoiceInput = (props: any) => <InputFormik
        noLabelFocusAnimation
        labelClassName={styles.labels}
        containerClassName={styles.inputs}
        wrapperClassName={styles.wrapper}
        {...props}
    />

    const InvoiceDropdown = (props: any) => <DropdownFormik
        noLabelFocusAnimation
        labelClassName={`${styles.labels} ${styles.dropdownLabels}`}
        containerClassName={`${styles.inputs}} ${styles.dropdowns}`}
        {...props}
    />

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <Button className={styles.backButton} linkStyle>
                    <Link to="/manage-payments">{'<'}</Link>
                </Button>

                <h2 className={styles.title}>Create Invoice</h2>
            </div>

            <Formik
                initialValues={{
                    username: '',
                    name: '',
                    payAmount: '',
                    type: '',
                    status: null,
                    comment: '',
                    site: null,
                    date: null,
                    publishCount: '',
                    sitePublishCount: '',
                    views: '',
                    siteViews: '',
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validateInvoice}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, values, setFieldValue }) => {
                    return (
                        <Form className={styles.invoiceForm}>
                            <InvoiceInput name="name" label="Name" required />

                            <InvoiceInput name="username" label="Username" required />

                            <InvoiceInput
                                name="type"
                                label="Type"
                                required
                                component={RadioSelectGroup}
                                options={typeOptions}
                            />

                            <InvoiceDropdown
                                name="site"
                                label="Site"
                                required
                                isDisabled={gettingSiteOptions}
                                isLoading={gettingSiteOptions}
                                component={DropdownFormik}
                                options={siteValues}
                            />

                            <InvoiceDropdown
                                name="status"
                                label="Status"
                                required
                                isDisabled={isStatusDisabled(values)}
                                component={DropdownFormik}
                                options={buildStatusValues(values)}
                            />

                            <InvoiceInput
                                name="payAmount"
                                label="Amount"
                                required
                                values={values}
                                setFieldValue={setFieldValue}
                                component={Currency}
                            />

                            <InvoiceInput
                                name="date"
                                label="Invoice Month"
                                values={values}
                                component={DatePicker}
                            />

                            <InvoiceInput
                                name="comment"
                                label="Comments"
                                type="textarea"
                                required
                                labelClassName={cx(styles.labels, styles.commentsTextarea)}
                            />

                            <section className={styles.optionalInfo}>
                                <h3>Optional Info</h3>

                                <InvoiceInput name="publishCount" label="User Pub Count" />
                                <InvoiceInput name="sitePublishCount" label="Site Pub Count" />
                                <InvoiceInput name="views" label="Page Views" />
                                <InvoiceInput name="siteViews" label="Site Views" />
                            </section>

                            <Button type="submit" disabled={isSubmitting} size="large" className={styles.submit}>
                                Submit{isSubmitting && 'ting...'}
                            </Button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default CreateInvoice
