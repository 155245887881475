export const CAN_VIEW_USERS = 'can_view_users'
export const CAN_MANAGE_USERS = 'can_manage_users'

export const CAN_EDIT_USERS = 'can_edit_users'

export const CAN_MANAGE_ROLES = 'can_manage_roles'

export const CAN_MANAGE_CONTRACTORS = 'can_manage_contractors'

export const CAN_LOGIN_TO_ALL_SITES = 'can_login_to_all_sites'

export const CAN_AFFILIATE_PODCAST = 'can_affiliate_podcast'
