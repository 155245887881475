import getRoleFromSlug from './getRoleFromSlug'

/**
 *
 * @param {array} roleArr
 */
export const formatCurrentRoles = (
    roleArr: UserRoles,
    roleList: IRoleList,
    siteId: string,
    type: RoleType = 'secondary',
) =>
    roleArr
        .map((r: any) => {
            if (r.siteId !== siteId) {
                return null
            }

            const { name: roleName } = getRoleFromSlug({ slug: r.slug, roleList, type })

            return roleName ? { label: roleName, value: r.slug } : null
        })
        .filter(Boolean)
