import React from 'react'

type OwnProps = {
    fill?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SupportIcon.defaultProps
/* eslint-disable */
// @ts-expect-error ts-migrate(7022) FIXME: 'SupportIcon' implicitly has type 'any' because it... Remove this comment to see the full error message
const SupportIcon = ({ fill, ...rest }: Props) => (
    <svg viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg" fill={fill} {...rest}>
        <path d="m36.2 1.3c-14.7-4.6-30.4 3.7-35 18.4-4.6 14.8 3.7 30.5 18.5 35 14.8 4.6 30.5-3.7 35-18.5s-3.7-30.4-18.5-34.9zm12.3 12.5c1.1 1.7 2.1 3.4 2.8 5.3l-8.9 4.7c-.6-1.9-1.5-3.8-2.8-5.3zm-9.1 17.8c-2 6.3-8.7 9.9-15 7.9s-9.9-8.7-7.9-15 8.7-9.9 15-7.9c6.3 1.9 9.9 8.6 7.9 15zm-4.1-27.5c4.5 1.4 8.4 4 11.3 7.3l-9.3 4.9c-1.4-1.1-3.1-2-4.9-2.6s-3.7-.8-5.5-.6l-4.9-9.3c4.2-1.1 8.8-1.1 13.3.3zm-16.3.6 4.7 8.9c-1.9.6-3.7 1.5-5.3 2.8l-4.7-8.9c1.7-1.2 3.5-2.1 5.3-2.8zm-14.9 15.9c1.4-4.5 4-8.3 7.3-11.3l4.9 9.3c-1.1 1.4-2 3.1-2.6 4.9s-.8 3.7-.6 5.5l-9.4 5c-1.1-4.3-1-8.9.4-13.4zm3.3 21.6c-1.1-1.7-2.1-3.4-2.8-5.3l9-4.7c.6 1.9 1.5 3.7 2.8 5.3zm13.2 9.7c-4.5-1.4-8.3-4-11.3-7.3l9.3-4.9c1.4 1.1 3.1 2 4.9 2.6s3.7.8 5.5.6l4.9 9.3c-4.2 1.1-8.8 1.1-13.3-.3zm16.3-.5-4.7-9c1.9-.6 3.8-1.5 5.3-2.8l4.7 9c-1.7 1.1-3.5 2-5.3 2.8zm14.9-16c-1.4 4.5-4 8.4-7.3 11.3l-4.9-9.3c1.1-1.4 2-3.1 2.6-4.9s.8-3.7.6-5.5l9.3-4.9c1.2 4.2 1.1 8.8-.3 13.3z" />
    </svg>
)

SupportIcon.defaultProps = {
    fill: '#073759',
}

export default SupportIcon
