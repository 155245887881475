import * as users from './users'
import * as global from './global'
import * as auth from './auth'
import * as payments from './payments'
import * as analytics from './analytics'

export default {
    users,
    global,
    auth,
    payments,
    analytics,
}
