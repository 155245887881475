import React, { useContext } from 'react'

import { UsersDetailContext } from '../../../UsersDetailContext'
import { setSingleUser } from '../../../../../../state/userState'

import SiteRow from './SiteRow'

import { useRoleList } from '../../../../../../lib/utils/hooks'

import styles from '../siteDetailsTab.module.scss'

type Props = {
    inputValue?: string | null
    sites: ISiteList
    roles: UserRoles
}

const SiteListUI: React.FC<Props> = ({ sites, inputValue, roles }) => {
    const { data: userData, userId, isDeactivated, isOwnProfile, sitesMeta } = useContext(UsersDetailContext)
    const setUserData = (userDataArray: any) => setSingleUser(userDataArray[0])

    const [roleList] = useRoleList()

    return (
        <div className={styles.listContainer}>
            {sites.length ? (
                <div className={styles.headers}>
                    <div className={styles.spacer} />
                    <span>Primary Roles</span>
                    <span className={styles.secondaryRoleHeader}>Secondary Roles</span>
                    <span>Last Post</span>
                    <span>IC</span>
                    <span className={styles.fee}>Fee Model</span>
                </div>
            ) : null}
            {sites
                .filter(
                    (item: any) =>
                        item?.name?.toLowerCase().includes(inputValue?.toLowerCase()) ||
                        item?.url?.toLowerCase().includes(inputValue?.toLowerCase()),
                )
                .map((item: any, i: any) => {
                    return (
                        <SiteRow
                            key={item.id}
                            item={item}
                            isOwnProfile={isOwnProfile}
                            isDeactivated={isDeactivated}
                            sitesMeta={sitesMeta}
                            i={i}
                            userId={userId}
                            roles={roles}
                            userData={userData}
                            setUserData={setUserData}
                            roleList={roleList}
                        />
                    )
                })}
        </div>
    )
}

SiteListUI.defaultProps = {
    inputValue: '',
}

export default SiteListUI
