/* eslint-disable */
import React from 'react'

type OwnProps = {
    fill?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof MyProfile.defaultProps;
/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7022) FIXME: 'MyProfile' implicitly has type 'any' because it d... Remove this comment to see the full error message
const MyProfile = ({ fill, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={fill} {...rest} viewBox="0 0 28 22">
        <g id="_x31_051-id-badge-selected_x40_2x.png_2_">
            <g>
                <path
                    d="M12.5,6h3C15.8,6,16,5.8,16,5.5v-5C16,0.2,15.8,0,15.5,0h-3C12.2,0,12,0.2,12,0.5v5C12,5.8,12.2,6,12.5,6z
                 M13,1h2v2h-2V1z M13,4h2v1h-2V4z M27,3H17v2.5C17,6.3,16.3,7,15.5,7h-3C11.7,7,11,6.3,11,5.5V3H1C0.4,3,0,3.4,0,4v17
                c0,0.6,0.4,1,1,1h26c0.6,0,1-0.4,1-1V4C28,3.4,27.6,3,27,3z M15.5,18h-11C4.2,18,4,17.8,4,17.5c0-3.8,4.4-1.8,4.9-4.4
                c-0.7-0.5-1.4-1.4-1.4-2.8C7.5,8.5,8.6,7,10,7s2.5,1.5,2.5,3.2c0,1.4-0.7,2.4-1.4,2.8c0.5,2.6,4.9,0.7,4.9,4.4
                C16,17.8,15.8,18,15.5,18z M24,17h-6v-1h6V17z M24,15h-7v-1h7V15z M24,13h-8v-1h8V13z M24,11h-8v-1h8V11z M24,9h-8V8h8V9z"
                />
            </g>
        </g>
    </svg>
)

MyProfile.defaultProps = {
    fill: '',
}

export default MyProfile
