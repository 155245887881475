import React, { useState, useContext } from 'react'
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/react-flex-columns` if it ... Remove this comment to see the full error message
import { Columns, Column } from 'react-flex-columns'
import { formatDistanceToNow, toDate, isBefore, parseISO } from 'date-fns'

import { ManagePaymentsContext } from '../../layouts/DashboardHome/ManagePaymentsContext'
import { Input, Button } from '../../../lib/framework'
import { isAuthorized } from '../../../lib/utils'
import { CAN_AUDIT_INVOICES } from '../../../lib/utils/permissions'

import styles from './styles.module.scss'

type OwnProps = {
    comments: any[]
    onSubmit: (...args: any[]) => any
    enableCommenting?: boolean
    title: string
    siteId: number
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Comments.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'Comments' implicitly has type 'any' because it do... Remove this comment to see the full error message
const Comments = ({ onSubmit: onSubmitProp, comments, enableCommenting, title, siteId }: Props) => {
    const [author, setAuthor] = useState(false)
    const [newComment, setNewComment] = useState('')

    const { date } = useContext(ManagePaymentsContext)
    const disableComments = isBefore(parseISO(date), new Date(2020, 6, 31))

    const onSubmit = (e: any) => {
        e.preventDefault()

        // You can also do an "optimistic save" for the comment here if you want
        setAuthor(false)
        setNewComment('')
        onSubmitProp(newComment)
    }

    const createUserAndDateStamp = (c: any) => {
        let parsed = null
        if (c.createdAt) {
            // TODO: Update  createdAt is sometimes in EDT, sometimes EST
            // const formattedStamp = c.createdAt.concat('-04:00')
            parsed = toDate(new Date(c.createdAt * 1000))
        }

        const { user } = c
        // @ts-expect-error ts-migrate(2345) FIXME: Type 'null' is not assignable to type 'number | Da... Remove this comment to see the full error message
        const timeStamp = c.createdAt ? `${formatDistanceToNow(parsed)} ago` : 'just now'

        return (
            <span>
                {' - '}
                {user ? `${user} - ` : ''}
                <em>{timeStamp}</em>
            </span>
        )
    }

    const formatTiers = (text: any) => {
        const arr = text.split('\n')

        const getTier = (t: any) => arr.find((msg: any) => msg.toLowerCase().includes(t))?.concat('<br />')

        const pTier1 = getTier('personal tier 1')
        const pTier2 = getTier('personal tier 2')
        const pTier3 = getTier('personal tier 3')
        const neTier1 = getTier('non-expert tier 1')
        const neTier2 = getTier('non-expert tier 2')
        const neTier3 = getTier('non-expert tier 3')

        const formattedComments = [pTier1, pTier2, pTier3, neTier1, neTier2, neTier3]
            .filter(Boolean)
            .reduce((a, t) => a + `<span>${t}</span>`, '')

        return formattedComments
    }

    const formatComment = (c: any) => {
        let text = c?.body ?? c.message

        if (title === 'Comments') {
            return text
        }

        if (text.toLowerCase().includes('tier')) {
            text = formatTiers(text)
        }

        return text
    }

    return (
        <div className="owl">
            <h3 className={styles.headerExpanded}>{title}</h3>
            <hr />

            <div className="owl-small">
                {comments.length > 0 &&
                    comments.map((c: any) => {
                        let text = formatComment(c)

                        return (
                            <div key={text} className={styles.comment}>
                                {c.type === 'system' ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: text,
                                        }}
                                    />
                                ) : (
                                    text.split('\n').map((t: any, i: any) => (
                                        <div key={t + i}>
                                            <span>{t}</span>
                                            <br />
                                        </div>
                                    ))
                                )}
                                {createUserAndDateStamp(c)}
                            </div>
                        )
                    })}
                {comments.length === 0 && <em>No Comments Found</em>}
            </div>

            {enableCommenting &&
                (!author ? (
                    isAuthorized({
                        permission: CAN_AUDIT_INVOICES,
                        siteId,
                    }) && (
                        <Button
                            className="button small"
                            onClick={() => setAuthor(true)}
                            disabled={disableComments}
                        >
                            Add Comment
                        </Button>
                    )
                ) : (
                    <form className="owl-medium" onSubmit={onSubmit}>
                        <hr />
                        <Input
                            type="textarea"
                            name="commentTextarea"
                            value={newComment}
                            handleChange={(e) => setNewComment(e.target.value)}
                            autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                        />
                        <Columns split gutters middle>
                            <Column>
                                <Button type="submit" className="button small">
                                    Submit
                                </Button>
                            </Column>
                            <Column>
                                <Button onClick={() => setAuthor(false)}>Cancel</Button>
                            </Column>
                        </Columns>
                    </form>
                ))}
        </div>
    )
}

Comments.defaultProps = {
    enableCommenting: false,
}

export default Comments
