import { format } from 'date-fns'

export const siteStatsColumns = () => [
    {
        Header: 'Name',
        accessor: 'name',
        headerClassName: 'sortable',
        minWidth: 150,
        Cell: ({ original: { name } }: any) => name,
    },
    {
        Header: 'Email',
        accessor: 'email',
        headerClassName: 'sortable',
        minWidth: 250,
        Cell: ({ original: { email } }: any) => email,
    },
    {
        Header: 'Pub Count',
        accessor: 'pubCount',
        headerClassName: 'sortable',
        minWidth: 125,
        Cell: ({ original: { pubCount } }: any) => pubCount,
    },
    {
        Header: 'Last Post',
        accessor: 'lastPost',
        headerClassName: 'sortable',
        minWidth: 125,
        Cell: ({ original: { lastPublished } }: any) =>
            lastPublished ? format(Number(lastPublished) * 1000, 'yyyy-MM-dd') : null,
    },
    {
        Header: 'Page Views',
        headerClassName: 'pageviews',
        columns: [
            {
                Header: 'Tier 1',
                sortable: false,
                width: 90,
                Cell: ({ original: { tier1 } }: any) => tier1,
            },
            {
                Header: 'Tier 2',
                sortable: false,
                width: 90,
                Cell: ({ original: { tier2 } }: any) => tier2,
            },
            {
                Header: 'Tier 3',
                sortable: false,
                width: 90,
                Cell: ({ original: { tier3 } }: any) => tier3,
            },
            {
                Header: 'Tier 4',
                sortable: false,
                width: 90,
                Cell: ({ original: { tier4 } }: any) => tier4,
            },
        ],
    },
]
