import React, { useState, useContext } from 'react'

import { UsersDetailContext } from '../../../UsersDetailContext'
import api from '../../../../../../api'
import { Button } from '../../../../../../lib/framework'
import Image from '../../../../../../lib/framework/Image'

import { pageView } from '../../../../../../lib/utils/googleAnalytics'
import { imageService } from '../../../../../../lib/utils'

import styles from '../siteDetailsTab.module.scss'

const voltaxUrl = 'https://fansided.voltax.io/dashboard'

const SiteLogo = ({ item, useDownshift, handleSSO, index, getItemProps }: any) => {
    const logo = imageService(item.logo, 150, 150)

    return (
        <div className={`${styles.logoButtonWrap}`}>
            <Button
                linkStyle
                className={`${styles.logoButton}`}
                key={item.id}
                {...(useDownshift &&
                    getItemProps({
                        key: item.name,
                        index,
                        item,
                    }))}
                onClick={handleSSO(item.name, item.id, item.voltaxSlug)}
            >
                <Image src={logo} alt={item.name} className={styles.logo} height={150} width={150} />
                <h4 className={styles.logoSiteName}>{item.name}</h4>
            </Button>
        </div>
    )
}

type Props = {
    inputValue?: string | null
    allSites: ISiteList
    userSites: ISiteList
    useDownshift: boolean
    getItemProps: (...args: any[]) => any
}

const SiteLogosUI: React.FC<Props> = ({ inputValue, allSites, userSites, useDownshift, getItemProps }) => {
    const [ssoSiteName, setSsoSiteName] = useState('')
    const [ssoLoading, setSsoLoading] = useState(false)
    const { currentUserId } = useContext(UsersDetailContext)

    /**
     * Logs user into Wordpress on click of site logo.
     *
     * @param {string} siteName
     * @param {string} siteId
     * @param { string | null} voltaxSlug
     */
    const handleSSO = (siteName: any, siteId: any, voltaxSlug: string | null) => async () => {
        setSsoSiteName(siteName)
        setSsoLoading(true)
        pageView({ currentUserId })

        if (voltaxSlug) {
            return (window.location.href = voltaxUrl)
        }

        return api.auth.ssoAuth(siteId, setSsoLoading).catch((e) => e)
    }

    return (
        <>
            {userSites?.length > 0 && (
                <>
                    <h2 className={styles.sectionHeading}>My Sites</h2>

                    <section className={styles.logosContainer} data-testid="mySiteLogosSection">
                        {userSites
                            .filter(
                                (item: any) =>
                                    item?.name?.toLowerCase().includes(inputValue?.toLowerCase()) ||
                                    item?.url?.toLowerCase().includes(inputValue?.toLowerCase()),
                            )
                            .map((item: any, index: any) => (
                                <SiteLogo
                                    item={item}
                                    index={index}
                                    handleSSO={handleSSO}
                                    useDownshift={useDownshift}
                                    getItemProps={getItemProps}
                                    key={item.id}
                                />
                            ))}
                    </section>
                </>
            )}

            {allSites?.length > 0 && (
                <>
                    <h2 className={styles.sectionHeading}>Log in to all sites</h2>
                    <section className={styles.logosContainer} data-testid="fullSiteLogosSection">
                        {allSites
                            .filter(
                                (item: any) =>
                                    item?.name?.toLowerCase().includes(inputValue?.toLowerCase()) ||
                                    item?.url?.toLowerCase().includes(inputValue?.toLowerCase()),
                            )
                            .map((item: any, index: any) => (
                                <SiteLogo
                                    item={item}
                                    index={index}
                                    handleSSO={handleSSO}
                                    useDownshift={useDownshift}
                                    getItemProps={getItemProps}
                                    key={item.id}
                                />
                            ))}
                    </section>
                </>
            )}
            {/* Loading component */}
            {ssoLoading && <div className={styles.ssoLoading}>Redirecting to {ssoSiteName}...</div>}
        </>
    )
}

SiteLogosUI.defaultProps = {
    inputValue: '',
    allSites: [],
}

export default SiteLogosUI
