import React, { useState, useEffect, useContext } from 'react'
import Downshift from 'downshift'
import cx from 'classnames'
import { Helmet } from 'react-helmet'

import { UsersDetailContext } from '../../UsersDetailContext'
import { Input } from '../../../../../lib/framework'
import SiteListUI from './utils/SiteListUI'
import SiteLogosUI from './utils/SiteLogosUI'
import AddSite from '../../../../ui/AddSite'
import Icon from '../../../../../lib/framework/Icon'
import { isAuthorized, getSitesDetailsFromIds } from '../../../../../lib/utils'
import { CAN_MANAGE_USERS } from '../../../../../lib/utils/permissions'

import styles from './siteDetailsTab.module.scss'

type Props = {
    siteList: ISiteList
    siteIds: string[]
    roles: UserRoles
    userData: UserData
}

const SiteDetailsTab: React.FC<Props> = ({ siteList, siteIds, roles, userData }) => {
    const { isOwnProfile, isDeactivated } = useContext(UsersDetailContext)

    const [showAddSite, setShowAddSite] = useState(false)
    const [useDownshift, setUseDownshift] = useState(false)
    const [viewOption, setViewOption] = useState(isOwnProfile ? 'logos' : 'list')

    const canManageUsers = isAuthorized({ permission: CAN_MANAGE_USERS })

    const unsortedUserSites = getSitesDetailsFromIds(siteIds, siteList)

    const userSitesWithGlobal = [
        ...unsortedUserSites.filter((site) => site.id === '0'),
        ...unsortedUserSites
            .filter((site) => site.id !== '0')
            .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    ]
    const userSites = userSitesWithGlobal.filter((s) => s.id !== '0')

    const allSitesWithoutUserSites = canManageUsers
        ? siteList.filter((s: any) => !userSites.some((el) => el.id === s.id))
        : []

    /**
     * Set visibility of downshift search box.
     *
     */
    useEffect(
        () =>
            userSites.length > 7 || (canManageUsers && isOwnProfile)
                ? setUseDownshift(true)
                : setUseDownshift(false),
        [canManageUsers, isOwnProfile, userSites],
    )

    const handleToggle = () => setShowAddSite(!showAddSite)

    /**
     * Toggles site view.
     *
     * Returns an anonymous func.
     * This is to allow passing args to the func in props without calling it every render.
     *
     * @param {('logo'|'list')} view
     * @return {func}
     */
    const handleClick = (view: any) => () => setViewOption(view)

    return (
        <div className={styles.siteBox}>
            <Helmet>
                <title>FS App Console - {isOwnProfile ? 'My Sites' : 'User Sites'} </title>
            </Helmet>

            <Downshift itemToString={(item) => (item ? item.name : '')}>
                {({ getInputProps, getItemProps, inputValue, getLabelProps }) => (
                    <div>
                        <div className={styles.options}>
                            {isOwnProfile && (
                                <div className={styles.viewOptions}>
                                    <button
                                        type="button"
                                        onClick={handleClick('logos')}
                                        className={cx(styles.option, viewOption === 'logos' && styles.active)}
                                    >
                                        <Icon icon="ViewIconsOption" fill="#dfdfdf" size={2} />
                                    </button>
                                    <span> | </span>
                                    <button
                                        type="button"
                                        onClick={handleClick('list')}
                                        className={cx(styles.option, viewOption === 'list' && styles.active)}
                                    >
                                        <Icon icon="ViewListOption" fill="#dfdfdf" size={2} />
                                    </button>
                                </div>
                            )}
                            {useDownshift && (
                                <Input
                                    containerClassName={styles.searchSites}
                                    // @ts-expect-error ts-migrate(2783) FIXME: 'name' is specified more than once, so this usage ... Remove this comment to see the full error message
                                    name="sitesFilter"
                                    icon="search"
                                    label="Search Sites"
                                    {...getInputProps()}
                                    getLabelProps={getLabelProps}
                                    handleChange={() => null}
                                    style={isOwnProfile ? { marginLeft: '2rem' } : {}}
                                />
                            )}
                        </div>

                        {viewOption === 'list' && (
                            <SiteListUI sites={userSitesWithGlobal} inputValue={inputValue} roles={roles} />
                        )}
                        {viewOption === 'logos' && (
                            <SiteLogosUI
                                inputValue={inputValue}
                                allSites={allSitesWithoutUserSites}
                                userSites={userSites}
                                useDownshift={useDownshift}
                                getItemProps={getItemProps}
                            />
                        )}
                    </div>
                )}
            </Downshift>

            {!isOwnProfile && canManageUsers && (
                <AddSite
                    flipped={showAddSite}
                    handleToggle={handleToggle}
                    userData={userData}
                    userSites={userSites}
                    disabled={isDeactivated}
                />
            )}
        </div>
    )
}

export default SiteDetailsTab
