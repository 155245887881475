import { isAuthorized } from '../../lib/utils'
import { CAN_MANAGE_INVOICES, CAN_AUDIT_INVOICES } from '../../lib/utils/permissions'

export const cycleStatus = (status: any, siteId: any) => {
    switch (status) {
        case 'pending':
            return isAuthorized({ permission: CAN_AUDIT_INVOICES, siteId }) ? 'audited' : status
        case 'audited':
            return isAuthorized({ permission: CAN_MANAGE_INVOICES, siteId }) ? 'approved' : status

        default:
            return status
    }
}
