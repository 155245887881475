import { http, handleError } from '../lib/utils'

/**
 * Redirects to a specific wp-admin after a successful login,
 * or on click of a site in a user's sites view,
 * after they've logged into the app normally
 *
 * @param {number} siteId
 * @param {func} loadingFunc
 *
 */
export const ssoAuth = async (siteId: any, loadingFunc: any, redirectPath = null) => {
    try {
        const res = await http.get('/wordPressSso/handshake', {
            params: {
                siteId,
                // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
                ...(redirectPath && { redirectPath }),
            },
        })

        loadingFunc && loadingFunc(false)
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'redirect' does not exist on type 'AxiosR... Remove this comment to see the full error message
        const { redirect } = res

        window.location.href = redirect
    } catch (e) {
        return handleError(e, loadingFunc, true)
    }
}
