import { createReducer, createAction } from 'redux-act'

import store from '../store'

import {
    initialUserDataShape,
    makeAsyncAction,
    makeAsyncReducer,
    makeAsyncStateProperty,
} from '../lib/utils/storeUtils'

const actions = {
    ...makeAsyncAction('SET_SITE_LIST'),
    ...makeAsyncAction('SET_VOLTAX_SITES_LIST'),
    ...makeAsyncAction('SET_ROLE_LIST'),
    ...makeAsyncAction('SET_PERMISSION_LIST'),
    ...makeAsyncAction('SET_CURRENT_USER'),
    setNavigation: createAction('SET_NAV'),
    setToasts: createAction('SET_TOASTS'),
}

export const initialState = {
    ...makeAsyncStateProperty({ key: 'siteList', initialData: [], initialLoadingState: false }),
    voltaxSitesList: {
        data: [],
    },
    ...makeAsyncStateProperty({ key: 'roleList', initialData: [], initialLoadingState: false }),
    ...makeAsyncStateProperty({ key: 'permissionList', initialData: [], initialLoadingState: false }),
    currentUser: {
        isLoading: false,
        data: initialUserDataShape as UserData,
    },
    navigation: [],
    toasts: [],
}

export default createReducer(
    {
        ...makeAsyncReducer({ actions, actionKey: 'setSiteList', stateKey: 'siteList' }),
        ...makeAsyncReducer({ actions, actionKey: 'setVoltaxSitesList', stateKey: 'voltaxSitesList' }),
        ...makeAsyncReducer({ actions, actionKey: 'setRoleList', stateKey: 'roleList' }),
        ...makeAsyncReducer({ actions, actionKey: 'setPermissionList', stateKey: 'permissionList' }),
        ...makeAsyncReducer({ actions, actionKey: 'setCurrentUser', stateKey: 'currentUser' }),
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [actions.setNavigation]: (state, navigation) => ({
            ...state,
            navigation,
        }),
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [actions.setToasts]: (state, toasts) => ({
            ...state,
            toasts,
        }),
    },
    initialState,
)

/**
 * Setters
 */

// @ts-expect-error ts-migrate(2339) FIXME: Property 'setSiteListLoading' does not exist on ty... Remove this comment to see the full error message
export const setSiteListLoading = (bool: any) => store.dispatch(actions.setSiteListLoading(bool))

export const setSiteList = (sites: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setSiteList' does not exist on type '{ s... Remove this comment to see the full error message
    store.dispatch(actions.setSiteList(sites))
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setSiteListLoading' does not exist on ty... Remove this comment to see the full error message
    store.dispatch(actions.setSiteListLoading(false))
}

export const setVoltaxSitesList = (voltaxSites: any) => {
    // @ts-expect-error ts-migrate(2339)
    store.dispatch(actions.setVoltaxSitesList(voltaxSites))
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'setRoleListLoading' does not exist on ty... Remove this comment to see the full error message
export const setRoleListLoading = (bool: any) => store.dispatch(actions.setRoleListLoading(bool))

export const setRoleList = (roleList: IRoleList) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setRoleList' does not exist on type '{ s... Remove this comment to see the full error message
    store.dispatch(actions.setRoleList(roleList))
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setRoleListLoading' does not exist on ty... Remove this comment to see the full error message
    store.dispatch(actions.setRoleListLoading(false))
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'setPermissionListLoading' does not exist... Remove this comment to see the full error message
export const setPermissionListLoading = (bool: any) => store.dispatch(actions.setPermissionListLoading(bool))

export const setPermissionList = (permissionList: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setPermissionList' does not exist on typ... Remove this comment to see the full error message
    store.dispatch(actions.setPermissionList(permissionList))
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setPermissionListLoading' does not exist... Remove this comment to see the full error message
    store.dispatch(actions.setPermissionListLoading(false))
}

export const setNavigation = (navigation: any) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    store.dispatch(actions.setNavigation(navigation))
}

export const setCurrentUser = (currentUser: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setCurrentUser' does not exist on type '... Remove this comment to see the full error message
    store.dispatch(actions.setCurrentUser(currentUser))
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setCurrentUserLoading' does not exist on... Remove this comment to see the full error message
    store.dispatch(actions.setCurrentUserLoading(false))
}

export const setCurrentUserLoading = (bool: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setCurrentUserLoading' does not exist on... Remove this comment to see the full error message
    store.dispatch(actions.setCurrentUserLoading(bool))
}

export const setToasts = (toasts: any) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    store.dispatch(actions.setToasts(toasts))
}
