import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { format, isSameMonth, parseISO } from 'date-fns'

import { Table } from '../../../../lib/framework'
import { paymentHistoryColumns } from '../userStatsTableColumns'

import api from '../../../../api'
import Loading from '../../../../lib/framework/Loading'

import styles from '../userStats.module.scss'

type OwnProps = {
    userId: string
    lastSixMonths: any[] // TODO: PropTypes.instanceOf(Date).isRequired
    paymentsHistoryStartDate: string
    paymentsHistoryEndDate: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof PaymentHistory.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'PaymentHistory' implicitly has type 'any' because ... Remove this comment to see the full error message
const PaymentHistory = ({ userId, lastSixMonths, paymentsHistoryStartDate, paymentsHistoryEndDate }: Props) => {
    const [paymentsLoading, setPaymentsLoading] = useState(false)
    const [paymentHistory, setPaymentHistory] = useState([])
    const [expanded, setExpanded] = useState({ 0: false })

    const { userId: pathUserId } = useParams()

    const handleRowExpanded = (_: any, index: any) => {
        // Set to false if already exists (i.e., it's open), else true (open)
        // @ts-expect-error ts-migrate(2345) FIXME: Property '0' is missing in type '{ [x: number]: bo... Remove this comment to see the full error message
        setExpanded({ [index]: !expanded[index] })
    }

    useEffect(() => {
        const updatePaymentHistory = async () => {
            setPaymentsLoading(true)

            try {
                const paymentsData = await api.payments.contractors.getUserInvoices({
                    userId,
                    startDate: paymentsHistoryStartDate,
                    endDate: paymentsHistoryEndDate,
                    setPaymentsLoading,
                })

                const totalForEachMonth = lastSixMonths
                    .map((date: any) => {
                        const paymentsByMonth = paymentsData?.filter((payment: any) => {
                            return isSameMonth(parseISO(payment.date), date)
                        })

                        if (!paymentsByMonth?.length) {
                            return null
                        }

                        const totalPayment = paymentsByMonth.reduce((acc: any, curr: any) => {
                            return acc + Number(curr.total.replace(/,/g, ''))
                        }, 0)

                        return {
                            payments: paymentsByMonth,
                            amount: totalPayment,
                            month: format(parseISO(paymentsByMonth[0].date), 'MMMM yyyy'),
                        }
                    })
                    .filter((el: any) => {
                        return el?.month
                    })

                setPaymentHistory(totalForEachMonth)
            } catch (e) {
                console.error(e) // eslint-disable-line
            }
            setPaymentsLoading(false)
        }

        updatePaymentHistory()
    }, [lastSixMonths, paymentsHistoryEndDate, paymentsHistoryStartDate, userId])

    return (
        <section className={styles.container}>
            <h2>{pathUserId ? 'Payment History' : 'My Payment History'}</h2>

            <Table
                resizable={false}
                loading={paymentsLoading}
                loadingText={''}
                noDataText={<span>No data found</span>}
                minRows={3}
                data={paymentHistory}
                showPageSizeOptions={false}
                showPagination={false}
                defaultSorted={[
                    {
                        id: 'month',
                        desc: true,
                    },
                ]}
                LoadingComponent={() => (
                    <Loading loading={paymentsLoading} style={{ transform: 'translateY(-2px)' }} />
                )}
                expanded={expanded}
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
                onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index, event)}
                SubComponent={({ row: { _original: original } }: any) => (
                    <Table
                        // className={styles.paymentsBySite}
                        subTable={true}
                        resizable={false}
                        loading={paymentsLoading}
                        loadingText={''}
                        noDataText={<span>No data found</span>}
                        showPagination={false}
                        data={original.payments}
                        columns={[
                            {
                                Header: 'Site Name',
                                headerClassName: 'sortable',
                                Cell: ({ original: { site } }: any) => site,
                            },
                            {
                                Header: 'Status',
                                sortable: false,
                                Cell: ({ original: { status } }: any) => status,
                            },
                            {
                                Header: 'Amount',
                                sortable: false,
                                Cell: ({ original: { total } }: any) =>
                                    new Intl.NumberFormat('en-US', {
                                        currency: 'USD',
                                        style: 'currency',
                                        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                                    }).format(String(total).replace(/,/g, '')),
                            },
                        ]}
                        LoadingComponent={() => (
                            <Loading loading={paymentsLoading} style={{ transform: 'translateY(-2px)' }} />
                        )}
                    />
                )}
                onPageChange={() => {
                    // @ts-expect-error ts-migrate(2345) FIXME: Type '{}' provides no match for the signature '(pr... Remove this comment to see the full error message
                    setExpanded({})
                }}
                columns={paymentHistoryColumns()}
            />
        </section>
    )
}

export default PaymentHistory
