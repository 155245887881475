import React from 'react'
import { Link } from 'react-router-dom'
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/react-flex-columns` if it ... Remove this comment to see the full error message
import { Columns, Column } from 'react-flex-columns'
import styles from './detailItem.module.scss'

type OwnProps = {
    label: string
    to?: string
    children: React.ReactNode
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DetailItem.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'DetailItem' implicitly has type 'any' because it ... Remove this comment to see the full error message
const DetailItem = ({ label, to, children }: Props) => {
    const linkExternal = (link: any) => {
        const linkObj = new URL(link)
        return linkObj.hostname !== window.location.hostname
    }

    return (
        <Columns gutters split>
            <Column>
                <div className={styles.confine}>
                    {to &&
                        (linkExternal(to) ? (
                            <a href={to} target="_blank" rel="noopener noreferrer">
                                {label}
                            </a>
                        ) : (
                            <Link to={to}>{label}</Link>
                        ))}
                    {!to && label}
                </div>
            </Column>
            <Column className={styles.postNumber}>{children}</Column>
        </Columns>
    )
}

DetailItem.defaultProps = {
    to: null,
}

export default DetailItem
