import { combineReducers } from 'redux'

import filteringState from './filteringState'
import invoiceState from './invoiceState'
import flatRateState from './flatRateState'

const paymentsState = combineReducers({
    filteringState,
    invoiceState,
    flatRateState,
})

export default paymentsState
