import React from 'react'
import cx from 'classnames'

import styles from './box.module.scss'

type Props = {
    children: React.ReactNode[] | React.ReactNode
    className?: string
    innerClassName?: string
    title?: string
    backgroundColor?: boolean
}

const Box: React.FC<Props> = (props) => {
    const { children, className, innerClassName, title, backgroundColor, ...rest } = props

    return (
        <div className={cx(styles.box, className && className)} {...rest}>
            {title ? (
                <div className={styles.boxHeader}>
                    <h2>{title}</h2>
                </div>
            ) : (
                ''
            )}
            <div
                className={cx(
                    styles.boxContent,
                    !title && backgroundColor && styles.background,
                    innerClassName && innerClassName,
                )}
            >
                {children}
            </div>
        </div>
    )
}

Box.defaultProps = {
    innerClassName: '',
    className: '',
    title: '',
    backgroundColor: false,
}

export default Box
