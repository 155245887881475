import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'

import { getUserData, customRoleDeleteToast, getVoltaxFirstId } from '../../../lib/utils'
import { Button } from '../../../lib/framework'
import api from '../../../api'

import styles from './deleteSite.module.scss'

type OwnProps = {
    siteId: string
    className?: string
    siteName: string
    userId: string
    disabled?: boolean
    voltaxSitesList: VoltaxSiteObject[]
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DeleteSite.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'DeleteSite' implicitly has type 'any' because it ... Remove this comment to see the full error message
const DeleteSite = ({ siteId, siteName, userId, disabled, className, voltaxSitesList, ...rest }: Props) => {
    const [selectedUserName, setSelectedUserName] = useState('this user')

    useEffect(() => {
        const { firstName, lastName } = getUserData(userId)
        firstName && lastName && setSelectedUserName(`${firstName} ${lastName}`)
    }, [userId])

    const removeUserFromSite = () => {
        const { roles } = getUserData(userId)
        const voltaxSiteId = getVoltaxFirstId(siteId, voltaxSitesList)[0]

        const roleIdsForThisSite = roles
            .filter((r: any) => r.siteId === siteId || r.siteId === voltaxSiteId)
            .map((r: any) => r.slug)

        api.users.deleteUserRoles({
            id: userId,
            roles: roleIdsForThisSite,
            siteId,
        })
    }

    return (
        <Button
            size="small"
            type="danger"
            onClick={() =>
                customRoleDeleteToast({
                    siteName,
                    userName: selectedUserName,
                    removeFunction: removeUserFromSite,
                })
            }
            className={cx(styles.delete, className)}
            key={siteId}
            disabled={disabled}
            {...rest}
        >
            X
        </Button>
    )
}

DeleteSite.defaultProps = {
    className: '',
    disabled: false,
}

const stateToProps = ({ globalState }: any) => ({
    voltaxSitesList: globalState.voltaxSitesList?.data,
})

export default connect(stateToProps)(DeleteSite)
