import React, { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import cx from 'classnames'
import Button from '../Button'
import { useOnClickOutside } from '../../../lib/utils/hooks'
import styles from './editableCellInputField.module.scss'

type OwnProps = {
    displayValue: string
    value: string
    type?: string
    onSubmit: (...args: any[]) => any
    disabled: boolean
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof EditableCellInputField.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'EditableCellInputField' implicitly has type 'any'... Remove this comment to see the full error message
const EditableCellInputField = ({ value, displayValue, type, onSubmit, disabled }: Props) => {
    const [editing, setEditing] = useState(false)
    const [newValue, setNewValue] = useState()

    const inputRef = useRef()

    const submit = (e: any) => {
        e.preventDefault()
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        const newValueAsFloat = parseFloat(newValue)

        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
        setNewValue()
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        inputRef.current.blur()

        if (Number.isNaN(newValueAsFloat)) return

        const newValueAsFloatString = newValueAsFloat.toFixed(2)

        if (value !== newValueAsFloatString) {
            if (newValueAsFloatString === '0.00') {
                toast.error('Please mark invoice "Ineligible" instead of using override "$0"')
                onSubmit(null)
            } else {
                onSubmit(newValueAsFloatString)
            }
        }
    }

    useOnClickOutside(inputRef, (e: any) => submit(e))

    useEffect(() => {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        inputRef.current && inputRef.current.select() // eslint-disable-line
    }, [editing])

    const startEditing = () => setEditing(true)

    return (
        <>
            {!editing ? (
                <Button
                    className={cx('appButtonReset', styles.plainMode)}
                    onClick={startEditing}
                    disabled={disabled}
                >
                    {displayValue}
                </Button>
            ) : (
                <form onSubmit={submit}>
                    <input
                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'HTMLIn... Remove this comment to see the full error message
                        ref={inputRef}
                        className={styles.input}
                        type={type}
                        step="0.01"
                        value={newValue}
                        onBlur={() => setEditing(false)}
                        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                        onChange={(e) => setNewValue(e.target.value)} // eslint-disable-line jsx-a11y/no-onchange
                    />
                </form>
            )}
        </>
    )
}

EditableCellInputField.defaultProps = {
    type: 'input',
}

export default EditableCellInputField
