import React from 'react'

import AddSiteButton from './ui/AddSiteButton'
import AddSiteRow from './ui/AddSiteRow'
import { FlipRow } from '../../../lib/utils'

type Props = {
    className?: string
    disabled?: boolean
    flipped: boolean
    handleToggle: (arg: boolean) => void
    userData: UserData
    userSites: ISiteList
}

const AddSite = ({ flipped, handleToggle, userData, userSites, className, disabled }: Props) => {
    return (
        <FlipRow
            height={120}
            flipped={flipped}
            front={() => <AddSiteButton handleToggle={handleToggle} disabled={disabled} />}
            back={() => <AddSiteRow handleToggle={handleToggle} userData={userData} userSites={userSites} />}
            className={className}
        />
    )
}

export default AddSite
