import React, { useState, useEffect } from 'react'

import api from '../../../../api'
import styles from '../siteStats.module.scss'

type OwnProps = {
    siteId: string
    startDate: string
    endDate: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SiteStatsCards.defaultProps

const INITIAL_SITE_VIEWS = [
    { views: 0, geoTier: 1 },
    { views: 0, geoTier: 2 },
    { views: 0, geoTier: 3 },
]

// @ts-expect-error ts-migrate(7022) FIXME: 'SiteStatsCards' implicitly has type 'any' because... Remove this comment to see the full error message
const SiteStatsCards = ({ startDate, endDate, siteId }: Props) => {
    const [siteViews, setSiteViews] = useState(0)
    const [sitePostCount, setSitePostCount] = useState(0)
    const [nonExpertPageViews, setNonExpertPageViews] = useState(INITIAL_SITE_VIEWS)

    useEffect(() => {
        if (!siteId) return

        const cb = async () => {
            const getNonExpertPageViews = async () => {
                setNonExpertPageViews(INITIAL_SITE_VIEWS)

                const nonExpertPageViews = await api.payments.siteStats.getNonExpertPageViews({
                    siteId,
                    startDate,
                    endDate,
                })

                if (nonExpertPageViews) {
                    setNonExpertPageViews(nonExpertPageViews)
                }
            }

            const getSiteViews = async () => {
                setSiteViews(0)

                const siteviews =
                    (await api.payments.siteStats.getSiteViews({
                        siteId,
                        startDate,
                        endDate,
                    })) || []

                const totalSiteViews = siteviews.reduce((total, item) => total + item.views, 0)

                setSiteViews(totalSiteViews)
            }

            const getSitePostCount = async () => {
                setSitePostCount(0)

                const postCount = await api.payments.siteStats.getSitePostCount({
                    siteId,
                    startDate,
                    endDate,
                })

                if (postCount) {
                    setSitePostCount(postCount)
                }
            }

            getNonExpertPageViews()
            getSitePostCount()
            getSiteViews()
        }

        if (siteId) {
            cb()
        }
    }, [endDate, siteId, startDate])

    return (
        <section className={styles.siteStats}>
            <div>
                Pub Count
                <div className={styles.stat}>{sitePostCount}</div>
            </div>
            <div>
                Total Site Views
                <div className={styles.stat}>{siteViews.toLocaleString()}</div>
            </div>
            <div className={styles.pageViews}>
                Non-Expert Page Views
                <div className={styles.tiers}>
                    {nonExpertPageViews
                        .sort((a, b) => (a.geoTier > b.geoTier ? 1 : -1))
                        .map((v) => (
                            <div key={v.geoTier}>
                                T{v.geoTier}
                                <div className={styles.stat}>{v.views.toLocaleString()}</div>
                            </div>
                        ))}
                </div>
            </div>
        </section>
    )
}

export default SiteStatsCards
