export const userStatsColumns = (siteList: any) => [
    {
        Header: 'Site Name',
        headerClassName: 'sortable',
        minWidth: 300,
        Cell: ({ original: { siteId } }: any) => {
            const { name = '' } = siteList.find((s: any) => s.id === siteId) || {}

            return name
        },
    },
    {
        Header: 'Pub Count',
        headerClassName: 'sortable',
        minWidth: 125,
        Cell: ({ original: { pubCount } }: any) => pubCount,
    },
    {
        Header: 'Page Views',
        headerClassName: 'pageviews',
        columns: [
            {
                Header: 'Tier 1',
                sortable: false,
                width: 100,
                Cell: ({ original: { tier1 } }: any) => tier1,
            },
            {
                Header: 'Tier 2',
                sortable: false,
                width: 100,
                Cell: ({ original: { tier2 } }: any) => tier2,
            },
            {
                Header: 'Tier 3',
                sortable: false,
                width: 100,
                Cell: ({ original: { tier3 } }: any) => tier3,
            },
            {
                Header: 'Tier 4',
                sortable: false,
                width: 100,
                Cell: ({ original: { tier4 } }: any) => tier4,
            },
        ],
    },
]

export const paymentHistoryColumns = () => [
    {
        Header: 'Month',
        headerClassName: 'sortable',
        // minWidth: 300,
        Cell: ({ original: { month } }: any) => month,
    },
    {
        Header: 'Amount',
        Cell: ({ original: { amount } }: any) =>
            new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(amount),
    },
]
