import { countryData, stateData } from './countryStateData'

export const formatUserDetails = (data: any) => {
    const {
        firstName,
        lastName,
        email,
        fullName,
        displayName,
        profilePic,
        bio,
        twitter,
        facebook,
        instagram,
        country,
        state,
    } = data || {}

    const stateObj = stateData.find((s) => s.value === state)
    const countryObj = countryData.find((c) => c.value === country)

    const countryLabel = countryObj && countryObj.label
    const stateLabel = stateObj && stateObj.label

    const values = {
        ...(firstName ? { firstName } : { firstName: '' }),
        ...(lastName ? { lastName } : { lastName: '' }),
        ...(email ? { email } : { email: '' }),
        ...(fullName ? { fullName } : { fullName: '' }),
        ...(profilePic ? { profilePic } : { profilePic: '' }),
        ...(bio ? { bio } : { bio: '' }),
        ...(displayName ? { displayName } : { displayName: '' }),
        ...(twitter ? { twitter } : { twitter: '' }),
        ...(facebook ? { facebook } : { facebook: '' }),
        ...(instagram ? { instagram } : { instagram: '' }),
        ...(country
            ? { country: { label: countryLabel, value: country } }
            : { country: { label: '', value: '' } }),
        ...(state ? { state: { label: stateLabel, value: state } } : { state: { label: '', value: '' } }),
    }

    return values
}
