import { createReducer, createAction } from 'redux-act'

import store from '../store'
import api from '../api'
import { makeAsyncAction, makeAsyncReducer, makeAsyncStateProperty } from '../lib/utils/storeUtils'

const actions = {
    ...makeAsyncAction('SET_INVOICE_DATA'),
    clearData: createAction('CLEAR_DATA'),
}

const initialState = {
    ...makeAsyncStateProperty({ key: 'invoiceData', initialData: null }),
    allSelected: false,
}

export default createReducer(
    {
        ...makeAsyncReducer({ actions, actionKey: 'setInvoiceData', stateKey: 'invoiceData' }),
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [actions.clearData]: () => initialState,
    },
    initialState,
)

/**
 * Setters
 */
// @ts-expect-error ts-migrate(2339) FIXME: Property 'setInvoiceDataLoading' does not exist on... Remove this comment to see the full error message
export const setInvoiceDataLoading = (bool: any) => store.dispatch(actions.setInvoiceDataLoading(bool))

const buildVoltaxURL = (posts: any, domain: string) => {
    const postsWithCorrectURL = posts.map((post: any) => {
        if (!post.url.includes('https://')) {
            post.url = `https://${domain}/posts/${post.url}`
        }
        return post
    })
    return postsWithCorrectURL
}

export const setInvoiceData = (data: any) => {
    const voltaxSitesList = store.getState().globalState.voltaxSitesList?.data as VoltaxSiteObject[]
    const modifiedData = data.map(
        ({ invoiceComments, postStats, comments: oldComments, stats, siteId, ...row }: any) => {
            const comments = invoiceComments ?? oldComments
            let posts = postStats ?? stats
            const voltaxDomain = voltaxSitesList.find((site: any) => site.id === String(siteId))?.domain || null

            if (posts && voltaxDomain) {
                posts = buildVoltaxURL(posts, voltaxDomain)
            }

            return {
                ...row,
                siteId,
                comments,
                posts,
                selected: false,
            }
        },
    )

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInvoiceData' does not exist on type '... Remove this comment to see the full error message
    store.dispatch(actions.setInvoiceData(modifiedData))
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInvoiceDataLoading' does not exist on... Remove this comment to see the full error message
    store.dispatch(actions.setInvoiceDataLoading(false))
}

export const setDetailsData = ({ invoiceComments, postStats, comments: oldComments, stats, ...data }: any) => {
    const comments = invoiceComments ?? oldComments
    const posts = postStats ?? stats

    const newInvoice = {
        ...data,
        comments,
        posts,
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'invoiceData' does not exist on type '{ a... Remove this comment to see the full error message
    const { data: oldInvoices } = store.getState().paymentsState.invoiceState.invoiceData

    const newData = oldInvoices.map((inv: any) => {
        if (inv.id === data.id) {
            return newInvoice
        }
        return inv
    })

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInvoiceData' does not exist on type '... Remove this comment to see the full error message
    store.dispatch(actions.setInvoiceData(newData))
}

export const toggleSelectedRow = (id: any) => {
    const data = store
        .getState()
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'invoiceData' does not exist on type '{ a... Remove this comment to see the full error message
        .paymentsState.invoiceState.invoiceData.data.map((d: any) =>
            d.id === id ? { ...d, selected: !d.selected } : d,
        )
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInvoiceData' does not exist on type '... Remove this comment to see the full error message
    store.dispatch(actions.setInvoiceData(data))
}

/**
 * Selects all invoices within a range
 *
 * @param {number} startIndex
 * @param {number} endIndex
 * @param {Array} dataOverride
 */
export const selectAll = (startIndex: any, endIndex: any, dataOverride = []) => {
    if (dataOverride.length > 0) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property '_original' does not exist on type 'never... Remove this comment to see the full error message
        const selectedIds = [...dataOverride].slice(startIndex, endIndex + 1).map((d) => d._original.id) // eslint-disable-line
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'invoiceData' does not exist on type '{ a... Remove this comment to see the full error message
        const data = store.getState().paymentsState.invoiceState.invoiceData.data.map((d: any) => {
            const selected = selectedIds.includes(d.id) && ['pending', 'audited', 'approved'].includes(d.status)
            return { ...d, selected }
        })
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInvoiceData' does not exist on type '... Remove this comment to see the full error message
        return store.dispatch(actions.setInvoiceData(data))
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'invoiceData' does not exist on type '{ a... Remove this comment to see the full error message
    const data = store.getState().paymentsState.invoiceState.invoiceData.data.map((d: any, i: any) => {
        const selected = i >= startIndex && i <= endIndex && ['pending', 'audited', 'approved'].includes(d.status)
        return { ...d, selected }
    })

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInvoiceData' does not exist on type '... Remove this comment to see the full error message
    return store.dispatch(actions.setInvoiceData(data))
}

export const deselectOne = (selectedIndex: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'invoiceData' does not exist on type '{ a... Remove this comment to see the full error message
    const data = store.getState().paymentsState.invoiceState.invoiceData.data.map((d: any, i: any) => {
        const selected = i === selectedIndex ? false : d.selected

        return { ...d, selected }
    })
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInvoiceData' does not exist on type '... Remove this comment to see the full error message
    store.dispatch(actions.setInvoiceData(data))
}

export const selectNone = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'invoiceData' does not exist on type '{ a... Remove this comment to see the full error message
    const data = store.getState().paymentsState.invoiceState.invoiceData.data.map((d: any) => ({
        ...d,
        selected: false,
    }))
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInvoiceData' does not exist on type '... Remove this comment to see the full error message
    store.dispatch(actions.setInvoiceData(data))
}

export const updateSelectedStatus = (status: any) => {
    /**
     * Get the invoiceIds that should be updated on the server
     */
    const invoiceIds = store
        .getState()
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'invoiceData' does not exist on type '{ a... Remove this comment to see the full error message
        .paymentsState.invoiceState.invoiceData.data.reduce(
            (acc: any, item: any) => (item.selected && item.status !== status ? [...acc, item.id] : acc),
            [],
        )

    if (invoiceIds.length > 0) {
        api.payments.invoices.bulkUpdateStatus(invoiceIds, status)
    }

    /**
     * If invoice is selected, update it's status
     */
    const data = store
        .getState()
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'invoiceData' does not exist on type '{ a... Remove this comment to see the full error message
        .paymentsState.invoiceState.invoiceData.data.map((d: any) =>
            d.selected ? { ...d, selected: false, status } : d,
        )
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInvoiceData' does not exist on type '... Remove this comment to see the full error message
    store.dispatch(actions.setInvoiceData(data))
}

export const setOverrideValue = (id: any, value: any) => {
    const data = store
        .getState()
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'invoiceData' does not exist on type '{ a... Remove this comment to see the full error message
        .paymentsState.invoiceState.invoiceData.data.reduce(
            (d: any, row: any) => d.concat([row.id === id ? { ...row, override: value } : row]),
            [],
        )
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInvoiceData' does not exist on type '... Remove this comment to see the full error message
    store.dispatch(actions.setInvoiceData(data))

    api.payments.invoices.updateInvoice(id, { override: value })
}

export const setStatus = (id: any, status: any) => {
    const data = store
        .getState()
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'invoiceData' does not exist on type '{ a... Remove this comment to see the full error message
        .paymentsState.invoiceState.invoiceData.data.reduce(
            (d: any, row: any) => d.concat([row.id === id ? { ...row, status } : row]),
            [],
        )

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInvoiceData' does not exist on type '... Remove this comment to see the full error message
    store.dispatch(actions.setInvoiceData(data))

    api.payments.invoices.updateInvoice(id, { status })
}

export const setComment = (id: any, message: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'invoiceData' does not exist on type '{ a... Remove this comment to see the full error message
    const { data } = store.getState().paymentsState.invoiceState.invoiceData

    const newData = data.map((inv: any) => {
        if (inv.id === id) {
            const { comments: oldComments = [] } = inv

            const newInvoice = {
                ...inv,
                comments: [
                    {
                        type: 'note',
                        body: message,
                        createdAt: Date.now() / 1000,
                    },
                    ...oldComments,
                ],
            }

            return newInvoice
        }

        return inv
    })

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setInvoiceData' does not exist on type '... Remove this comment to see the full error message
    store.dispatch(actions.setInvoiceData(newData))
}

export const clearData = () => {
    store.dispatch(actions.clearData())
}

/**
 * Getters
 */

// Tells whether or not all the data records are selected
export const getMetaData = (state: any) => {
    if (!state.data) return {}

    const totalRows = state.data.length
    const selectedRows = state.data.filter((d: any) => d.selected).length

    return { totalRows, selectedRows }
}
