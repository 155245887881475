/* eslint-disable */
import React from 'react'

type OwnProps = {
    fill?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Stats.defaultProps;
/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7022) FIXME: 'Stats' implicitly has type 'any' because it does ... Remove this comment to see the full error message
const Stats = ({ fill, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={fill} {...rest}>
        <path
            d="M19,7H9C7.9,7,7,7.9,7,9v10c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,
        2-2V9C21,7.9,20.1,7,19,7z M19,9v2H9V9H19z M13,15v-2h2v2H13z M15,
        17v2h-2v-2H15z M11,15H9v-2h2V15z M17,13h2v2h-2V13z M9,17h2v2H9V17z M17,
        19v-2h2v2H17z M6,17H5c-1.1,0-2-0.9-2-2V5 c0-1.1,0.9-2,2-2h10c1.1,0,
        2,0.9,2,2v1h-2V5H5v10h1V17z"
        />
    </svg>
)

Stats.defaultProps = {
    fill: '',
}

export default Stats
