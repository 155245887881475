import React from 'react'

type OwnProps = {
    fill?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Pencil.defaultProps
/* eslint-disable */
// @ts-expect-error ts-migrate(7022) FIXME: 'Pencil' implicitly has type 'any' because it does... Remove this comment to see the full error message
const Pencil = ({ fill, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" fill={fill} {...rest}>
        <g id="Glyphish_6_Icons__x28_selected_x29_">
            <g id="_x38_30-pencil-selected_x40_2x.png_2_">
                <g>
                    <path
                        d="M4.3,36.3L4.3,36.3c-0.1,0.1-0.2,0.3-0.2,0.5l0,0l-4,18l0,0c0,0.1,0,0.1,0,0.2c0,0.6,0.4,1,1,1c0.1,0,0.1,0,0.2,0l0,0
                        l18-4l0,0c0.2,0,0.4-0.1,0.5-0.2l27.8-27.8L32.1,8.5L4.3,36.3z M18,50.2L2.3,53.7L5.8,38H12v5c0,0.6,0.4,1,1,1h5V50.2z
                            M55.7,14.3l-14-14C41.5,0.1,41.3,0,41,0c-0.3,0-0.5,0.1-0.7,0.3l-6.8,6.8l15.4,15.4l6.8-6.8c0.2-0.2,0.3-0.4,0.3-0.7
                        C56,14.7,55.9,14.5,55.7,14.3z"
                    />
                </g>
            </g>
        </g>
        <g id="Glyphish_6_Icons" />
    </svg>
)

Pencil.defaultProps = {
    fill: '',
}

export default Pencil
