import React from 'react'

import ExpandedInvoiceStatsRow from '../ExpandedInvoiceStatsRow'
import { Table } from '../../../../../lib/framework'
import Loading from '../../../../../lib/framework/Loading'
import bySiteInvoiceStatsColumns from '../../../../utils/bySiteInvoiceStatsColumns'

import styles from './bySiteStats.module.scss'

type Props = {
    loading: boolean
    data: string[]
    siteList: ISiteList
}

const BySiteStats = ({ data, loading, siteList }: Props) => {
    return (
        <>
            <Loading loading={loading} />
            <Table
                loading={loading}
                data={data}
                // For each site we find number of pending invoices so we can show in table
                resolveData={(d: any) =>
                    d && d.length
                        ? d
                              .filter((row: any) => row[0].site_id !== 0)
                              .map((row: any) =>
                                  row.reduce(
                                      (a: any, b: any) => ({
                                          ...a,
                                          ...(b.status === 'pending' && {
                                              status: b.status,
                                              invoices: b.invoices,
                                          }),
                                      }),
                                      { siteId: row[0].site_id },
                                  ),
                              )
                        : []
                }
                className={styles.statsTable}
                columns={siteList.length ? bySiteInvoiceStatsColumns(siteList) : []}
                SubComponent={({ index }: any) => {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'site_id' does not exist on type 'string'... Remove this comment to see the full error message
                    const hasSiteZero = data[0][0].site_id === 0
                    // Adding one because we skip over index 0 (see line 22 above)
                    const expandedData = data[hasSiteZero ? index + 1 : index]
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'site_id' does not exist on type 'String'... Remove this comment to see the full error message
                    const [{ site_id: siteId }] = expandedData

                    return (
                        <ExpandedInvoiceStatsRow data={expandedData} index={index} view="bySite" siteId={siteId} />
                    )
                }}
            />
        </>
    )
}

export default BySiteStats
