/* eslint-disable jsx-a11y/label-has-for */

import React from 'react'
import { ValueType } from 'react-select/src/types.js'

import BaseSelect from '../BaseSelect'
import { BaseSelectTypes } from '../BaseSelect/BaseSelect'

const Dropdown: React.FC<BaseSelectTypes & { onChange: (...args: any[]) => void }> = ({
    onChange: handleOnChange,
    ...props
}) => {
    const onChange = (v: ValueType<object>, meta: ObjectType) => {
        if (handleOnChange) handleOnChange(v, meta)
    }

    return <BaseSelect onChange={onChange} {...props} />
}

export default Dropdown
