// @ts-expect-error ts-migrate(2724) FIXME: Module '"../../../node_modules/axios"' has no expo... Remove this comment to see the full error message
import axios, { isCancel } from 'axios'
import { usersConfig } from './usersConfig'
import { camelCaseKeys } from '..'

// For use with users
const http = axios.create(usersConfig)

// Return just the data object from the response
http.interceptors.response.use(({ data }) => {
    const { data: innerData, ...rest } = data
    if (rest.status === 'success') {
        return innerData && typeof innerData === 'object'
            ? { ...rest, ...innerData } // eslint-ignore-line
            : data
    }
    return data
})

// Convert keys to camelCase from snake_case
http.interceptors.response.use((data) => camelCaseKeys(data))

// Middleware Request
http.interceptors.request.use((req) => {
    // Auth Token
    const token = localStorage.getItem('fs_app_token')
    if (token) {
        req.headers = {
            ...req.headers,
            Authorization: `Bearer ${token}`,
        }
    }
    return req
})

// Middleware Response
http.interceptors.response.use(
    (response) => response,
    (error) => {
        // Catch and return axios cancellations
        if (isCancel(error)) return Promise.reject(error)

        if (!error.response) return Promise.reject(error.message)
        const { status, data } = error.response

        // eslint-disable-next-line
        return Promise.reject({ status, message: data.message })
    },
)

export default http
