/* eslint-disable react/button-has-type */
import React from 'react'
import classnames from 'classnames'
import Icon from '../../../lib/framework/Icon'

type OwnProps = {
    children?: React.ReactNode
    onClick?: (...args: any[]) => any
    icon?: string
    iconColor?: string
    type?: string
    className?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Button.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'Button' implicitly has type 'any' because it does... Remove this comment to see the full error message
const Button = ({ children, type, icon, iconColor, onClick, className, ...rest }: Props) => {
    const wrapChildren = icon ? <span>{children}</span> : children
    const modifiedChildren = children ? wrapChildren : null

    return (
        <button {...rest} onClick={onClick} type={type} className={classnames(className, { 'icon-text': icon })}>
            {icon && <Icon icon={icon} color={iconColor} />}
            {modifiedChildren}
        </button>
    )
}

Button.defaultProps = {
    children: null,
    onClick: null,
    icon: null,
    iconColor: null,
    type: 'button',
    className: 'appButtonReset',
}

export default Button
