import { CAN_EDIT_USERS, CAN_VIEW_OWN_CONTRACTOR_PAYMENTS, CAN_VIEW_CONTRACTOR_PAYMENTS } from '../../permissions'

export const usersDetailsNavItems = [
    {
        label: 'Profile',
        path: 'profile',
    },
    {
        label: 'Sites',
        path: 'sites',
        showNotOn: '/myprofile',
    },
    {
        label: 'My Sites',
        path: 'sites',
        showOn: '/myprofile',
    },
    {
        label: 'Payments',
        path: '/payments/user',
        showNotOn: '/myprofile',
        permission: CAN_VIEW_CONTRACTOR_PAYMENTS,
    },
    {
        label: 'My Payments',
        path: '/payments/user',
        showOn: '/myprofile',
        permission: CAN_VIEW_OWN_CONTRACTOR_PAYMENTS,
    },
    {
        label: 'Security',
        path: 'security',
        showOn: '/myprofile',
    },
    {
        label: 'Additional Options',
        path: 'additional',
        showNotOn: '/myprofile',
        permission: CAN_EDIT_USERS,
    },
]
