import { toast } from 'react-toastify'
import { endOfMonth, format, parseISO } from 'date-fns'

import { setFlatRateDataLoading, clearData, setFlatRateData } from '../../state/flatRateState'
import { getSiteAndSystemAdmins } from '../users'
import paymentsHttp from '../../ManagePayments/utils/paymentsHttp'
import { handleError } from '../../lib/utils'

export const getAll = ({ site, date }: any) => {
    setFlatRateDataLoading(true)
    clearData()
    const params = {
        site: null,
        start: date,
        end: format(endOfMonth(parseISO(date)), 'yyyy-MM-dd'),
    }

    if (site && site.toString() !== '-1') params.site = site

    return paymentsHttp
        .get('/flatRate/posts', { params })
        .then(async ({ data }) => {
            const pages = [1, 2]
            const responses = await Promise.all(pages.map(async (page: number) => getSiteAndSystemAdmins(page)))
            const users = responses.flatMap(({ users }: any) => users)

            return { usersToFilterOut: users, posts: data }
        })
        .then(({ usersToFilterOut, posts }) => {
            const fulltimeEmployees = ['sreed1', 'bfrederick', 'dnosa']
            let wpUsernames = usersToFilterOut.map((user: any) => user.wordpressUsername)
            wpUsernames = [...wpUsernames, ...fulltimeEmployees]

            return posts.filter((user: any) => !wpUsernames.includes(user.username))
        })
        .then((posts) => setFlatRateData(posts))
        .catch((e) => handleError(e, setFlatRateDataLoading))
}

export const updateFlatRatePayment = ({ payAmount, siteId, postId, author }: any = {}) => {
    paymentsHttp
        .post('/flatRate/post', {
            amount: payAmount,
            site: siteId,
            postId: postId,
        })
        .then(() => {
            toast.success(
                `The $${payAmount} invoice has been generated for ${author}, and can be found under Manage Invoices.`,
            )
        })
        .catch(handleError)
}
