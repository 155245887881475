import React from 'react'

import { Button } from '../../framework'
import { customToast } from '.'

import styles from './customToast.module.scss'

interface CustomUserDeleteToastProps {
    closeToast: (...args: any[]) => any
    deleteUserFunc: (...args: any[]) => void
    name: string
    isBlock?: boolean
}

/**
 *
 * @param {object} props All our props.
 * @param {func} props.closeToast Func supplied by react-toastify.
 * @param {func} props.deleteUserFunc Custom func to remove the user.
 * @param {string} props.name The name of the user selected.
 * @param {bool} props.isBlock Whether we're blocking or deactivating
 * @returns {node}
 */
const CustomUserDeleteToast = ({
    closeToast,
    deleteUserFunc,
    name,
    isBlock = false,
}: CustomUserDeleteToastProps) => (
    <div className={styles.modal}>
        <h4>{`Are you sure you want to ${isBlock ? 'block' : 'deactivate'} ${name}?`}</h4>
        <div className={styles.buttonContainer}>
            <Button type="danger" onClick={deleteUserFunc} className={styles.buttonYes}>
                Yes, {isBlock ? 'block' : 'deactivate'}
            </Button>
            <Button type="primary" onClick={closeToast} className={styles.buttonNo}>
                No, cancel
            </Button>
        </div>
    </div>
)

const customUserDeleteToast = (props: any) =>
    customToast(
        { Component: CustomUserDeleteToast, ...props },
        {
            autoClose: false,
            className: styles.deleteUser,
        },
    )

export default customUserDeleteToast
