import React from 'react'

type OwnProps = {
    fill?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ExternalLink.defaultProps
/* eslint-disable */
// @ts-expect-error ts-migrate(7022) FIXME: 'ExternalLink' implicitly has type 'any' because i... Remove this comment to see the full error message
const ExternalLink = ({ fill, ...rest }: Props) => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        width="20"
        height="20"
        fill={fill}
        {...rest}
    >
        <g>
            <polygon points="9.5,0 12.3,2.8 7.6,7.6 10.4,10.4 15.2,5.7 18,8.5 18,0 	" />
            <path d="M16,15v1h-1H7H2V2h5V0H2C0.9,0,0,0.9,0,2v14c0,1.1,0.9,2,2,2h5h8h1c1.1,0,2-0.9,2-2v-1v-4h-2V15z" />
        </g>
    </svg>
)

ExternalLink.defaultProps = {
    fill: '',
}

export default ExternalLink
