/* eslint-disable jsx-a11y/label-has-for */

import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import { SelectComponentsProps } from 'react-select/src/Select'

import { applyStyle } from '../../utils'
import styles from './baseSelect.module.scss'

export type BaseSelectTypes = {
    autoFocus?: boolean
    className?: string
    classNamePrefix?: string
    containerClassName?: string
    id?: string
    inForm?: boolean
    isDisabled?: boolean
    isLoading?: boolean
    isMulti?: boolean
    label?: string
    labelClassName?: string
    name: string
    noLabelFocusAnimation?: boolean
    onChange?: (...args: any[]) => void
    options: any
    required?: boolean
    value: ObjectType | ObjectType[] | null
    ariaLabel?: string
} & SelectComponentsProps

const BaseSelect: React.FC<BaseSelectTypes> = ({
    autoFocus,
    className = '',
    classNamePrefix = 'fs-select',
    containerClassName = '',
    id = '',
    inForm = false,
    isDisabled = false,
    isLoading = false,
    isMulti = false,
    label = '',
    labelClassName = 'fs-select-label',
    name,
    noLabelFocusAnimation = false,
    options,
    required = false,
    value,
    ...rest
}) => {
    const checkValue = Array.isArray(value) ? !!value.length : (value && !!value.value) || null

    // When hasValue is true the label remains above element but returns to gray
    const [focused, setFocused] = useState(false)
    const [hasValue, setHasValue] = useState(checkValue)

    const ref = useRef<any>()

    useEffect(() => {
        if (!isLoading && autoFocus) {
            ref?.current?.focus()
        }
    }, [isLoading, autoFocus])

    useEffect(() => {
        setHasValue(!!checkValue)
    }, [checkValue])

    const onFocus = () => setFocused(true)

    const onBlur = () => setFocused(false)

    const customStyles = {
        container: (provided: any) => ({
            ...provided,
        }),
        control: (provided: any) => ({
            ...provided,
        }),
    }

    return (
        <div
            className={`fs-select-container${applyStyle(containerClassName)}${
                !noLabelFocusAnimation ? applyStyle(focused, 'focused') : ''
            }`}
        >
            {label && (
                <label
                    htmlFor={id || name}
                    className={`fs-select-label ${styles.label}${applyStyle(labelClassName)}${
                        !noLabelFocusAnimation ? applyStyle(hasValue, styles.hasValue) : ''
                    }${applyStyle(focused, styles.labelFocused)}${applyStyle(isDisabled, styles.labelDisabled)}
`}
                >
                    {`${label}${required ? ' *' : ''}`}
                </label>
            )}
            <Select
                inputId={id || name}
                name={name}
                className={`${styles.select} ${applyStyle(className)}`}
                options={options}
                isMulti={isMulti}
                isLoading={isLoading}
                isDisabled={isDisabled}
                classNamePrefix={classNamePrefix}
                onFocus={onFocus}
                onBlur={onBlur}
                backspaceRemovesValue={false}
                escapeClearsValue={false}
                value={value}
                ref={ref}
                autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
                styles={customStyles}
                aria-label={name}
                {...rest}
            />
        </div>
    )
}

export default BaseSelect
