import React from 'react'
import { Button } from '../../framework'
import { customToast } from '.'

import styles from './customToast.module.scss'

type ComponentProps = {
    closeToast?: (...args: any[]) => any
    onConfirm: (...args: any[]) => any
}

/**
 *
 * @param {object} props All our props.
 * @param {func} props.closeToast Func supplied by react-toastify.
 * @param {func} props.onConfirm Custom func to export invoices.
 * @returns {node}
 */
const Component = ({ closeToast, onConfirm }: ComponentProps) => (
    <div className={styles.modal}>
        <h4>Are you sure you want to submit invoices for payment?</h4>
        <div className={styles.buttonContainer}>
            <Button type="danger" onClick={onConfirm} className={styles.buttonYes}>
                Yes, confirm
            </Button>
            <Button type="primary" onClick={closeToast} className={styles.buttonNo}>
                No, cancel
            </Button>
        </div>
    </div>
)

Component.defaultProps = {
    closeToast: null,
}

const customSubmitPaymentToast = (props: any) =>
    customToast(
        { Component, ...props },
        {
            autoClose: false,
            className: styles.deleteSite,
        },
    )

export default customSubmitPaymentToast
