import { toast } from 'react-toastify'

/**
 *
 *
 * @param {obj} e error
 * @param {func} [loadingFunc]
 * @param {bool} [shouldThrow=false]
 * @param {bool} [retry=false]
 * @throws {Error}
 */
let retries = 0
const handleError = async (
    e: IError | string,
    loadingFunc?: ILoadingFunc | null,
    shouldThrow = false,
    retry = false,
) => {
    if (retry && loadingFunc) {
        retries++

        if (retries === 1) {
            setTimeout(() => loadingFunc(false), 200)
        }
        if (retries === 2) {
            setTimeout(() => loadingFunc(false), 1000)
        }
        if (retries === 3) {
            setTimeout(() => loadingFunc(false), 2500)
        }
        if (retries > 3) {
            retries = 0
        }
    }

    if (!e || e === 'Network Error') {
        toast.error('There seems to be a problem with our server...', {
            toastId: 'Network Error',
        })
        return
    }

    if (!retry && loadingFunc) loadingFunc(false)

    if (!e || typeof e === 'string') return console.error('No error object') // eslint-disable-line

    /**
     * This code means we cancelled an axios request.
     */
    if (e.status === 499) return console.log(e.message) // eslint-disable-line

    if (e.status === 401) {
        window.location.href = window.location.origin + '/logout'
        return
    }

    console.error(e) // eslint-disable-line

    if (e.message) {
        toast.error(e.message, {
            toastId: e.message,
        })
        if (shouldThrow) throw e
        return
    }

    if (e.status) {
        toast.error(`Sorry, that didn't work - Status ${e.status}`, {
            toastId: e.status,
        })
        if (shouldThrow) throw e
        return
    }

    toast.error(`Sorry, that didn't work`)

    if (shouldThrow) throw e
}

export default handleError
