export const paymentsNavItems = [
    {
        label: 'Site Stats',
        path: 'site',
    },
    {
        label: 'My Stats',
        path: 'user',
    },
]
