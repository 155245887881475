import { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isValid } from 'date-fns'
import isEqual from 'lodash/isEqual'
import qs from 'qs'
import { setQueryString } from './helpers'

const parseQueryString = () => {
    const queryString = window.location.search?.slice(1)

    return qs.parse(queryString) as any
}

const setQueryStringValue = (key: string, value: string, nav: Function) => {
    const values = parseQueryString()
    const newQsValue = qs.stringify({ ...values, [key]: value })

    setQueryString(`${newQsValue}`, nav)
}

const getQueryStringValue = (key: string) => {
    const values = parseQueryString()
    const value = values[key]

    if (value === 'true') {
        return true
    }

    if (value === 'false') {
        return false
    }

    if (!Number(value) && isValid(new Date(value))) {
        return value
    }

    if (Number(value)) {
        return Number(value)
    }

    return value
}

export const useQueryString = (key: string, initialValue?: any): [any, (newValue: string) => void, any] => {
    const nav = useNavigate()
    const currentQueryValue = getQueryStringValue(key)

    const [value, setValue] = useState(currentQueryValue || initialValue)

    const onSetValue = useCallback(
        (newValue: string) => {
            setValue(newValue)
            setQueryStringValue(key, newValue, nav)
        },
        [key, nav],
    )

    useEffect(() => {
        if (!currentQueryValue) {
            if (Array.isArray(value) && !value.length) {
                return
            }

            if (typeof value === 'object' && isEqual(initialValue, value)) {
                return
            }

            return onSetValue(initialValue)
        }

        if (!Number(value) && isValid(new Date(currentQueryValue))) {
            if (new Date(currentQueryValue).toString() !== new Date(value).toString()) {
                setValue(currentQueryValue)
            }
            return
        }

        if (typeof value === 'object') {
            if (!isEqual(currentQueryValue, value)) {
                setValue(currentQueryValue)
            }
            return
        }

        if (currentQueryValue !== value) {
            setValue(currentQueryValue)
        }
        // eslint-disable-next-line
    }, [currentQueryValue, value])

    return [value, onSetValue, currentQueryValue]
}
