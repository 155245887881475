import React from 'react'
import ReactImage from 'react-image'
import { useInView } from 'react-intersection-observer'
import isEqual from 'lodash/isEqual'

import styles from './image.module.scss'

type OwnImageProps = {
    src: string
    placeholder?: React.ReactElement | string
    errorSrc?: string
    alt: string
    className: string
    height: number
    width: number
    lazy?: boolean
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'ImageProps' circularly references itse... Remove this comment to see the full error message
type ImageProps = OwnImageProps & typeof Image.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'Image' implicitly has type 'any' because it does ... Remove this comment to see the full error message
const Image = ({ src, placeholder, errorSrc, alt, height, width, className, lazy, ...rest }: ImageProps) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '1500px 0px',
    })

    const DefaultPlaceholder = () => (
        <img
            // https://css-tricks.com/preventing-content-reflow-from-lazy-loaded-images/
            src={`data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`} // eslint-disable-line
            alt="Default placeholder"
            height={height}
            width={width}
            className={className}
            {...rest}
        />
    )

    const Placeholder = () => {
        if (!placeholder) {
            return <DefaultPlaceholder />
        }

        return typeof placeholder === 'string' ? (
            <img
                src={placeholder}
                alt={alt}
                height={height}
                width={width}
                className={`${styles.loading} ${className && className}`}
                {...rest}
            />
        ) : (
            placeholder
        )
    }

    return (
        <ReactImage
            src={src}
            loader={<Placeholder />}
            unloader={
                <img src={errorSrc} height={height} width={width} alt="Error" className={className} {...rest} />
            }
            container={(children) =>
                lazy ? (
                    <div ref={ref} className={styles.container}>
                        {inView ? children : <DefaultPlaceholder />}
                    </div>
                ) : (
                    <div className={styles.container}>{children}</div>
                )
            }
            height={height}
            width={width}
            alt={alt}
            className={className}
            {...rest}
        />
    )
}

Image.defaultProps = {
    placeholder: null,
    lazy: false,
    className: '',
    errorSrc: 'https://app.fansided.com/media/default-avatar.png',
}

const areEqual = (prevProps: any, nextProps: any) => {
    let bool = true

    Object.keys(prevProps).forEach((p) => {
        if (p === 'onClick') return

        if (!isEqual(nextProps[p], prevProps[p])) {
            bool = false
        }
    })

    return bool
}

export default React.memo(Image, areEqual)
