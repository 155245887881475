import React from 'react'
/* eslint-disable */
const SvgProcessingCircle = (props: any) => <svg viewBox="0 0 24 24" {...props}>
    <path d="M12 0A12 12 0 1 1 0 12 12 12 0 0 1 12 0zm0 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2z" />
    <path
        d="M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z"
        transform="scale(.88) translate(1.73 1.22)"
    />
    <path d="M0 0h24v24H0z" fill="none" />
</svg>

export default SvgProcessingCircle
