import React from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import api from '../../../api'
import { Modal, InputFormik, Button } from '../../../lib/framework'
import { setStatus, setComment } from '../../../state/invoiceState'

const RequireCommentModal: React.FC<{
    handleModal: () => void
    data: {
        invoiceId: number
        value: string
    }
}> = ({ handleModal, data: { invoiceId, value: statusValue } }) => {
    const handleSubmit = async ({ comment }: any) => {
        try {
            await api.payments.invoices.createComment(invoiceId, comment)

            setComment(invoiceId, comment)
            setStatus(invoiceId, statusValue)
            handleModal()
            toast.success(`Invoice status set to ${statusValue}`)
        } catch (e) {
            console.error(e) // eslint-disable-line
        }
    }

    return (
        <Modal handleModal={handleModal} ariaLabel="Add Comment">
            <Formik
                initialValues={{
                    comment: '',
                }}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <h3>Add a comment</h3>
                        <p>Please add a comment to explain why you chose this status.</p>

                        <InputFormik label="Add Comment" name="comment" type="textarea" required />
                        <Button type="submit" disabled={isSubmitting}>
                            Add{!isSubmitting ? '' : 'ing'} Comment
                        </Button>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default RequireCommentModal
