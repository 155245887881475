import { isAuthorized } from './authorization'

import { getSiteListWithGlobalRolesOption } from './getSiteListWithGlobalRolesOption'
import { CAN_MANAGE_USERS } from './permissions'

/**
 *
 * This func takes an array of one or more site Ids and the global siteList
 * @param {array} sites
 * @param {array} siteList
 * @returns {array} The site objects in array that match the passed ids
 */
const getSitesDetailsFromIds = (sites: string[] = [], siteList: ISiteList = []) => {
    const matchingSites: ISiteList = siteList.filter((site) => sites.includes(site.id))

    const sitesWithGlobalOption =
        sites.includes('0') &&
        isAuthorized({ permission: CAN_MANAGE_USERS, siteId: '0' }) &&
        getSiteListWithGlobalRolesOption(matchingSites)

    return sitesWithGlobalOption || matchingSites
}

export default getSitesDetailsFromIds
