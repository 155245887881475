import React from 'react'
import Icon from '../../lib/framework/Icon'
import { isAuthorized } from '../../lib/utils'
import { CAN_MANAGE_INVOICES } from '../../lib/utils/permissions'
import EditableCellInputField from '../ui/EditableCellInputField'
import { numberWithCommas } from './format/number'
import { setFlatRatePaymentValue } from '../../state/flatRateState'

const buildTotalPayAmount = (payAmount: any) => {
    const totalPayAmount = payAmount.reduce((acc: any, cur: any) => {
        let curPayment = 0
        if (cur?._original.payAmount) {
            curPayment = Number(cur._original.payAmount)
        }
        return acc + curPayment
    }, 0)
    const roundAmount = Math.round(totalPayAmount * 100) / 100
    /* @ts-ignore */
    return parseFloat(roundAmount || 0, 10).toFixed(2)
}

const tableColumns = () => [
    {
        Header: 'Post',
        id: 'post',
        accessor: 'title',
        headerClassName: 'sortable',
        Cell: ({ original: { title, url } }: any) => {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">
                    {title}
                    {` `}
                    <Icon icon="ExternalLink" size={0.8} />
                </a>
            )
        },
        width: 350,
        Footer: () => <span>Total</span>,
    },
    {
        Header: 'Publish Date (ET)',
        id: 'pubDate',
        accessor: 'published',
        headerClassName: 'sortable',
        Cell: ({ original: { published } }: any) => {
            const date = new Date(published)
            const formattedDate = new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'America/New_York',
            }).format(date)
            return formattedDate
        },
        width: 250,
        Footer: ({ data }: any) => (
            <span>
                {data.length} post{data.length > 1 ? 's' : ''}
            </span>
        ),
    },
    {
        Header: 'Site',
        id: 'site',
        accessor: 'siteName',
        Cell: ({ original: { siteName } }: any) => {
            return siteName
        },
        headerClassName: 'sortable',
        width: 250,
    },
    {
        Header: 'User',
        id: 'user',
        accessor: 'name',
        Cell: ({ original: { name } }: any) => {
            return name
        },
        headerClassName: 'sortable',
        width: 200,
    },
    {
        Header: 'Amount',
        id: 'amount',
        accessor: 'payAmount',
        headerClassName: 'sortable',
        Cell: ({ original: { payAmount, postId, property, name } }: any) => {
            /* @ts-ignore */
            const n = payAmount ? `$${parseFloat(payAmount || 0, 10).toFixed(2)}` : '$'
            return isAuthorized({
                permission: CAN_MANAGE_INVOICES,
                siteId: property,
            }) ? (
                <EditableCellInputField
                    type="number"
                    displayValue={numberWithCommas(n)}
                    value={n}
                    onSubmit={(value: any) => setFlatRatePaymentValue(value, property, postId, name)}
                    disabled={n !== '$'}
                />
            ) : null
        },
        width: 200,
        Footer: ({ data }: any) => <span>${buildTotalPayAmount(data)}</span>,
    },
]

export default tableColumns
