const imageService = (src: any, width: any, height: any) => {
    if (process.env.NODE_ENV === 'development') return src

    const h = height ? `,h_${height}` : ''
    const w = width ? `,w_${width}` : ''

    return `https://images2.minutemediacdn.com/image/fetch/c_fill,g_auto${h}${w},f_auto/${encodeURIComponent(src)}`
}

export default imageService
