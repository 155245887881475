import React from 'react'
import { Table } from '../../../../lib/framework'

import { siteStatsColumns } from '../siteStatsTableColumns'
import Loading from '../../../../lib/framework/Loading'

import styles from '../siteStats.module.scss'

const SiteStatsTable: React.FC<any> = ({ stats, statsLoading }) => {
    return (
        <section>
            <div className={styles.tableContainer}>
                <Table
                    resizable={false}
                    loading={statsLoading}
                    loadingText={''}
                    noDataText={<span>No data found</span>}
                    minRows={3}
                    data={stats}
                    defaultSorted={[
                        {
                            id: 'pubCount',
                            desc: true,
                        },
                    ]}
                    LoadingComponent={() => (
                        <Loading
                            loading={statsLoading}
                            style={{
                                transform: 'translateY(-2px)',
                            }}
                        />
                    )}
                    columns={siteStatsColumns()}
                />
            </div>
        </section>
    )
}

export default SiteStatsTable
