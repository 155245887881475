/* eslint-disable import/no-cycle */

import { combineReducers } from 'redux'

// Custom Reducers
import paymentsState from './paymentsState'
import userState from './userState'
import globalState from './globalState'

// Combine Reducers
const appReducer = combineReducers({
    paymentsState,
    userState,
    globalState,
})

const rootReducer = (state: any, action: any) => {
    if (action.type === 'LOGOUT') {
        state = undefined // eslint-disable-line
    }

    return appReducer(state, action)
}

export default rootReducer
