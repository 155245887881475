import { isAuthorized } from './authorization'
import { CAN_MANAGE_USERS } from './permissions'

const formatSitesWithVerticals = (
    siteList: ISiteList = [],
    sites?: ISiteList,
    withAllSitesOption?: boolean,
    sitesMetaIds?: string[],
) => [
    ...(withAllSitesOption ? [{ label: 'All Sites', value: '' }] : []),
    ...(isAuthorized({ permission: CAN_MANAGE_USERS }) && sites && !sites?.some((site) => site.id === '0')
        ? [{ label: 'Global Roles', value: '0' }]
        : []),
    ...Object.values(
        siteList.reduce((result, { id, name, vertical }) => {
            // Filter out existing sites for user
            if (sites && sites.some((site) => site.id === id)) {
                return result
            }

            // Filter out sites not in sitesMeta
            if (sitesMetaIds && !sitesMetaIds.some((siteId: any) => siteId === id)) {
                return result
            }

            const inVertical = vertical || 'Other'
            // Create new group

            if (!result[inVertical]) {
                // eslint-disable-next-line
                result[inVertical] = {
                    label: inVertical,
                    value: inVertical,
                    options: [],
                }
            }

            // Append to group
            result[inVertical].options.push({
                label: name,
                value: id,
            })
            return result
        }, {}),
    ),
]

export default formatSitesWithVerticals
