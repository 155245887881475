import React from 'react'
import classNames from 'classnames'

import styles from './alert.module.scss'

type Props = {
    className?: string
    children?: React.ReactNode[] | React.ReactNode
    success?: boolean
    info?: boolean
    warning?: boolean
    error?: boolean
    title?: boolean
}

const Alert: React.FC<Props> = (props) => {
    const { className, children, success, info, warning, error, title, ...rest } = props

    const alertClasses = classNames(styles.alert, className, {
        [styles.success]: success,
        [styles.info]: info,
        [styles.warning]: warning,
        [styles.error]: error,
    })

    return (
        <div className={alertClasses} {...rest}>
            {title ? <span className={styles.title}>{title} </span> : ''}
            {children}
        </div>
    )
}

Alert.defaultProps = {
    className: '',
    children: [],
    success: false,
    info: false,
    warning: false,
    error: false,
    title: false,
}

export default Alert
