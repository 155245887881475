import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import createAuth0Client from '@auth0/auth0-spa-js'

export const Auth0Context = React.createContext({})
export const useAuth0 = () => useContext<any>(Auth0Context)

export const Auth0Provider = ({ children, getInitialUrl, ...initOptions }: any) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>()
    const [user, setUser] = useState()
    const [auth0Client, setAuth0]: any = useState()
    const [loading, setLoading] = useState(true)

    const nav = useNavigate()

    // A function that routes the user to the right place
    // after login
    const onRedirectCallback = () => {
        const initialUrl = getInitialUrl()

        /**
         * Auth0's appState is broken. So instead it captures the URL the app
         * was originally loaded on and stores it in local storage. Then, when
         * the user signs in, redirects to the local storage URL on success.
         */
        nav(initialUrl || window.location.pathname)
    }

    useEffect(() => {
        const initAuth0 = async () => {
            const auth0FromHook = await createAuth0Client(initOptions)
            setAuth0(auth0FromHook)

            if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
                const { appState } = await auth0FromHook.handleRedirectCallback()
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
                onRedirectCallback(appState)
            }

            const isAuthenticated = await auth0FromHook.isAuthenticated()

            setIsAuthenticated(isAuthenticated)

            if (isAuthenticated) {
                const user = await auth0FromHook.getUser()
                setUser(user)
            }

            setLoading(false)
        }
        initAuth0()
        // eslint-disable-next-line
    }, [])

    const handleRedirectCallback = async () => {
        setLoading(true)

        await auth0Client?.handleRedirectCallback()

        const user = await auth0Client?.getUser()
        setLoading(false)

        setIsAuthenticated(true)
        setUser(user)
    }
    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                loading,
                handleRedirectCallback,
                getIdTokenClaims: (...p: any) => auth0Client?.getIdTokenClaims(...p),
                loginWithRedirect: (...p: any) => auth0Client?.loginWithRedirect(...p),
                getTokenSilently: (...p: any) => auth0Client?.getTokenSilently(...p),
                getTokenWithPopup: (...p: any) => auth0Client?.getTokenWithPopup(...p),
                logout: (...p: any) => auth0Client?.logout(...p),
            }}
        >
            {children}
        </Auth0Context.Provider>
    )
}
