export const managePaymentsNavItems = [
    {
        label: 'Manage Invoices',
        path: '',
    },
    {
        label: 'Invoice Stats',
        path: 'stats',
    },
    {
        label: 'Flat Rate',
        path: 'flatrate',
    },
]
