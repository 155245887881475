import React from 'react'
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/react-datepicker` if it ex... Remove this comment to see the full error message
import DatePicker from 'react-datepicker'
import { parseISO } from 'date-fns'

import Icon from '../Icon'

import 'react-datepicker/dist/react-datepicker.css'
import styles from './datePickerButton.module.scss'

type Props = {
    onChange: (...args: any[]) => any
    value: string
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'minDate' does not exist on type 'Props'.
const DatePickerButton = ({ onChange, value: valueProp, minDate: minDateProp }: Props) => {
    const d = new Date()

    const value = parseISO(valueProp)

    const minDate = minDateProp || new Date(`1/1/${d.getFullYear() - 1}`)
    const maxDate = new Date()
    const CustomInput = ({ value, onClick }: any) => (
        <>
            <input type="text" className={styles.wrapper} onClick={onClick} value={value} />
            <button type="button" className={styles.dropDownButton} onClick={onClick}>
                <Icon icon="caretDown" size={1.2} color="#ccc" />
            </button>
        </>
    )

    return (
        <DatePicker
            onChange={onChange}
            selected={value}
            minDate={minDate}
            maxDate={maxDate}
            dateFormat="MMM y"
            showMonthYearPicker
            customInput={<CustomInput />}
        />
    )
}

export default DatePickerButton
