/* eslint-disable react/prop-types */

import React from 'react'

class ErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props)
        this.state = { hasError: false }
    }

    componentDidUpdate() {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasError' does not exist on type 'Readon... Remove this comment to see the full error message
        const { hasError } = this.state
        if (hasError) {
            console.error('Error boundary triggered') // eslint-disable-line

            window.location.href = '/'
        }
    }

    componentDidCatch() {}

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    render() {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'hasError' does not exist on type 'Readon... Remove this comment to see the full error message
        const { hasError } = this.state
        const { children } = this.props
        if (hasError) {
            return null
        }

        return children
    }
}

export default ErrorBoundary
