/* eslint-disable react/prop-types */
import React from 'react'
import { getSitesDetailsFromIds } from '../../lib/utils'

const bySiteInvoiceStatsColumns = (siteList: ISiteList) => [
    {
        Header: 'Site Name',
        headerClassName: 'sortable',
        id: 'siteName',
        accessor: ({ siteId }: any) => {
            const [{ name = '' } = {}] = getSitesDetailsFromIds([siteId.toString()], siteList)

            return name
        },
        width: 150,
        Cell: ({ original: { siteId } }: any) => {
            const [{ name = `Inactive - Site ID ${siteId}` } = {}] = getSitesDetailsFromIds(
                [siteId.toString()],
                siteList,
            )

            return name
        },
    },
    {
        Header: '# Pending',
        headerClassName: 'sortable',
        id: 'status',
        width: 150,
        accessor: (row: any) => row.invoices,
        filterable: false,
        Cell: ({ original: { status, invoices } }: any) => {
            return status ? (
                <div
                    style={{
                        color: '#073759',
                    }}
                >
                    {invoices} {status}
                </div>
            ) : (
                <div
                    style={{
                        color: '#999',
                    }}
                >
                    0 pending
                </div>
            )
        },
    },
]

export default bySiteInvoiceStatsColumns
