import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import api from '../../../api'

const useSiteList = (withUnassigned?: boolean): [ISiteList, boolean] => {
    const siteList = useSelector<ObjectType, ISiteList>(({ globalState }) => globalState.siteList.data)
    const sitesLoading = useSelector<ObjectType, boolean>(({ globalState }) => globalState.siteList.isLoading)

    const [list, setList] = useState<ISiteList>(siteList)

    useEffect(() => {
        if (!siteList.length && !sitesLoading) {
            const cb = async () => {
                const s = await api.global.getSiteList()
                if (s) {
                    const sites = !withUnassigned
                        ? s
                        : [...s, { id: '0', name: 'Unassigned', url: 'Unassigned', vertical: 'FS' }]
                    const sorted = sites.sort((a: SiteListSiteObject, b: SiteListSiteObject) =>
                        a.name > b.name ? 1 : -1,
                    )
                    setList(sorted)
                }
            }
            cb()
        }
    }, [siteList.length, sitesLoading, withUnassigned])

    return [list, sitesLoading]
}

export default useSiteList
