import React from 'react'
import styles from './editableCellSelectField.module.scss'

type OwnProps = {
    values: React.ReactNode
    value?: string
    onChange: (...args: any[]) => any
    disabled: boolean
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof EditableCellSelectField.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'EditableCellSelectField' implicitly has type 'any... Remove this comment to see the full error message
const EditableCellSelectField = ({ value, values, onChange, disabled }: Props) => (
    // eslint-disable-next-line
    <select className={styles.select} value={value} onChange={onChange} disabled={disabled}>
        {values}
    </select>
)

EditableCellSelectField.defaultProps = {
    value: '',
}

export default EditableCellSelectField
