import React from 'react'
import { components } from 'react-select'
import Icon from '../../../lib/framework/Icon'
import styles from './dropdownIndicator.module.scss'

const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
        <div className={styles.roleSelectIcon}>
            <Icon icon="Plus" fill="#999" />
        </div>
    </components.DropdownIndicator>
)

export default DropdownIndicator
