import React, { useEffect } from 'react'
import { useTransition, animated } from 'react-spring'
import 'conic-gradient' // Polyfill

import Icon from '../Icon'
import styles from './loading.module.scss'
import colors from '../../utils/colors'

type OwnProps = {
    loading: boolean
    fadeIn?: boolean
    lockScroll?: boolean
    verticalCenter?: boolean
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Loading.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'Loading' implicitly has type 'any' because it doe... Remove this comment to see the full error message
const Loading = ({ lockScroll, loading, verticalCenter, fadeIn, style, ...rest }: Props) => {
    const transitions = useTransition(loading, null, {
        from: { opacity: fadeIn ? 0 : 1 },
        ...(fadeIn && { enter: { opacity: 1 } }),
        leave: { opacity: 0 },
    })

    useEffect(() => {
        if (!lockScroll) return

        document.body.style.overflow = loading ? 'hidden' : 'auto'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [loading, lockScroll])

    return transitions.map(
        ({ item, props, key }) =>
            item && (
                <animated.div
                    style={{
                        ...style,
                        ...props,
                    }}
                    key={key}
                    className={`${styles.loadingContainer} ${verticalCenter && styles.verticalCenter}`}
                    {...rest}
                >
                    <div className={`${styles.spinner} ${verticalCenter && styles.verticalCenterSpinner}`}>
                        <Icon className={styles.fsLogo} size={3.2} icon="fansidedLogo" />
                        <div
                            style={{
                                // @ts-expect-error ts-migrate(2339) FIXME: Property 'ConicGradient' does not exist on type 'W... Remove this comment to see the full error message
                                background: new window.ConicGradient({
                                    // eslint-disable-next-line
                                    stops: `${colors.colorFSBlue} 0deg, ${colors.colorPrimary} 45deg, ${colors.colorFSBlue} 90deg`,
                                }),
                            }}
                            className={styles.backLayer}
                        ></div>
                    </div>
                </animated.div>
            ),
    )
}

Loading.defaultProps = {
    fadeIn: false,
    lockScroll: false,
    verticalCenter: false,
}

export default Loading
