const appConfig = {
    prod: {
        api: {
            protocol: '',
            host: '',
        },
    },
    test: {
        api: {
            protocol: '',
            host: '',
        },
    },
    dev: {
        api: {
            protocol: '',
            host: '',
        },
    },
    local: {
        api: {
            protocol: 'http://',
            host: `localhost:${process.env.REACT_APP_USERS_PORT}`,
        },
    },
}

const env = window.location.host.includes('app.')
    ? 'prod'
    : window.location.host.includes('app-test.')
    ? 'test'
    : window.location.host.includes('app-dev.')
    ? 'dev'
    : 'local'

export default appConfig[env]
