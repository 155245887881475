import React from 'react'
import { BrowserRouter } from 'react-router-dom'

type Props = {
    children: React.ReactElement
}

const Router = ({ children }: Props) => <BrowserRouter>{children}</BrowserRouter>

export default Router
