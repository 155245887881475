import React, { lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { isAuthorized } from '../lib/utils'
import { CAN_MANAGE_CONTRACTORS } from '../lib/utils/permissions'
import NotFound from '../lib/layouts/NotFound'

const Users = lazy(() => import(/* webpackChunkName: "ManageUsers" */ './layouts/ManageUsers'))
const UserDetail = lazy(() => import(/* webpackChunkName: "UserDetail" */ './layouts/UserDetail'))

const ManageContractors = lazy(
    () => import(/* webpackChunkName: "ManageContractors" */ './layouts/ManageContractors'),
)

const UsersRouter = ({ ...rest }) => {
    return (
        <Routes>
            {/* - - -  User management table - - - */}
            <Route path="/" element={<Navigate to="manage" />} {...rest} />
            <Route path="manage" element={<Users />} {...rest} />

            {/* - - -  Detailed user management - - - */}
            <Route path=":id/*" element={<UserDetail />} {...rest} />

            {/* - - -  Profile - - - */}
            <Route path="myprofile/*" element={<UserDetail />} {...rest} />

            {/* - - -  Contractor management - - - */}
            {isAuthorized({ permission: CAN_MANAGE_CONTRACTORS }) && (
                <Route path="contractors" element={<ManageContractors />} {...rest} />
            )}

            {/* 404 Routing */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default UsersRouter
