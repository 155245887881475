import paymentsHttp from '../../ManagePayments/utils/paymentsHttp'
import { handleError } from '../../lib/utils'

/**
 * Get active users for site
 *
 * @param {string} siteId
 * @param {string} startDate
 * @param {string} endDate
 *
 * @returns {object} response
 */
export const getActiveUsers = async ({
    siteId,
    startDate,
    endDate,
}: {
    siteId: string
    startDate: string
    endDate: string
}) => {
    try {
        const { data } = await paymentsHttp.get<any, ObjectType>(`/siteStats/${siteId}/activeUsers`, {
            params: {
                startDate,
                endDate,
            },
        })

        if (!data.data) throw new Error('Something went wrong!')

        return data.data
    } catch (e) {
        return handleError(e, null, false)
    }
}

interface IGetSiteViews {
    (args: { siteId: string; startDate: string; endDate: string }): Promise<Array<{
        views: number
        geoTier: number
    }> | void>
}

export const getSiteViews: IGetSiteViews = async ({ siteId, startDate, endDate }) => {
    try {
        const { data } = await paymentsHttp.get(`/siteStats/${siteId}/siteViews`, {
            params: { startDate, endDate },
        })

        return data.data
    } catch (e) {
        return handleError(e, null, false)
    }
}

interface IGetNonExpertPageViews {
    (args: { siteId: string; startDate: string; endDate: string }): Promise<Array<{
        views: number
        geoTier: number
    }> | void>
}

export const getNonExpertPageViews: IGetNonExpertPageViews = async ({ siteId, startDate, endDate }) => {
    try {
        const { data } = await paymentsHttp.get(`/siteStats/${siteId}/nonExpertPageViews`, {
            params: { startDate, endDate },
        })

        return data.data
    } catch (e) {
        return handleError(e, null, false)
    }
}

interface IGetSitePostCount {
    (args: { siteId: string; startDate: string; endDate: string }): Promise<number | void>
}

export const getSitePostCount: IGetSitePostCount = async ({ siteId, startDate, endDate }) => {
    try {
        const { data } = await paymentsHttp.get<any, ObjectType>(`/siteStats/${siteId}/pubCount`, {
            params: { startDate, endDate },
        })

        return data.data?.pubCount
    } catch (e) {
        return handleError(e, null, false)
    }
}
