export const hasPermission = (currentUserId: string) =>
    [
        'auth0|5f81ef1f04abd400714be982', // Test - Andrea
        'auth0|5f81efedc9e982007654d065', // Test - Marissa
        'auth0|5f81ef65b1c17a0078e70f08', // Test - Betty
        'auth0|60b861011d54a0006859da31', // Test - Noa
        'auth0|5f81ef84f6d700006ade8e14', // Test - Ed
        'auth0|5fecb55089f2700069f28740', // Prod - Andrea
        'auth0|6013b432acec2b006a9b6b09', // Prod - Marissa
        'auth0|5ff5ee7610e72100763ca71d', // Prod - Betty
        'auth0|60b85ef635f0ce006bde283a', // Prod - Noa
        'auth0|5e4521dc8f7d390e597ae32a', // Prod - Ed
        'auth0|5e4520158a409e0e62023759', // Prod - Carrie
        'auth0|5eba041991131e200227eaff', // Prod - Kyle
    ].includes(currentUserId)
