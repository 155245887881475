import React from 'react'

import Button from '../Button'

import { setStatus } from '../../../state/invoiceState'
import { cycleStatus } from '../../utils/cycleStatus'

type Props = {
    id: number
    siteId: number
    status: string
}

const StatusToggle = ({ id, siteId, status }: Props) => {
    let icon = 'pending-circle'
    let iconColor = 'green'

    let disabled = true

    if (status === 'ineligible') {
        icon = 'ineligible-circle'
        iconColor = 'red'
    }

    if (status === 'flagged') {
        icon = 'flag'
        iconColor = 'red'
    }

    if (status === 'error') {
        icon = 'error-circle'
        iconColor = 'red'
    }

    if (status === 'pending') {
        iconColor = 'orange'
        disabled = false
    }

    if (status === 'audited') {
        icon = 'audited-circle'
        iconColor = 'green'
        disabled = false
    }

    if (status === 'approved') {
        icon = 'approved-circle'
        iconColor = 'green'
    }

    if (status === 'export') {
        icon = 'export-circle'
        iconColor = 'blue'
    }

    if (status === 'processing') {
        icon = 'processing-circle'
        iconColor = 'blue'
    }

    if (status === 'submitted') {
        icon = 'submitted-circle'
        iconColor = 'blue'
    }

    if (status === 'no_account') {
        icon = 'error-circle'
        iconColor = 'orange'
        disabled = true
    }

    if (status === 'paid') {
        icon = 'approved-circle'
        iconColor = 'blue'
        disabled = true
    }

    return (
        <Button
            style={{ cursor: 'pointer' }}
            icon={icon}
            iconColor={iconColor}
            disabled={disabled}
            onClick={() => setStatus(id, cycleStatus(status, siteId))}
        />
    )
}

export default StatusToggle
