import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { format } from 'date-fns'

import { Table } from '../../../../lib/framework'
import { useSiteList } from '../../../../lib/utils/hooks'
import { userStatsColumns } from '../userStatsTableColumns'
import DatePickerButton from '../../../../lib/framework/DatePickerButton/DatePickerButton'

import api from '../../../../api'
import { pageviewsByTier } from '../../../utils/pageviewsByTier'
import Loading from '../../../../lib/framework/Loading'

import styles from '../userStats.module.scss'
import GAPageView from '../../../../lib/utils/GAPageView'

type Props = {
    currentUserId: string
    userId: string
    sitesMeta: SitesMeta
    rolesMeta: string[]
    setDateFilter: ISetterFunc
    dateFilter: string
    startDate: string
    endDate: string
    voltaxSitesList: VoltaxSiteObject[]
}

const StatsTable: React.FC<Props> = ({
    currentUserId,
    userId,
    sitesMeta,
    rolesMeta,
    setDateFilter,
    dateFilter,
    startDate,
    endDate,
    voltaxSitesList,
}) => {
    const [statsLoading, setStatsLoading] = useState(false)
    const [stats, setStats] = useState<any>([])
    const [statsTablePageIndex, setStatsTablePageIndex] = useState(0)
    const siteIds = sitesMeta.map((site: SiteMeta) => site.id)
    const hasVoltaxRole = !!voltaxSitesList.filter((site: SiteMeta) => siteIds.includes(site.id)).length
    const pageIdentifier = window.location.pathname + window.location.search

    const { userId: pathUserId } = useParams()

    /**
     * Fetch the global siteList if it doesn't exist
     */
    const [siteList] = useSiteList()

    /**
     * Handles fetching analytics and payment history when date filter changes
     */
    useEffect(() => {
        const getUserStats = async () => {
            setStatsLoading(true)

            const res = (await api.payments.myStats.getMyStats({ userId, startDate, endDate })) || []

            const myStats = res.map((data) => {
                const { views, ...rest } = data
                const viewsByTier = pageviewsByTier(views)

                const siteData = {
                    ...rest,
                    ...viewsByTier,
                }

                return siteData
            })

            setStats(myStats)
            setStatsLoading(false)
        }
        getUserStats()
    }, [endDate, startDate, userId])

    return (
        <section className={styles.container}>
            <GAPageView
                userId={currentUserId}
                rolesMeta={rolesMeta}
                hasVoltaxRole={hasVoltaxRole}
                pageIdentifier={pageIdentifier}
            />
            <div className={styles.myStatsHeader}>
                <h2>{pathUserId ? 'Stats' : 'My Stats'}</h2>
                <DatePickerButton
                    onChange={(d) => {
                        setStats([])
                        setDateFilter(format(d, 'yyyy-MM-dd'))
                    }}
                    value={dateFilter}
                />
            </div>

            <Table
                resizable={false}
                loading={statsLoading}
                loadingText={''}
                noDataText={<span>No data found</span>}
                data={stats}
                LoadingComponent={() => (
                    <Loading
                        loading={statsLoading}
                        style={{
                            transform: 'translateY(-2px)',
                        }}
                    />
                )}
                columns={userStatsColumns(siteList)}
                page={statsTablePageIndex}
                defaultPageSize={10}
                onPageChange={setStatsTablePageIndex}
                showPageSizeOptions={false}
            />
        </section>
    )
}

export default StatsTable
