import axios from 'axios'
import camelCaseKeys from '../camelCaseKeys'

// For use with users
const analyticsHttp = axios.create({
    baseURL: `${
        window.location.host.includes('localhost')
            ? `http://localhost:${process.env.REACT_APP_ANALYTICS_PORT}`
            : ''
    }/analytics/api/v2`,
})

// Convert keys to camelCase from snake_case
analyticsHttp.interceptors.response.use(({ data }) => camelCaseKeys(data))

// Middleware Request
analyticsHttp.interceptors.request.use((req) => {
    // Auth Token
    const token = localStorage.getItem('fs_app_token')
    if (token) {
        req.headers = {
            ...req.headers,
            Authorization: `Bearer ${token}`,
        }
    }
    return req
})

// Middleware Response
analyticsHttp.interceptors.response.use(
    (response) => response,
    (error) => {
        if (!error.response) return Promise.reject(error.message)
        const { status, data } = error.response

        // eslint-disable-next-line
        return Promise.reject({ status, message: data.message })
    },
)

export default analyticsHttp
