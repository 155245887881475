/* eslint-disable  react/forbid-prop-types */
import React from 'react'
import BaseSelect from '../BaseSelect'
import { connect } from 'formik'
import { BaseSelectTypes } from '../BaseSelect/BaseSelect'

type Props = {
    name: string
    options?: any[]
    formik?: any
}

const DropdownFormik: React.FC<Omit<BaseSelectTypes, 'value'> & Props> = ({
    options,
    name,
    formik: { setFieldValue, values },
    ...rest
}) => (
    <BaseSelect
        options={options}
        name={name}
        onChange={(option) => setFieldValue(name, option)}
        {...rest}
        value={values[name]}
    />
)

DropdownFormik.defaultProps = {
    options: [],
}

export default connect(DropdownFormik)
