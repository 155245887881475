import { createReducer, createAction } from 'redux-act'

import store from '../store'
import api from '../api'
import { makeAsyncAction, makeAsyncReducer, makeAsyncStateProperty } from '../lib/utils/storeUtils'

const actions = {
    ...makeAsyncAction('SET_FLAT_RATE_DATA'),
    clearData: createAction('CLEAR_FLAT_RATE_DATA'),
}

const initialState = {
    ...makeAsyncStateProperty({ key: 'flatRateData', initialData: null }),
}

export default createReducer(
    {
        ...makeAsyncReducer({ actions, actionKey: 'setFlatRateData', stateKey: 'flatRateData' }),
        /* @ts-ignore */
        [actions.clearData]: () => initialState,
    },
    initialState,
)

/* @ts-ignore */
export const setFlatRateDataLoading = (bool: boolean) => store.dispatch(actions.setFlatRateDataLoading(bool))

export const setFlatRateData = (data: any) => {
    /* @ts-ignore */
    const siteList = store.getState().globalState.siteList?.data ?? []
    const withSiteName = data.map((d: any) => {
        const site = siteList.find((s: any) => s.id === d.property)
        const siteName = site?.name ? site.name : 'No Site Name'
        return { ...d, siteName }
    })
    /* @ts-ignore */
    store.dispatch(actions.setFlatRateData(withSiteName))
    /* @ts-ignore */
    store.dispatch(actions.setFlatRateDataLoading(false))
}

export const setFlatRatePaymentValue = (value: string, siteId: string, postId: string, author: string) => {
    const data = store
        .getState()
        /* @ts-ignore */
        .paymentsState.flatRateState.flatRateData.data.reduce(
            (d: any, row: any) => d.concat([row.postId === postId ? { ...row, payAmount: value } : row]),
            [],
        )
    /* @ts-ignore */
    store.dispatch(actions.setFlatRateData(data))

    api.payments.flatRate.updateFlatRatePayment({ payAmount: value, siteId, postId, author })
}

export const clearData = () => {
    store.dispatch(actions.clearData())
}
