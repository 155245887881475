import { analyticsHttp, handleError } from '../lib/utils'

interface IGetPageviews {
    (userId: string, siteId: string, startDate: string, endDate: string): Promise<Array<{
        date: string
        views: number
        tier: string
    }> | void>
}

export const getPageviews: IGetPageviews = async (userId, siteId, startDate, endDate) => {
    try {
        const { data = [] }: { data: { date: string; views: number; tier: string }[] } = await analyticsHttp.get(
            '/pageviews',
            {
                params: { userId, siteId, startDate, endDate },
            },
        )

        return data
    } catch (e) {
        return handleError(e, null, false)
    }
}
