import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { format, startOfMonth, endOfMonth, parseISO } from 'date-fns'

import api from '../../../api'
import SiteStatsHeader from './ui/SiteStatsHeader'
import { isAuthorized } from '../../../lib/utils'
import { CAN_VIEW_SITE_ANALYTICS } from '../../../lib/utils/permissions'
import { pageviewsByTier } from '../../utils/pageviewsByTier'
import Disclaimer from '../../ui/Disclaimer/Disclaimer'
import SiteStatsTable from './ui/SiteStatsTable'
import SiteStatsCards from './ui/SiteStatsCards'
import { useQueryString } from '../../../lib/utils/hooks'

import styles from './siteStats.module.scss'

const SiteStats = () => {
    const navigate = useNavigate()
    let { siteId } = useParams()

    useEffect(() => {
        if (
            !isAuthorized({
                permission: CAN_VIEW_SITE_ANALYTICS,
            })
        ) {
            navigate('/404')
        }
    }, [navigate])
    const [dateFilter, setDateFilter] = useQueryString('date', format(new Date(), 'yyyy-MM-dd'))
    const startDate = format(startOfMonth(parseISO(dateFilter)), 'yyyy-MM-dd')
    const endDate = format(endOfMonth(parseISO(dateFilter)), 'yyyy-MM-dd')

    const [stats, setStats] = useState([])
    const [statsLoading, setStatsLoading] = useState(false)

    /**
     * Handles fetching siteviews & active user list when date or site filter changes
     */
    useEffect(() => {
        const cb = async () => {
            const getActiveUsers = async () => {
                setStatsLoading(true)

                const usersStats =
                    (await api.payments.siteStats.getActiveUsers({
                        siteId,
                        startDate,
                        endDate,
                    })) || []

                const usersStatsWithTieredViews = usersStats.map((user: any) => {
                    const { pageViews, ...rest } = user
                    const userViewsByTier = pageviewsByTier(pageViews)

                    return {
                        ...rest,
                        ...userViewsByTier,
                    }
                })

                setStats(usersStatsWithTieredViews)
                setStatsLoading(false)
            }

            getActiveUsers()
        }
        if (siteId) {
            cb()
        }
    }, [endDate, siteId, startDate])

    return (
        <>
            <SiteStatsHeader dateFilter={dateFilter} setDateFilter={setDateFilter} setStats={setStats} />
            <div className={`${styles.container} contentBox`}>
                <Disclaimer isSiteStats />
                <SiteStatsCards siteId={siteId} startDate={startDate} endDate={endDate} />
                <SiteStatsTable stats={stats} statsLoading={statsLoading} />
            </div>
        </>
    )
}

export default SiteStats
