import React from 'react'
import { toast } from 'react-toastify'
import copy from 'clipboard-copy'
import { Tooltip } from 'react-tippy'
import { truncateId } from '../truncateId'
import { Button } from '../../framework'

type Props = {
    userId: string | number
}

const UserIdTooltip = ({ userId }: Props) => {
    return (
        <Tooltip
            interactive
            interactiveBorder={15}
            html={
                <Button
                    linkStyle
                    style={{
                        color: '#f6f6f6',
                    }}
                    onClick={() => {
                        // @ts-expect-error ts-migrate(2345) FIXME: Type 'number' is not assignable to type 'string'.
                        copy(userId)
                        toast.info('Copied User ID')
                    }}
                >
                    {userId}
                </Button>
            }
        >
            {truncateId(String(userId))}
        </Tooltip>
    )
}

export default UserIdTooltip
