import paymentsHttp from '../../ManagePayments/utils/paymentsHttp'
import { handleError } from '../../lib/utils'

interface IGetMyStats {
    (args: { userId: string; startDate: string; endDate: string }): Promise<Array<{
        siteName: string
        siteId: string
        pubCount: number
        lastPublished: number
        views: Array<{
            views: number
            geoTier: number
        }>
    }> | void>
}

export const getMyStats: IGetMyStats = async ({ userId, startDate, endDate }) => {
    try {
        const { data } = await paymentsHttp.get(`/personalStats/sites`, {
            params: { startDate, endDate, userId },
        })

        return data.data
    } catch (e) {
        return handleError(e, null, false)
    }
}
