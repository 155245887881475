import React, { MouseEvent } from 'react'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import * as Icons from '../Icons'
import colors from '../../utils/colors'
import './styles.module.scss'

type IconProps = {
    icon: string
    className?: string
    color?: string
    fill?: string
    size?: number
    onClick?: (e: MouseEvent<HTMLElement>) => void
}

const Icon: React.FC<IconProps> = ({ className = '', icon, color = '', size = 1, ...rest }) => {
    const newClassName = `icon ${className ? className : ''}`

    const Comp = Icons[upperFirst(camelCase(icon))]

    const props = {
        className: newClassName,
        style: { width: `${size}em`, height: `${size}em` },
        ...rest,
    } as any

    if (color) props.style.fill = colors[color] ? colors[color] : color

    // Get the correct icon from this array of functions
    return Comp ? <Comp {...props} /> : null
}

export default Icon
