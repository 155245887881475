/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react'
import cx from 'classnames'

import styles from './checkbox.module.scss'

type Props = {
    className?: string
    disabled?: boolean
    name: string
    label: string
    labelClassName?: string
    required?: boolean
    id?: string
    checked: boolean
}

const Checkbox: React.FC<Props> = ({
    className,
    disabled,
    name,
    label,
    labelClassName,
    required,
    id,
    checked,
    ...rest
}) => {
    return (
        <div>
            <input
                className={cx(styles.checkbox, className, disabled)}
                name={name}
                type="checkbox"
                id={id || name}
                disabled={disabled}
                required={required}
                checked={checked}
                {...rest}
            />

            <label className={cx(styles.label, labelClassName, disabled)} htmlFor={id || name}>
                {label}
            </label>
        </div>
    )
}

Checkbox.defaultProps = {
    className: '',
    disabled: false,
    required: false,
    labelClassName: '',
    id: '',
}

export default Checkbox
