const config = {
    prod: {
        domain: 'auth.minutemedia.com',
        // MMsport tenant application clientId
        clientId: '8LWp7RDYiSsXUpvBrWvx3MjyfSnlqSIu',
        // Audience matches user service API for backend
        audience: 'https://user-service2.minutesvc.com',
        connection: 'MinuteMedia-Sport',
    },
    dev: {
        domain: 'auth.minutemedia.com',
        // FS App Console - Dev application clientId
        clientId: 'laGuKNQ3Th1S6q2q972QUEYNH6VAx7EA',
        audience: 'https://user-service2.minutesvc.com',
        connection: 'Community',
    },
    test: {
        domain: 'auth.minutemedia.com',
        // FS App Console - Test application clientId
        clientId: '2dM5J0BvkJ1LeewmCDvPcL3wYsLJWO4p',
        audience: 'https://user-service2.minutesvc.com',
        connection: 'Community',
    },
}

const env = window.location.host.includes('app.')
    ? 'prod'
    : window.location.host.includes('app-test.')
    ? 'test'
    : 'dev'

export default config[env]
