import React from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { components } from 'react-select'
import cx from 'classnames'

import api from '../../../../api'
import { Button, DropdownFormik } from '../../../../lib/framework'
import { checkPermsAndFormatOptions, formatSitesWithVerticals } from '../../../../lib/utils'
import Icon from '../../../../lib/framework/Icon'

import styles from './addSiteUI.module.scss'

const initialValues = {
    site: null,
    primaryRole: null,
}

type Props = {
    withDefaultButtons?: boolean
    userSites: ISiteList
    className?: string
    textHorizontalMargin?: string
    handleToggle: (...args: any[]) => any
    isDisabled?: boolean
    userData: UserData
}

const AddSiteRow: React.FC<Props> = ({
    handleToggle,
    withDefaultButtons,
    userSites,
    className,
    textHorizontalMargin,
    isDisabled,
    userData,
    ...rest
}) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'globalState' does not exist on type 'Def... Remove this comment to see the full error message
    const siteList = useSelector(({ globalState }) => globalState.siteList.data)
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'globalState' does not exist on type 'Def... Remove this comment to see the full error message
    const roleList = useSelector(({ globalState }) => globalState.roleList.data)
    const selectedUserId = userData.id

    const handleSubmit = async (
        // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'roleSlug' implicitly has an 'any'... Remove this comment to see the full error message
        { primaryRole: { value: roleSlug }, site: { value: siteId } },
        { setSubmitting }: any,
    ) => {
        await api.users.addUserRoles({
            id: selectedUserId,
            roles: [roleSlug],
            sites: [siteId],
            type: 'primary',
            setSubmitting,
        }).then(handleToggle)
    }

    const DropdownIndicator = (props: any) => (
        <components.DropdownIndicator {...props}>
            <div className={styles.roleSelectIcon}>
                <Icon icon="Plus" fill="#999" />
            </div>
        </components.DropdownIndicator>
    )

    return (
        // @ts-expect-error ts-migrate(2322) FIXME: The intersection '{ primaryRole: { value: any; }; ... Remove this comment to see the full error message
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting, values, ...formik }) => {
                const { site, primaryRole } = values

                const isSubmitDisabled = isSubmitting || !site || !primaryRole

                return (
                    <Form className={styles.form}>
                        <div className={cx(className, styles.addSiteContainer)}>
                            {/* Get site and role options from global state */}
                            <DropdownFormik
                                options={formatSitesWithVerticals(siteList, userSites)}
                                className={styles.dropdown}
                                name="site"
                                id="addSitesSiteDropdown"
                                label="Add site..."
                                labelClassName={styles.label}
                                components={{ DropdownIndicator }}
                                isDisabled={isSubmitting}
                                openMenuOnFocus
                                tabSelectsValue={false}
                                {...rest}
                            />

                            <DropdownFormik
                                options={
                                    site
                                        ? checkPermsAndFormatOptions({
                                              roleList,
                                              siteId: site.value,
                                              type: 'primary',
                                          })
                                        : []
                                }
                                name="primaryRole"
                                id="addSitesRoleDropdown"
                                className={styles.dropdown}
                                label="With primary role..."
                                isDisabled={isDisabled || isSubmitting}
                                components={{ DropdownIndicator }}
                                openMenuOnFocus
                                tabSelectsValue={false}
                                {...rest}
                            />

                            {withDefaultButtons && (
                                <>
                                    <Button size="small" type="submit" disabled={isSubmitDisabled}>
                                        Sav{isSubmitting ? 'ing' : 'e'} Changes
                                    </Button>

                                    <Button size="small" type="warning" onClick={handleToggle}>
                                        Cancel
                                    </Button>
                                </>
                            )}
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

AddSiteRow.defaultProps = {
    withDefaultButtons: true,
    className: '',
    textHorizontalMargin: '0 6.5rem',
    isDisabled: false,
}

export default AddSiteRow
