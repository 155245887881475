import { updateUser } from '../../state/userState'

import { getUserData, getCurrentUserFromStore } from '../../lib/utils'
import { getCurrentUser } from '../global'

interface Args {
    addedRoles?: UserRoles
    deletedRoles?: UserRoles
    sitesMeta: SitesMeta
    sites: string[]
    id: string
    type: RoleType
    roles: UserRoles
    setSubmitting: (b: boolean) => void
    voltaxSlug: string[]
    voltaxSitesList: VoltaxSiteObject[]
}

export const updateUserWithNewRoles = async ({
    addedRoles,
    deletedRoles,
    sitesMeta,
    sites,
    id,
    type,
    roles,
    setSubmitting,
    voltaxSlug,
    voltaxSitesList,
}: Args) => {
    const { roles: oldRoles, titles } = getUserData(id)

    if (addedRoles?.length) {
        await addRoles({
            id,
            addedRoles,
            oldRoles,
            sites,
            type,
            roles,
            sitesMeta,
            voltaxSlug,
            voltaxSitesList,
            titles,
        })
    } else if (deletedRoles?.length) {
        deleteRoles({ id, deletedRoles, oldRoles, sitesMeta, titles })
    } else return

    /**
     * If this user is the current user, update their user object in store
     */
    const { id: currentUserId } = getCurrentUserFromStore()

    if (currentUserId === id) {
        getCurrentUser(false)
    }

    if (setSubmitting) setSubmitting(false)
}

const addRoles = async ({
    id,
    addedRoles,
    oldRoles,
    type,
    sitesMeta,
    voltaxSlug,
    titles,
    voltaxSitesList,
}: Required<Omit<Args, 'deletedRoles' | 'setSubmitting'>> & {
    oldRoles: UserRoles
    titles: { [key: string]: string }
}) => {
    let newUserRoles: UserRoles = []

    addedRoles.forEach((role: any) => {
        let voltaxFirstSites: VoltaxSiteObject[] = []
        if (voltaxSlug.length) {
            voltaxFirstSites = voltaxSitesList.filter((site: VoltaxSiteObject) => site.voltaxId === role.siteId)
        }

        const siteId = voltaxFirstSites.length ? voltaxFirstSites[0].id : role.siteId

        const newRole = {
            ...(titles[siteId] && { title: titles[siteId] }),
            slug: role.slug,
            siteId: siteId,
            name: role.name,
            createdAt: role.createdAt,
            type,
        }

        if (type === 'primary') {
            const oldRolesWithoutPreviousPrimary = oldRoles.filter(
                (
                    oldRole: any, // Keep other sites' roles and all secondary roles on this site
                ) => oldRole.siteId !== siteId || oldRole.type === 'secondary',
            )
            newUserRoles = [...oldRolesWithoutPreviousPrimary, newRole]
        } else {
            newUserRoles = [...oldRoles, newRole]
        }
    })

    updateUser(id, {
        roles: newUserRoles,
        sitesMeta,
    })
}

const deleteRoles = ({
    id,
    deletedRoles,
    oldRoles,
    sitesMeta,
    titles,
}: Required<Pick<Args, 'deletedRoles' | 'id' | 'sitesMeta'>> & {
    oldRoles: UserRoles
    titles: { [key: string]: string }
}) => {
    const newUserRoles = oldRoles.filter(
        // We want to return only the roles
        // which do not match any of the deleted roleId + siteId pairs
        (role: any) => !deletedRoles.some((r: any) => r.slug === role.slug && r.siteId === role.siteId),
    )
    let updatedTitles = titles
    delete updatedTitles[deletedRoles[0].siteId]

    updateUser(id, {
        roles: newUserRoles,
        sitesMeta,
        titles: updatedTitles,
    })
}
