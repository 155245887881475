import store from '../../store'

export const getUserData = (id: any) => {
    let data

    // If we're on userDetail page, we may not have data from the manage users table, say, if we navigate
    // straight to the details. In that case, we will use singleUserData instead of filtering all loaded users.
    const tryUser = store.getState().userState.singleUserData.data

    try {
        data =
            tryUser.id === id
                ? tryUser
                : store.getState().userState.usersData.data.filter((d: any) => d.id === id)[0]
    } catch (e) {
        console.error(e) // eslint-disable-line
        throw new Error('User not found!')
    }

    return data || {}
}
