import { isAuthorized } from '.'
import { CAN_MANAGE_USERS } from './permissions'

/**
 *
 * @param {array} roleList The global roleList
 * @param {number} [siteId] A site Id
 * @param {('secondary'|'primary'|'all')} [type='secondary'] Role type to filter
 * @param {bool} [checkPerms=true] Whether to check permissions
 * @returns {array} All permitted role roleList, formatted for React-Select
 */
interface Args {
    roleList: IRoleList
    siteId?: string
    type?: RoleType | 'all'
    checkPerms?: boolean
}

const checkPermsAndFormatOptions = ({
    roleList,
    siteId,
    type = 'secondary',

    checkPerms = true,
}: Args) => {
    return roleList
        ?.reduce((acc: any, option: any) => {
            if (type !== 'all' && option.type !== type) return acc

            /**
             *  System_admin should only be a global role and the only global role
             *  * Update 2/9/21: Adding Affiliate Podcaster global role.
             */
            const isGlobalRole = ['system_admin', 'affiliate_podcaster'].some((slug) => slug === option.slug)
            if ((siteId === '0' && !isGlobalRole) || (siteId && siteId !== '0' && isGlobalRole)) {
                return acc
            }

            if (!checkPerms || isAuthorized({ permission: CAN_MANAGE_USERS, siteId })) {
                return [...acc, { value: option.slug, label: option.name }]
            }

            return [...acc, { value: option.slug, label: option.name, isDisabled: true }]
        }, [])
        .sort((a: any, b: any) => (a.label >= b.label ? 1 : -1))
}

export default checkPermsAndFormatOptions
