import { useEffect } from 'react'

import { useAuth0 } from '../../../react-auth0-spa'

const Logout = () => {
    const { logout } = useAuth0()

    useEffect(() => {
        logout({ returnTo: window.location.origin })
        localStorage.clear()
    }, [logout])

    return null
}

export default Logout
