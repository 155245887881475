import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { addDays, compareAsc } from 'date-fns'

import { Modal } from '../../../../framework'
import Icon from '../../../../framework/Icon'
import api from '../../../../../api'
import styles from './payoneerModal.module.scss'

type Props = {
    sitesMeta: SitesMeta
    payoneerStatus?: 'approved' | 'declined' | 'registered' | ''
    userId: string
}

const PayoneerModal: React.FC<Props> = ({ payoneerStatus, userId, sitesMeta }) => {
    const [open, setOpen] = useState(false)
    const [payoneerLinkLoading, setPayoneerLinkLoading] = useState(false)
    const [payoneerLink, setPayoneerLink] = useState('')

    const setLastViewedDate = () => {
        window.localStorage.setItem('payoneer_modal_timestamp', JSON.stringify(new Date()))
    }

    const viewedRecently = () => {
        const lastShown = window.localStorage.getItem('payoneer_modal_timestamp')

        if (!lastShown) {
            return false
        }

        if (compareAsc(new Date(), addDays(new Date(lastShown), 3)) === 1) {
            return false
        }

        return true
    }

    useEffect(() => {
        const run = () => {
            if (!sitesMeta) {
                return
            }

            const isContractor = sitesMeta.some((siteMeta: any) => siteMeta.contractor)
            const hasRegisteredForPayoneer = !!payoneerStatus

            if (isContractor && !hasRegisteredForPayoneer && !viewedRecently()) {
                setOpen(true)
                setLastViewedDate()
            }
        }

        run()
        setInterval(run, 600000)
    }, [sitesMeta, payoneerStatus])

    const getPayoneerLink = () => {
        if (payoneerLink) {
            return payoneerLink
        }

        if (payoneerLinkLoading) {
            return
        }

        setPayoneerLinkLoading(true)

        api.users.getPayoneerRegistrationLink(userId, window.location.href).then((link) => {
            setPayoneerLink(link)
            setPayoneerLinkLoading(false)
        })
    }

    const handleModal = () => setOpen((o) => !o)

    return open ? (
        <Modal handleModal={handleModal} ariaLabel="Contracts" className="owl">
            <div>
                <p>
                    IMPORTANT: You have not completed the setup of your Payoneer account. You must have a Payoneer
                    account for FanSided to pay you. Click this link to begin or complete your Payoneer account
                    setup:
                    <br />
                </p>
                {getPayoneerLink() ? (
                    <a href={getPayoneerLink()} className={`${styles.wmLink} ${styles.lightBlueFont}`}>
                        Register With Payoneer
                        <Icon icon="ExternalLink" fill="#5a97c1" size={0.8} />
                    </a>
                ) : (
                    <span className={`${styles.wmLink} ${styles.lightBlueFont}`}>
                        Loading Payoneer Registration...
                    </span>
                )}
            </div>
        </Modal>
    ) : null
}

PayoneerModal.defaultProps = {
    payoneerStatus: '',
    sitesMeta: [],
}

const stateToProps = ({ globalState }: any) => ({
    sitesMeta: globalState.currentUser.data.sitesMeta,
    payoneerStatus: globalState.currentUser.data?.payoneerStatus,
    userId: globalState.currentUser.data.id,
})

export default connect(stateToProps)(PayoneerModal)
