import React from 'react'
import cx from 'classnames'
// @ts-ignore
import ReactTable, { TableProps } from 'react-table-v6'

import { makeStyledFilters } from './tableUtils/makeStyledFilters'
import styles from './table.module.scss'
import 'react-table-v6/react-table.css'

type CustomTableProps = {
    checkbox?: boolean
    subTable?: boolean
}

type Props = {
    className?: string
    data: any[]
    columns: any[]
    loading: boolean
    defaultPageSize?: number
    noDataText?: React.ReactNode
    SubComponent?: (...args: any[]) => any
    LoadingComponent?: (...args: any[]) => any
    showPagination?: boolean
    filterable?: boolean
    checkbox?: boolean
    subTable?: boolean
    resizable?: boolean
    loadingText?: string
    minRows?: number
    showPageSizeOptions?: boolean
    defaultSorted?: {}[]
    expanded?: { 0: boolean }
    manual?: boolean
    onPageSizeChange?: (...args: any[]) => any
    onPageChange?: (...args: any[]) => any
    onExpandedChange?: (...args: any[]) => any
    getTdProps?: (...args: any[]) => any
    getTrProps?: (...args: any[]) => any
    getTrGroupProps?: (...args: any[]) => any
    onSortedChange?: (...args: any[]) => any
    sorted?: {}[]
    pageSizeOptions?: number[]
    pages?: number
    page?: number
    collapseOnDataChange?: boolean
    resolveData?: (...args: any[]) => any
    sortable?: boolean
    NoDataComponent?: React.ReactNode
}

const Table: React.FC<CustomTableProps & Partial<TableProps> & Props> = React.forwardRef<any, Props>(
    (props, ref: any) => {
        // Needed to prevent column from spreading into CheckboxTable via ...rest
        const { columns, ...allPropsExceptColumns } = props

        const {
            className,
            data,
            loading,
            defaultPageSize,
            noDataText,
            SubComponent,
            getTrProps,
            resolveData,
            showPagination,
            filterable,
            checkbox,
            subTable,
            ...rest
        } = allPropsExceptColumns

        const styledColumns = filterable ? makeStyledFilters(columns) : columns

        return (
            <ReactTable
                className={cx(
                    styles.table,
                    'fsTable',
                    className && className,
                    !subTable && '-striped',
                    subTable && 'subTable',
                )}
                data={data}
                columns={styledColumns}
                resolveData={resolveData}
                loading={loading}
                defaultPageSize={defaultPageSize}
                minRows={0}
                noDataText={noDataText}
                SubComponent={SubComponent}
                getTrProps={getTrProps}
                showPagination={showPagination}
                filterable={filterable}
                defaultFilterMethod={(filter: any, row: any) =>
                    String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
                }
                ref={ref}
                {...rest}
            />
        )
    },
)

Table.defaultProps = {
    loading: false,
    defaultPageSize: 20,
    noDataText: <span>No data found!</span>,
    SubComponent: undefined,
    showPagination: true,
    filterable: false,
    checkbox: false,
    className: '',
    subTable: false,
}

export default Table
