import React, { useState, useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'react-tippy'
import { format, parseISO, isBefore } from 'date-fns'

import { ManagePaymentsContext } from '../DashboardHome/ManagePaymentsContext'
import { Dropdown, Input } from '../../../lib/framework'
import { statusOptions } from '../../utils/statusOptions'
import api from '../../../api'
import Icon from '../../../lib/framework/Icon'
import ButtonUI from '../../ui/Button'
import { Button } from '../../../lib/framework'

import styles from './primaryHeader.module.scss'
import { getDateForLastMonth } from '../../../lib/utils'

type PrimaryHeaderProps = {
    siteOptions: {
        value: string
        label: string
        vertical?: string
    }[]
    verticalOptions: {
        value: string
        label: string
    }[]
    dateOptions: {
        value: string
        label: string
    }[]
    loading: boolean
}

const invoiceTypeOptions = [
    {
        label: 'All Types',
        value: 'all',
    },
    {
        label: 'Expert',
        value: 'site_expert',
    },
    {
        label: 'Contributor',
        value: 'contributor',
    },
    {
        label: 'External/Freelance',
        value: 'external/freelance',
    },
    {
        label: 'Podcast',
        value: 'podcast',
    },
]

const PrimaryHeader: React.FC<PrimaryHeaderProps> = ({
    dateOptions,
    loading,
    siteOptions,
    verticalOptions: verticalOptionsWithoutAll,
}) => {
    const [prevSite, setPrevSite] = useState({ label: 'Loading...' })
    const [nextSite, setNextSite] = useState({ label: 'Loading...' })

    const {
        date,
        site,
        status,
        vertical,
        invoiceType,
        search,
        filterDate,
        filterSite,
        filterStatus,
        filterVertical,
        filterInvoiceType,
        filterSearch,
        handleClear,
    } = useContext(ManagePaymentsContext)

    const verticalOptions = [
        {
            value: 'all',
            label: 'All Verticals',
        },
        ...verticalOptionsWithoutAll,
    ]

    const siteString = String(site)
    const statusDropdownValue = statusOptions.find((s) => s.value === status)
    const siteDropdownValue = siteOptions.find((s: any) => s.value === siteString)
    const verticalDropdownValue = verticalOptions?.find((v) =>
        vertical === 'all' ? { value: vertical, label: 'All Verticals' } : v.value === vertical,
    )
    const invoiceTypeDropdownValue = invoiceTypeOptions.find((t) =>
        invoiceType === 'all' ? { value: invoiceType, label: 'All Verticals' } : t.value === invoiceType,
    )

    let dateLabel
    try {
        dateLabel = format(parseISO(date), 'MMM yyyy')
    } catch (e) {
        dateLabel = format(getDateForLastMonth(), 'MMM yyyy')
    }
    const dateDropdownValue = {
        label: dateLabel,
        value: date,
    }

    useEffect(() => {
        if (!siteOptions.length) return

        /**
         *
         * @param {string} dir The direction - 'prev' or 'next
         * @returns {object} The new site object
         */
        const findNewSite = (dir: any) => {
            const currentSiteIndex = siteOptions.findIndex((s: any) => s.value === siteString)

            const getNewSiteIndex = () => {
                if (currentSiteIndex === -1) return null

                if (currentSiteIndex === 0) {
                    return dir === 'next' ? currentSiteIndex + 1 : siteOptions.length - 1
                }
                if (currentSiteIndex === siteOptions.length - 1) {
                    return dir === 'next' ? 0 : currentSiteIndex - 1
                }

                return dir === 'next' ? currentSiteIndex + 1 : currentSiteIndex - 1
            }

            const newSiteIndex = getNewSiteIndex()

            if (newSiteIndex === null) return { label: 'None' }
            const newSite = siteOptions[newSiteIndex] || { label: 'None' }

            return newSite
        }

        setPrevSite(findNewSite('prev'))
        setNextSite(findNewSite('next'))
    }, [siteString, siteOptions])

    /**
     * Fetch invoices with filters
     */
    useEffect(() => {
        api.payments.invoices.getAll({
            site,
            date,
            ...(isBefore(parseISO(date), new Date(2019, 4, 1)) && { before: true }),
            status,
            vertical,
            invoiceType,
        })
    }, [date, invoiceType, site, status, vertical])

    /**
     *
     * @param {string} dir 'prev' or 'next'
     * @returns {func} This returns the anoynomous function that would otherwise be inlined as the event handler
     */
    const cycleSites = (dir: any) => () => filterSite(dir === 'next' ? nextSite : prevSite)

    const toolTipText = {
        prevSiteTitle: prevSite.label,
        nextSiteTitle: nextSite.label,
    }

    return (
        <header className={`${styles.primaryHeader} horizontal-owl`}>
            <div className={`${styles.filterDropdowns} horizontal-owl`}>
                <div className={styles.date}>
                    <Dropdown value={dateDropdownValue} options={dateOptions} onChange={filterDate} name="date" />
                </div>
                <div className={styles.status}>
                    <Dropdown
                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Object... Remove this comment to see the full error message
                        value={statusDropdownValue}
                        options={statusOptions}
                        onChange={filterStatus}
                        name="status"
                    />
                </div>
                <div className={styles.vertical}>
                    <Dropdown
                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Object... Remove this comment to see the full error message
                        value={verticalDropdownValue}
                        options={verticalOptions}
                        onChange={filterVertical}
                        name="status"
                    />
                </div>

                <div>
                    <Dropdown
                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Object... Remove this comment to see the full error message
                        value={invoiceTypeDropdownValue}
                        options={invoiceTypeOptions}
                        onChange={filterInvoiceType}
                        name="status"
                    />
                </div>

                <div className={`${verticalOptionsWithoutAll?.length ? styles.site : styles.fullSite}`}>
                    <Dropdown
                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Object... Remove this comment to see the full error message
                        value={siteDropdownValue}
                        options={siteOptions}
                        onChange={filterSite}
                        name="site"
                        isDisabled={loading}
                        isLoading={loading}
                    />
                    <Tooltip title={toolTipText.prevSiteTitle} className={styles.cycleTooltip}>
                        <ButtonUI
                            className={styles.cycleSitesButtons}
                            onClick={cycleSites('prev')}
                            disabled={loading}
                        >
                            <Icon icon="CaretDown" fill="#333" />
                        </ButtonUI>
                    </Tooltip>
                    <Tooltip title={toolTipText.nextSiteTitle} className={styles.cycleTooltip}>
                        <ButtonUI
                            className={`${styles.cycleSitesButtons} ${styles.next}`}
                            onClick={cycleSites('next')}
                            disabled={loading}
                        >
                            <Icon icon="CaretDown" fill="#333" />
                        </ButtonUI>
                    </Tooltip>
                </div>
                <div className={styles.filterName}>
                    <Input
                        containerClassName={styles.filterNameInput}
                        value={search}
                        name="searchField"
                        handleChange={filterSearch}
                        label="Search by name/id..."
                    />
                </div>
                <div className={styles.clearFilters}>
                    <Button type="primary" onClick={handleClear}>
                        Clear Filters
                    </Button>
                </div>
            </div>
        </header>
    )
}

const stateToProps = ({ paymentsState: { filteringState, invoiceState } }: any) => ({
    loading: invoiceState.invoiceData.isLoading,
    dateOptions: filteringState.dateOptions,
})

export default connect(stateToProps)(PrimaryHeader)
