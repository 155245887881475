/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, ChangeEvent, ReactElement, KeyboardEvent } from 'react'

import cx from 'classnames'

import styles from './input.module.scss'
import Icon from '../Icon'

export type InputTypes = {
    value: string
    id?: string
    name: string
    type?: string
    className?: string
    wrapperClassName?: string
    containerClassName?: string
    label?: string | ReactElement
    icon?: string
    disabled?: boolean
    required?: boolean
    autoFocus?: boolean
    placeholder?: string
    handleChange: (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLTextAreaElement>) => void
    getLabelProps?: Function
    onBlur?: Function
    style?: Object
}

const Input: React.FC<InputTypes> = ({
    id,
    name,
    value,
    type,
    className,
    containerClassName,
    wrapperClassName,
    label,
    icon,
    disabled,
    required,
    handleChange,
    getLabelProps,
    placeholder,
    onBlur: handleOnBlur,
    style,
    ...rest
}) => {
    const [active, setActive] = useState(false)
    const [focused, setFocused] = useState(false)
    const ref = useRef<any>()

    useEffect(() => (!value ? setActive(false) : setActive(true)), [value])

    const onFocus: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = () => {
        setActive(true)
        setFocused(true)
    }

    const onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
        handleOnBlur && handleOnBlur(e)

        setFocused(false)
        return !e.target.value && setActive(false)
    }

    return (
        <div
            className={cx(
                styles.formElement,
                containerClassName && containerClassName,
                (placeholder || active) && styles.active,
            )}
            style={style}
        >
            {label && (
                <label
                    htmlFor={id || name}
                    className={cx(
                        styles.label,
                        focused && styles.labelFocused,
                        type === 'textarea' && styles.label__textarea,
                        icon && styles.labelOffset,
                        disabled && styles.labelDisabled,
                    )}
                    {...(getLabelProps && getLabelProps())}
                >
                    {label}
                    {required ? <sup> *</sup> : ''}
                </label>
            )}
            <div
                className={cx(
                    styles.inputWrapper,
                    focused && type !== 'textarea' && styles.inputFocused,
                    wrapperClassName && wrapperClassName,
                )}
            >
                {icon && (
                    <Icon
                        icon={icon}
                        fill="#999"
                        className={cx(styles.icon, focused && styles.iconFocused, disabled && styles.iconDisabled)}
                        onClick={() => ref?.current?.focus()}
                        size={0.8}
                    />
                )}
                {type === 'textarea' ? (
                    <textarea
                        id={id || name}
                        name={name}
                        className={cx(styles.textarea, className && className, disabled && styles.disabled)}
                        disabled={disabled}
                        required={required}
                        placeholder={placeholder}
                        onChange={handleChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        value={value}
                        {...rest}
                    />
                ) : (
                    <input
                        id={id || name}
                        name={name}
                        type={type}
                        className={cx(
                            className && className,
                            styles.input,
                            icon && styles.iconOffset,
                            disabled && styles.disabled,
                        )}
                        disabled={disabled}
                        required={required}
                        onChange={handleChange}
                        onFocus={onFocus}
                        placeholder={placeholder}
                        value={value}
                        ref={ref}
                        {...rest}
                        // Moved onBlur below so can overwrite Downshift (ex. in SiteDetailsTab)
                        onBlur={onBlur}
                    />
                )}
            </div>
        </div>
    )
}

export default Input
