/* eslint-disable react/button-has-type */

import React from 'react'
import { connect } from 'formik'
import cx from 'classnames'

import styles from '../button.module.scss'

// Nick: not sure if it is ok to set buttonProps to any? - Marissa
type Props = {
    buttonProps: {
        [key: string]: string | boolean | any
    }
}

const SubmitButton: React.FC<Props> = (props) => {
    const { buttonProps } = props
    const { type, size, disabled, children, className, customType, linkStyle } = buttonProps

    return (
        <button
            className={cx(className, styles.button, styles.submit, {
                [styles.primary]: customType === 'primary',
                [styles.secondary]: customType === 'secondary',
                [styles.success]: customType === 'success',
                [styles.info]: customType === 'info',
                [styles.warning]: customType === 'warning',
                [styles.danger]: customType === 'danger',
                [styles.small]: size === 'small',
                [styles.large]: size === 'large',
                [styles.linkStyle]: linkStyle,
            })}
            disabled={disabled}
            type={type}
        >
            {children}
        </button>
    )
}

export default connect(SubmitButton)
