import React, { useState, useEffect } from 'react'

import api from '../../../../../api'

import styles from './expandedInvoiceStatsRow.module.scss'

type OwnProps = {
    data: string[]
    index: number
    siteId?: number
    isGlobal?: boolean
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ExpandedInvoiceStatsRow.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'ExpandedInvoiceStatsRow' implicitly has type 'any... Remove this comment to see the full error message
const ExpandedInvoiceStatsRow = ({ data, index, isGlobal, siteId }: Props) => {
    const [singleSiteData, setSingleSiteData] = useState([])
    const [loading, setLoading] = useState(!isGlobal)

    useEffect(() => {
        if (!singleSiteData.length && !isGlobal) {
            const getSiteInvoiceDetails = async () => {
                const singleData = await api.payments.invoices.getInvoiceStatDetails([siteId])

                if (singleData && Object.keys(singleData).length) {
                    setSingleSiteData(Object.values(singleData))
                }
                setLoading(false)
            }

            getSiteInvoiceDetails()
        }
    }, [singleSiteData.length, isGlobal, siteId])

    const makeUI = () => {
        if (loading) {
            return <div className={styles.placeholderText}>Loading...</div>
        }

        if (isGlobal) {
            return data.map(({ status, invoices, amount }: any, i: any) => (
                <div key={status}>
                    {i === 0 && (
                        <div className={`${styles.statusRow} ${styles.headers}`}>
                            <strong>Status</strong>
                            <strong>Invoices</strong>
                            <strong>Amount</strong>
                        </div>
                    )}
                    <div className={styles.statusRow}>
                        <span>{status}</span>
                        <span>{invoices}</span>
                        <span>
                            {new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(amount)}
                        </span>
                    </div>
                </div>
            ))
        }

        if (!singleSiteData.length) {
            return <div className={styles.placeholderText}>No invoices found!</div>
        }

        return Object.keys(singleSiteData[0])
            .reverse() // Most recent first
            .map((date) =>
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'map' does not exist on type 'never'.
                singleSiteData[0][date].map(({ status, invoices, amount }: any, i: any) => (
                    // eslint-disable-next-line
                    <div key={`${date}${i}`}>
                        {i === 0 && (
                            <>
                                <time dateTime={date} className={styles.dateHeader}>
                                    <strong>{date}</strong>
                                </time>
                                <div className={`${styles.dateStatusRow} ${styles.headers}`}>
                                    <strong>Status</strong>
                                    <strong>Invoices</strong>
                                    <strong>Amount</strong>
                                </div>
                            </>
                        )}
                        <div className={styles.dateStatusRow}>
                            <span>{status}</span>
                            <span>{invoices}</span>
                            <span>
                                {new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(
                                    amount,
                                )}
                            </span>
                        </div>
                    </div>
                )),
            )
    }

    return (
        <section
            className={`${styles.expandedSection} ${!isGlobal && !loading && styles.siteExpandedSection}`}
            style={{
                background: index % 2 === 0 ? 'rgba(0, 0, 0, 0.03)' : 'white',
            }}
        >
            {makeUI()}
        </section>
    )
}

ExpandedInvoiceStatsRow.defaultProps = {
    isGlobal: false,
    siteId: null,
}

export default ExpandedInvoiceStatsRow
