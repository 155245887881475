import { toast } from 'react-toastify'
import { stringify } from 'qs'

import { setInvoiceData, setDetailsData, clearData, setInvoiceDataLoading } from '../../state/invoiceState'
import paymentsHttp from '../../ManagePayments/utils/paymentsHttp'
import { handleError } from '../../lib/utils'

export const getAll = ({
    site = null,
    date = null,
    status = 'all',
    vertical = 'all',
    before = null,
    invoiceType = 'all',
}: any = {}) => {
    setInvoiceDataLoading(true)
    clearData()
    const params = {} as ObjectType

    if (site) params.site = site
    if (date) params.date = date
    if (vertical) params.vertical = vertical
    if (invoiceType) params.type = invoiceType
    if (before) params.before = before

    params.status = status === 'all' ? null : status.toLowerCase()

    return paymentsHttp
        .get('/invoices', { params })
        .then(({ data }) => {
            let sortedData = []
            if (data?.length) {
                sortedData = data.sort((a: any, b: any) => (b.name.toLowerCase() <= a.name.toLowerCase() ? 1 : -1))
            }

            setInvoiceData(sortedData)
        })
        .catch((e) => handleError(e, setInvoiceDataLoading))
}

export const createInvoice = (
    {
        type,
        name,
        username,
        payAmount,
        comment,
        status,
        siteId,
        vertical,
        date,
        publishCount,
        sitePublishCount,
        views,
        siteViews,
    }: any,
    setSubmitting: any,
) =>
    paymentsHttp
        .post('/invoices', {
            type,
            name,
            ...(username && { username }),
            payAmount,
            comment,
            status,
            siteId,
            vertical,
            ...(date && { date }),
            // Allow users to input commas, but strip them out here
            ...(publishCount && { publishCount: publishCount.replace(/,/g, '') }),
            ...(sitePublishCount && { sitePublishCount: sitePublishCount.replace(/,/g, '') }),
            ...(views && { views: views.replace(/,/g, '') }),
            ...(siteViews && { siteViews: siteViews.replace(/,/g, '') }),
        })
        .then(({ data }) => {
            setSubmitting(false)
            toast.success('Invoice created!')
            return data
        })
        .catch((e) => handleError(e, setSubmitting, true))

export const getInvoiceDetails = (id: any) =>
    paymentsHttp
        .get(`/invoices/${id}`)
        .then(({ data }) => setDetailsData(data))
        .catch(handleError)

export const updateInvoice = (id: any, { status = null, override = null } = {}) => {
    const updates = {}

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'status' does not exist on type '{}'.
    if (status !== null) updates.status = status
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'override' does not exist on type '{}'.
    if (override !== null) updates.override = override

    paymentsHttp.put(`/invoices/${id}`, updates).catch(handleError)
}

export const bulkUpdateStatus = (invoiceIds: any, status: any) =>
    paymentsHttp
        .post('/invoices/bulkUpdate', {
            invoiceIds,
            status,
        })
        .catch(handleError)

export const createComment = (invoiceId: any, message: any, type = 'user') =>
    paymentsHttp
        .post('/comments', {
            invoiceId,
            message,
            type,
        })
        .catch((e) => handleError(e, null, true))

/**
 * Invoice Stats Endpoints
 */

/**
 * Gets stats for all invoices per month
 * @returns {object}
 */
export const getGlobalStats = () =>
    paymentsHttp
        .get('/invoices/stats/global')
        .then(({ data }) => data)
        .catch(handleError)

/**
 * Gets stats for an array of sites per month
 *
 * @param {array} siteIds
 * @returns {object}
 */
export const getStatsBySite = (siteIds: any) =>
    paymentsHttp
        .get('/invoices/stats/sites', {
            params: {
                sites: siteIds,
            },
            paramsSerializer: (params) => stringify(params),
        })
        .then(({ data }) => data)
        .catch(handleError)

/**
 * Gets stats for an individual sites per month
 *
 * @param {array} siteIds
 * @returns {object}
 */
export const getInvoiceStatDetails = (siteIds: any) =>
    paymentsHttp
        .get('/invoices/stats/sitesDetailed', {
            params: {
                sites: siteIds,
            },
            paramsSerializer: (params) => stringify(params),
        })
        .then(({ data }) => data)
        .catch(handleError)

export const submitForPayment = () => {
    paymentsHttp
        .post('/invoices/setToExport')
        .then(() => toast.success('Invoices set to export!'))
        .catch(handleError)
}
