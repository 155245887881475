import { CAN_VIEW_USERS, CAN_MANAGE_CONTRACTORS } from '../../../permissions'

export const usersNavItems = [
    {
        label: 'Manage Users',
        path: 'manage',
        permission: CAN_VIEW_USERS,
    },
    {
        label: 'Manage Contractors',
        path: 'contractors',
        permission: CAN_MANAGE_CONTRACTORS,
    },
]
