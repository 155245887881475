export const rolesWithBlogIds = (roles: UserRoles, voltaxSitesList: VoltaxSiteObject[]) =>
    roles.map((role: UserRole) => {
        const voltaxSite = voltaxSitesList.filter((site: VoltaxSiteObject) => site.voltaxId === role.siteId)

        return { ...role, siteId: voltaxSite.length ? voltaxSite[0].id : role.siteId }
    })

export const getVoltaxFirstId = (siteId: string, voltaxSitesList: VoltaxSiteObject[]) => {
    const voltaxSites = voltaxSitesList.filter((site: VoltaxSiteObject) => site.id === siteId && site.voltaxId)

    if (!voltaxSites.length) return []

    return [voltaxSites[0].voltaxId]
}
