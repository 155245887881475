import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import api from '../../../api'

const useRoleList = (): [IRoleList, boolean] => {
    const roleList = useSelector<ObjectType, IRoleList>(({ globalState }) => globalState.roleList.data)
    const rolesLoading = useSelector<ObjectType, boolean>(({ globalState }) => globalState.roleList.isLoading)

    const [list, setList] = useState<IRoleList>(roleList)

    useEffect(() => {
        if (!roleList.length && !rolesLoading) {
            const cb = async () => {
                const r = await api.global.getRoleList()
                setList(r)
            }
            cb()
        }
    }, [roleList.length, rolesLoading])

    return [list, rolesLoading]
}

export default useRoleList
