// * Polyfill needed with CRA v2 for ie11 support
// * c.f. https://github.com/facebook/create-react-app/issues/6924#issuecomment-487313679
import 'react-app-polyfill/ie11' // eslint-disable-line
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import * as serviceWorker from './serviceWorker'

import './lib/styles/index.module.scss'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register()
serviceWorker.unregister()
