import getRoleFromSlug from './getRoleFromSlug'

/**
 *  Destructure result into primary & secondary arrays like so:
 *  const [primary, secondary] = splitRolesIntoPrimaryAndSecondary(roles)
 *
 * @param {array} roles
 * @param {array} roleList
 * @returns {array} array of two arrays
 */
interface Args {
    roles: UserRoles
    roleList: IRoleList
}

const splitRolesIntoPrimaryAndSecondary = ({ roles, roleList }: Args) =>
    roles.reduce(
        (acc: any, slug: any) => {
            const { type } = getRoleFromSlug({ slug, roleList, type: 'all' })

            if (!type) return acc

            if (type === 'secondary') return [acc[0], [...acc[1], slug]]
            if (type === 'primary') return [[...acc[0], slug], acc[1]]

            return acc
        },
        [[], []],
    )

export default splitRolesIntoPrimaryAndSecondary
