import { useRef, useEffect } from 'react'
import isEqual from 'lodash/isEqual'
import usePrevious from './usePrevious'

const useDeepCompareEffect = (callback: any, inputs: any) => {
    const previousInputs = usePrevious(inputs)
    const inputsAreEqual = isEqual(inputs, previousInputs)
    // every time our effect callback runs, the cleanup
    // function for the effect will change. If we don't
    // actually call the callback, then the user's cleanup
    // function wont be updated. So we'll keep track of the
    // most recent cleanup function and return that if
    // we don't actually call the callback.
    const cleanupRef = useRef()
    useEffect(
        () => {
            if (!inputsAreEqual) {
                cleanupRef.current = callback()
            }
            return cleanupRef.current
        },
        // run the effect callback if equality changes
        // this can change from [true] to [false]
        // or from [false] to [true]
        // both changes will cause the callback to re-run
        // which is why we have the `if (!inputsAreEqual)`
        // condition above.
        [callback, inputsAreEqual],
    )
}

export default useDeepCompareEffect
