import { applyMiddleware, createStore, compose } from 'redux'
import LogRocket from 'logrocket'
import { isBefore, parseISO, addHours } from 'date-fns'

import reducers from './state'
import { initialState as globalInitialState } from './state/globalState'

const loadState = () => {
    try {
        const serializedState = localStorage.getItem('user_state')
        if (serializedState === null) {
            return {}
        }

        const parsedState = JSON.parse(serializedState)
        const { expiresAt = '' } = parsedState
        const expired = isBefore(parseISO(expiresAt), Date.now())

        if (expired || !expiresAt) {
            return {}
        }

        return parsedState
    } catch {
        return undefined
    }
}

const saveState = (state: any) => {
    const expiresAt = Object.keys(state).length ? addHours(new Date(), 12) : undefined

    try {
        const serializedState = JSON.stringify({ ...state, expiresAt })
        localStorage.setItem('user_state', serializedState)
    } catch {
        // ignore write errors
    }
}

const preloadedState = {
    globalState: {
        ...globalInitialState,
        currentUser: {
            ...globalInitialState.currentUser,
            data: loadState(),
        },
    },
}

const useDevToolsInDev =
    // process.env.NODE_ENV === 'development' ?
    compose(
        // for multiple enhancers
        // @ts-expect-error ts-migrate(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION__' does not e... Remove this comment to see the full error message
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }) : (f) => f, // eslint-disable-line
        applyMiddleware(LogRocket.reduxMiddleware()),
    )
// : f => f

// @ts-expect-error ts-migrate(2345) FIXME: Type 'unknown' is not assignable to type 'StoreEnh... Remove this comment to see the full error message
const store = createStore(reducers, preloadedState, useDevToolsInDev)

store.subscribe(() => {
    saveState(store.getState().globalState.currentUser?.data || {})
})

export default store
