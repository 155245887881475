import React from 'react'
import cx from 'classnames'
import FocusTrap from 'focus-trap-react'
import { toast } from 'react-toastify'
import api from '../../../api'
import { Button } from '..'
import styles from './imageUpload.module.scss'

interface ImageUploadModalTypes {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
    triggerInputFile: () => void
    id?: string
}

export const ImageUploadModal = ({ isOpen, setIsOpen, triggerInputFile, id }: ImageUploadModalTypes) => {
    const handleRemovePhoto = async () => {
        if (!id) throw new Error('Removing photo failed!')

        toast.info('Removing photo...')

        await api.users.updateUserDetails(
            id,
            {
                profilePic: 'https://app.fansided.com/media/default-avatar.png',
            },
            true,
        )

        setIsOpen(false)
    }

    return (
        <FocusTrap
            active={isOpen}
            focusTrapOptions={{ onDeactivate: () => setIsOpen(false), clickOutsideDeactivates: true }}
        >
            <div className={cx(styles.modalContainer, !isOpen && styles.hidden)}>
                <Button type="button" className={styles.button} onClick={triggerInputFile}>
                    Upload New Photo
                </Button>
                <Button type="button" className={styles.button} onClick={handleRemovePhoto}>
                    Remove Current Photo
                </Button>
            </div>
        </FocusTrap>
    )
}
