import React from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

import { BuildNavItems, navItems } from '../../utils'
import styles from './dashboard.module.scss'

const Dashboard = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'globalState' does not exist on type 'Def... Remove this comment to see the full error message
    const { deletedAt } = useSelector(({ globalState }) => globalState.currentUser?.data || {})

    const dashboardCardItems = deletedAt ? navItems.filter((el) => el.label === 'New Proposal') : navItems

    return (
        <div className={styles.background}>
            <Helmet>
                <title>FS App Console</title>
            </Helmet>
            <div className={styles.dashboardItemsContainer}>
                <BuildNavItems
                    navItems={dashboardCardItems}
                    className={styles.dashboardItem}
                    textClass={styles.label}
                    iconSize={1.5}
                />
            </div>
        </div>
    )
}

export default Dashboard
