import React from 'react'

import styles from './analyticsHeader.module.scss'

const PaymentsHeader = ({ children }: any) => {
    return (
        <header className={styles.container}>
            <div className={`${styles.inner} horizontal-owl-extra-large`}>{children}</div>
        </header>
    )
}

export default PaymentsHeader
