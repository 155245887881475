export const pageviewsByTier = (allViews: any) =>
    allViews.reduce(
        (a: any, d: any) => {
            a.tier1 = d.geoTier === 1 ? a.tier1 + d.views : a.tier1
            a.tier2 = d.geoTier === 2 ? a.tier2 + d.views : a.tier2
            a.tier3 = d.geoTier === 3 ? a.tier3 + d.views : a.tier3
            a.tier4 = d.geoTier === 4 ? a.tier4 + d.views : a.tier4

            return a
        },
        { tier1: 0, tier2: 0, tier3: 0, tier4: 0 },
    )
