/**
 *
 *
 * @param {string} slug
 * @param {array} roleList Global roleList
 * @param {('secondary'|'primary'|'all')} [type='secondary'] Role type to filter
 * @returns {object} role or empty object
 */
interface Args {
    slug: string
    roleList: IRoleList
    type: RoleType | 'all'
}

const getRoleFromSlug = ({ slug, roleList, type = 'secondary' }: Args) => {
    const [role] = roleList.filter((role: any) => slug === role.slug && (type === role.type || type === 'all'))

    return role || {}
}

export default getRoleFromSlug
