import { useCallback } from 'react'

import api from '../../../../api'

const useManageContractorsUpdates = () => {
    const updateMeta = useCallback((newUserData: ObjectType[], userId: string, setUserData: (a: any) => void) => {
        setUserData(newUserData)

        const sitesMeta: ObjectType[] = newUserData.find(({ id }: any) => id === userId)?.sitesMeta
        api.users.updateUserPaymentMeta({ userId, sitesMeta })
    }, [])

    const handleContractorCheck = useCallback(
        (userId: string, siteId: string, userData: ObjectType[], setUserData: (a: any) => void) => {
            const newUserData = userData.map((user) => {
                if (user.id !== userId) {
                    return user
                }

                return {
                    ...user,
                    sitesMeta: user.sitesMeta.map((site: ObjectType) =>
                        site.id !== siteId
                            ? site
                            : {
                                  ...site,
                                  contractor: !site.contractor,
                                  ...(site.contractor && { revShare: 'none' }),
                              },
                    ),
                }
            })

            updateMeta(newUserData, userId, setUserData)
        },
        [updateMeta],
    )

    const handleRevShareChange = useCallback(
        (userId, siteId, revShare, userData, setUserData) => {
            const newUserData = userData.map((user: ObjectType) => {
                if (user.id !== userId) {
                    return user
                }

                return {
                    ...user,
                    sitesMeta: user.sitesMeta.map((site: ObjectType) =>
                        site.id !== siteId ? site : { ...site, revShare },
                    ),
                }
            })

            updateMeta(newUserData, userId, setUserData)
        },
        [updateMeta],
    )

    return { handleContractorCheck, handleRevShareChange }
}

export default useManageContractorsUpdates
