/* eslint-disable jsx-a11y/label-has-for */

import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { Field, connect } from 'formik'

import ErrorComponent from '../InputUtils/ErrorComponent'
import styles from '../input.module.scss'

type Props = {
    autoComplete?: string
    formik?: any
    containerClassName?: string
    className?: string
    wrapperClassName?: string
    labelClassName?: string
    noLabelFocusAnimation?: boolean
    errorClassName?: string
    hintClassName?: string
    disabled?: boolean
    hint?: string
    id?: string
    label?: string
    placeholder?: string
    name: string
    required?: boolean
    hidden?: boolean
    onFocus?: ((...args: any[]) => any) | null
    onBlur?: ((...args: any[]) => any) | null
    onChange?: ((...args: any[]) => any) | null
    type?:
        | 'text'
        | 'color'
        | 'email'
        | 'hidden'
        | 'image'
        | 'number'
        | 'password'
        | 'range'
        | 'search'
        | 'tel'
        | 'url'
        | 'textarea'
    component?: ((...args: any[]) => any) | any
    showErrorsInPopover?: boolean
}

const InputFormik: React.FC<Props> = (props) => {
    const {
        autoComplete,
        formik: { values, errors },
        name,
        containerClassName,
        wrapperClassName,
        labelClassName,
        noLabelFocusAnimation,
        className,
        errorClassName,
        hintClassName,
        disabled,
        hint,
        id,
        label,
        required,
        placeholder,
        type,
        hidden,
        onChange,
        onFocus: handleOnFocus,
        onBlur: handleOnBlur,
        component: Component,
        showErrorsInPopover,
        ...rest
    } = props

    const [active, setActive] = useState(values[name])
    const [focused, setFocused] = useState(false)
    const value = values[name]
    const error = errors[name]

    useEffect(() => {
        value ? setActive(true) : setActive(false)
    }, [value])

    const onFocus = () => {
        setActive(true)
        setFocused(true)
        handleOnFocus && handleOnFocus()
    }

    const onBlur = (e: any) => {
        !e.target.value && setActive(false)
        setFocused(false)
        handleOnBlur && handleOnBlur()
    }

    const InputComponent = type === 'textarea' ? 'textarea' : 'input'

    return (
        <div
            className={cx(
                styles.formElement,
                // prevent label moving down if placeholder
                !noLabelFocusAnimation && (placeholder || active) && styles.active,
                hidden && styles.hidden,
                containerClassName,
            )}
        >
            {label && (
                <label
                    htmlFor={id || name}
                    className={cx(
                        labelClassName,
                        styles.label,
                        focused && styles.labelFocused,
                        disabled && styles.labelDisabled,
                        type === 'textarea' && styles.label__textarea,
                    )}
                >
                    {label}
                    {required ? <sup> *</sup> : ''}
                </label>
            )}
            <div
                className={cx(
                    styles.inputWrapper,
                    focused && type !== 'textarea' && styles.inputFocused,
                    wrapperClassName && wrapperClassName,
                )}
            >
                {/* /**
                 *  If provided, render component hooked up to formik
                 */}
                {Component ? (
                    <>
                        <Component {...props} />
                        {/* @ts-ignore */}
                        <ErrorComponent
                            focused={focused}
                            error={error}
                            errorClassName={errorClassName}
                            showErrorsInPopover={showErrorsInPopover}
                        />
                    </>
                ) : (
                    <Field name={name} {...rest}>
                        {({ field: { onBlur: formikOnBlur, ...field }, form: { touched } }: any) => {
                            const handleOnBlur = (e: any) => {
                                formikOnBlur(e)
                                onBlur(e)
                            }

                            return (
                                <>
                                    <InputComponent
                                        className={cx(
                                            className,
                                            styles.input,
                                            type === 'textarea' && styles.textarea,

                                            {
                                                [styles.hasError]: !!(touched[field.name] && error),
                                            },
                                        )}
                                        id={id || name}
                                        placeholder={placeholder}
                                        type={type}
                                        disabled={disabled}
                                        required={required}
                                        onBlur={handleOnBlur}
                                        onFocus={onFocus}
                                        {...field}
                                        {...rest}
                                    />
                                    {/* @ts-ignore */}
                                    <ErrorComponent
                                        focused={focused}
                                        error={error}
                                        errorClassName={errorClassName}
                                        showErrorsInPopover={showErrorsInPopover}
                                    />
                                    {hint && (
                                        <div className={cx(hintClassName && hintClassName)}>
                                            <em>{hint}</em>
                                        </div>
                                    )}
                                </>
                            )
                        }}
                    </Field>
                )}
            </div>
        </div>
    )
}

InputFormik.defaultProps = {
    containerClassName: '',
    wrapperClassName: '',
    className: '',
    labelClassName: '',
    noLabelFocusAnimation: false,
    errorClassName: '',
    hintClassName: '',
    disabled: false,
    hint: '',
    id: '',
    label: '',
    placeholder: '',
    required: false,
    hidden: false,
    onFocus: null,
    onBlur: null,
    type: 'text',
    component: null,
    showErrorsInPopover: false,
}

export default connect(InputFormik)
