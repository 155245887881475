import { createReducer, createAction } from 'redux-act'

import store from '../store'
import { getSiteIdsFromRoles, rolesWithBlogIds } from '../lib/utils'
import {
    makeAsyncAction,
    makeAsyncReducer,
    makeAsyncStateProperty,
    initialUserDataShape,
} from '../lib/utils/storeUtils'

const actions = {
    ...makeAsyncAction('SET_USERS'),
    ...makeAsyncAction('SET_SINGLE_USER'),
    setSelection: createAction('SET_SELECTION'),
    setPaginationDetails: createAction('SET_PAGINATION_DETAILS'),
    setCurrentUsersTableUrl: createAction('SET_CURRENT_USERS_TABLE_URL'),
}

const initialState = {
    ...makeAsyncStateProperty({ key: 'usersData', initialData: [] }),
    ...makeAsyncStateProperty({
        key: 'singleUserData',
        initialData: initialUserDataShape,
    }),
    selection: [],
    selectAll: false,
    paginationDetails: {},
    currentUsersTableUrl: '/users/manage?desc=true&sortBy=createdAt',
}

export default createReducer(
    {
        ...makeAsyncReducer({ actions, actionKey: 'setUsers', stateKey: 'usersData' }),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'setSingleUser' does not exist on type '{... Remove this comment to see the full error message
        [actions.setSingleUser]: (state, singleUserData) => ({
            ...state,
            singleUserData: {
                ...state.singleUserData,
                data: { ...singleUserData },
            },
        }),
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'setSingleUserLoading' does not exist on ... Remove this comment to see the full error message
        [actions.setSingleUserLoading]: (state, isLoading) => ({
            ...state,
            singleUserData: {
                ...state.singleUserData,
                isLoading,
            },
        }),
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [actions.setSelection]: (state, selection) => ({
            ...state,
            ...selection,
        }),
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [actions.setPaginationDetails]: (state, paginationDetails) => ({
            ...state,
            paginationDetails,
        }),
        // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [actions.setCurrentUsersTableUrl]: (state, currentUsersTableUrl) => ({
            ...state,
            currentUsersTableUrl,
        }),
    },
    initialState,
)

/**
 * Setters
 */

// @ts-expect-error ts-migrate(2339) FIXME: Property 'setUsersLoading' does not exist on type ... Remove this comment to see the full error message
export const setUsersLoading = (bool: any) => store.dispatch(actions.setUsersLoading(bool))

export const setUsers = (data = [], paginationDetails = {}) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setUsers' does not exist on type '{ setS... Remove this comment to see the full error message
    store.dispatch(actions.setUsers(data))
    if (Object.keys(paginationDetails).length !== 0) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        store.dispatch(actions.setPaginationDetails(paginationDetails))
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setUsersLoading' does not exist on type ... Remove this comment to see the full error message
    store.dispatch(actions.setUsersLoading(false))
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'setSingleUserLoading' does not exist on ... Remove this comment to see the full error message
export const setSingleUserLoading = (bool: any) => store.dispatch(actions.setSingleUserLoading(bool))

export const setSingleUser = (data: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setSingleUser' does not exist on type '{... Remove this comment to see the full error message
    store.dispatch(actions.setSingleUser(data))
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setSingleUserLoading' does not exist on ... Remove this comment to see the full error message
    store.dispatch(actions.setSingleUserLoading(false))
}

export const setCurrentUsersTableUrl = (currentUsersTableUrl: any) =>
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    store.dispatch(actions.setCurrentUsersTableUrl(currentUsersTableUrl))

export const updateUser = (id: any, updates: any) => {
    const { deletedAt, roles } = updates
    // Use null to clear deletedAt value
    const clearDeleted = deletedAt === null
    const state = store.getState()
    const userRolesWithBlogIds = roles && rolesWithBlogIds(roles, state.globalState.voltaxSitesList.data)

    if (Object.values(state.userState.singleUserData.data).length) {
        const { data: oldData } = state.userState.singleUserData
        if (oldData.id === id) {
            const data = {
                ...oldData,
                // update the array of unique site ids
                ...(roles && { roles: userRolesWithBlogIds, sites: getSiteIdsFromRoles(userRolesWithBlogIds) }),
                ...(deletedAt && { deletedAt }),
                ...(clearDeleted && { deletedAt: null }),
                ...updates,
            }

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'setSingleUser' does not exist on type '{... Remove this comment to see the full error message
            store.dispatch(actions.setSingleUser(data))
        }
    }

    if (state.userState.usersData.data.length) {
        const data = state.userState.usersData.data.map((oldData: any) =>
            oldData.id === id
                ? {
                      ...oldData,
                      // update the array of unique site ids
                      ...(roles && { roles, sites: getSiteIdsFromRoles(roles) }),
                      ...(deletedAt && { deletedAt }),
                      ...(clearDeleted && { deletedAt: null }),
                      ...updates,
                  }
                : oldData,
        )
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'setUsers' does not exist on type '{ setS... Remove this comment to see the full error message
        store.dispatch(actions.setUsers(data))
    }
}

// @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
export const setSelection = (selection: any) => store.dispatch(actions.setSelection(selection))
