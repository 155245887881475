import React from 'react'
import { Routes, Route } from 'react-router-dom'

import DashboardHome from './layouts/DashboardHome'
import CreateInvoice from './layouts/CreateInvoice'
import InvoiceStats from './layouts/InvoiceStats'
import FlatRate from './layouts/FlatRate'
import NotFound from '../lib/layouts/NotFound'

const ManagePaymentsRouter = () => (
    <Routes>
        <Route path="/" element={<DashboardHome />} />

        {/* Other "authorized" pages can go here */}
        <Route path="stats/*" element={<InvoiceStats />} />
        <Route path="invoice" element={<CreateInvoice />} />
        <Route path="flatrate" element={<FlatRate />} />

        {/* 404 Routing */}
        <Route path="*" element={<NotFound />} />
    </Routes>
)

export default ManagePaymentsRouter
