import React from 'react'
import Helmet from 'react-helmet'

type GAPageViewTypes = {
    userId?: string
    rolesMeta?: string[]
    hasVoltaxRole?: boolean
    pageIdentifier: string
}

const GAPageView = ({ userId, rolesMeta, hasVoltaxRole, pageIdentifier }: GAPageViewTypes) => {
    let roles = rolesMeta?.toString()
    const gaConfig = {
        pageIdentifier,
        userId,
        userRole: roles,
        hasVoltaxRole,
    }

    return (
        <Helmet>
            <script>
                {`
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(${JSON.stringify(gaConfig)});
                `}
            </script>
            <script>
                {`
                <!-- Google Tag Manager -->
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-N3LH97W');
                <!-- End Google Tag Manager -->
                `}
            </script>
        </Helmet>
    )
}

export default GAPageView
