import React from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import PaymentsStatsHeader from '../../../ui/AnalyticsHeader/AnalyticsHeader'

import Image from '../../../../lib/framework/Image'
import { imageService } from '../../../../lib/utils'
import styles from '../../../ui/AnalyticsHeader/analyticsHeader.module.scss'

type OwnUserStatsHeaderProps = {
    currentUserDisplayName?: string
    currentUserProfileImg?: string
    selectedUserDisplayName?: string
    selectedUserProfileImg?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'UserStatsHeaderProps' circularly refer... Remove this comment to see the full error message
type UserStatsHeaderProps = OwnUserStatsHeaderProps & typeof UserStatsHeader.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'UserStatsHeader' implicitly has type 'any' becaus... Remove this comment to see the full error message
const UserStatsHeader = ({
    selectedUserDisplayName,
    selectedUserProfileImg,
    currentUserDisplayName,
    currentUserProfileImg,
}: UserStatsHeaderProps) => {
    const { userId: pathUserId } = useParams()

    const displayName = pathUserId ? selectedUserDisplayName : currentUserDisplayName
    const profileImg = pathUserId ? selectedUserProfileImg : currentUserProfileImg

    return (
        <PaymentsStatsHeader>
            <div className={styles.usersStatsHeaderContainer}>
                <div>
                    <Image
                        src={imageService(profileImg, 45, 45)}
                        placeholder={imageService(profileImg, 16, 16)}
                        alt="Profile"
                        width={45}
                        height={45}
                    />
                </div>
            </div>
            <div className={styles.headerName}>{displayName}</div>
        </PaymentsStatsHeader>
    )
}

UserStatsHeader.defaultProps = {
    currentUserDisplayName: '',
    currentUserProfileImg: '',
    selectedUserDisplayName: '',
    selectedUserProfileImg: '',
}

const stateToProps = ({ globalState, userState }: any) => ({
    currentUserDisplayName: globalState.currentUser?.data?.displayName,
    currentUserProfileImg: globalState.currentUser?.data?.profilePic,
    selectedUserDisplayName: userState.singleUserData?.data?.fullName,
    selectedUserProfileImg: userState.singleUserData?.data?.profilePic,
})

export default connect(stateToProps)(UserStatsHeader)
