import React from 'react'
import { Button } from '../../framework'
import { customToast } from '.'

import styles from './customToast.module.scss'

interface ClearProfileTypes {
    closeToast?: (...args: any[]) => any
    handleClear: () => void
}

/**
 *
 * @param {object} props All our props.
 * @param {func} props.closeToast Func supplied by react-toastify.
 * @param {func} props.handleClear Custom func to update user profile.
 * @returns {node}
 */
const ClearProfile = ({ closeToast, handleClear }: ClearProfileTypes) => (
    <div className={styles.modal}>
        <h4>
            Clearing your profile 1) sets your profile photo to a default image and 2) deletes your bio and social
            media handles.
        </h4>
        <div className={styles.buttonContainer}>
            <Button type="danger" onClick={handleClear} className={styles.buttonYes}>
                Yes, clear
            </Button>
            <Button type="primary" onClick={closeToast} className={styles.buttonNo}>
                No, close
            </Button>
        </div>
    </div>
)

const customClearProfileToast = (props: any) =>
    customToast(
        { Component: ClearProfile, ...props },
        {
            autoClose: false,
            className: styles.deleteSite,
        },
    )

export default customClearProfileToast
