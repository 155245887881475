import React from 'react'
import { useSpring, animated as a } from 'react-spring'

import styles from './flipRow.module.scss'

type OwnProps = {
    height?: number
    flipped: boolean
    front: React.ReactElement | ((...args: any[]) => any)
    back: React.ReactElement | ((...args: any[]) => any)
    className?: string
    rowSideClassName?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof FlipRow.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'FlipRow' implicitly has type 'any' because it doe... Remove this comment to see the full error message
const FlipRow = ({ flipped, front: Front, back: Back, className, rowSideClassName, height }: Props) => {
    // @ts-expect-error ts-migrate(2769) FIXME: Object literal may only specify known properties, ... Remove this comment to see the full error message
    const { transform, opacity, zIndex, visibility } = useSpring({
        opacity: flipped ? 1 : 0,
        zIndex: flipped ? 4 : -1,
        visibility: flipped ? 1 : 0,
        transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
        config: { mass: 3, tension: 1000, friction: 80 },
    })

    return (
        <div className={`${styles.flipRow} ${className && className}`} style={{ height }}>
            <a.div
                style={{
                    // @ts-expect-error ts-migrate(2363) FIXME: The right-hand side of an arithmetic operation mus... Remove this comment to see the full error message
                    opacity: opacity.interpolate((o) => 1 - o),
                    transform,
                    // @ts-expect-error ts-migrate(2363) FIXME: The right-hand side of an arithmetic operation mus... Remove this comment to see the full error message
                    zIndex: zIndex.interpolate((z) => 1 - z),
                    pointerEvents: flipped ? 'none' : 'all',
                    // @ts-expect-error ts-migrate(2363) FIXME: The right-hand side of an arithmetic operation mus... Remove this comment to see the full error message
                    visibility: visibility.interpolate((v) => (1 - v === 0 ? 'hidden' : 'visible')),
                }}
                className={`${styles.flipRowSide} ${rowSideClassName && rowSideClassName}`}
            >
                <Front />
            </a.div>

            <a.div
                style={{
                    opacity,
                    transform: transform.interpolate((t) => `${t} rotateX(180deg)`),
                    zIndex,
                    pointerEvents: flipped ? 'all' : 'none',
                    // @ts-expect-error ts-migrate(2363) FIXME: The right-hand side of an arithmetic operation mus... Remove this comment to see the full error message
                    visibility: visibility.interpolate((v) => (1 - v === 1 ? 'hidden' : 'visible')),
                }}
                className={`${styles.flipRowSide} ${rowSideClassName && rowSideClassName}`}
            >
                <Back />
            </a.div>
        </div>
    )
}

FlipRow.defaultProps = {
    className: '',
    rowSideClassName: '',
    height: 70,
}

export default FlipRow
