import React from 'react'

type OwnProps = {
    fill?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof FansidedLogo.defaultProps

/* eslint-disable max-len */

// @ts-expect-error ts-migrate(7022) FIXME: 'FansidedLogo' implicitly has type 'any' because i... Remove this comment to see the full error message
const FansidedLogo = ({ fill, ...props }: Props) => (
    <svg viewBox="0 0 495.42 329" width="24" height="24" fill={fill} {...props}>
        <path
            d="M284.37,149.91L293,127.18c5.19-13.28,10.44-26.49,15.22-40.18H135.73C91.8,197.18,45.5,305.1,2.29,416q53.1-3.18,107.36-5.2c19.43-45.78,36.4-94,56.56-139.09h63.52l23.83-62.91-62.79-.2c8-19.58,14.95-40.14,23.94-58.69h69.68Z"
            transform="translate(-2.29 -87)"
        />
        <path
            d="M495.79,118.17c-1.89-5.43-8.3-12.75-13.28-16.16-17.41-12-48.83-16.37-79.08-14.43-31.88,2-63.49,13.29-83.12,34.06-9.37,9.91-15.23,22.52-20.78,36.36-8.43,21-20.73,50.91-6.93,73.88,11.78,19.6,39.73,32.89,57.72,46.75,6.59,5.07,15.28,12.33,16.74,18.47,4.05,17.11-8.73,49.66-23.09,55.41-5.81,2.33-15.48,3.13-18.47-2.31-5-9.18,12.21-50.59,14.95-58.76H256.23c-6.23,24.17-18.33,43.77-17.86,69.73,0.66,36.74,39,46.06,74.46,46.18,40.86,0.13,81.25-10.88,107.36-36.36,12.63-12.33,20.44-27.68,26.55-45,6.27-17.78,13.4-39.55,11.54-60.6-2.05-23.36-23.55-37.23-39.83-48.48-6.5-4.5-13.92-9.67-21.93-15-6.49-4.33-17.51-11.26-19-18.47-2.58-12.14,3.59-25.43,8.08-32.9,2.83-4.71,18.36-20,27.13-9.81,4.67,5.44-.75,17.17-2.31,21.93-2.48,7.6-5.42,14.47-6.22,20.79h80.1C488.42,161.4,502.86,138.5,495.79,118.17Z"
            transform="translate(-2.29 -87)"
        />
    </svg>
)

FansidedLogo.defaultProps = {
    fill: '#fff',
}

export default FansidedLogo
