import axios from 'axios'
import { toast } from 'react-toastify'
import { stringify } from 'qs'
import { setUsers, setSingleUser, updateUser, setSingleUserLoading, setUsersLoading } from '../state/userState'
import { setCurrentUser } from '../state/globalState'
import {
    http,
    handleError,
    getSiteIdsFromRoles,
    makeRequest,
    getCurrentUserFromStore,
    rolesWithBlogIds,
    getVoltaxFirstId,
    getUserData,
} from '../lib/utils'
import { updateUserWithNewRoles } from './utils/updateUserWithNewRoles'
import store from '../store'

/**                                                  *
 * -------------- Single User Methods -------------- *
 *                                                   */

/**
 *
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} email
 * @param {func} setSubmitting
 */
export const addUser = async ({
    firstName,
    lastName,
    email,
    setSubmitting,
}: {
    firstName: string
    lastName: string
    email: string
    setSubmitting: Function
}) => {
    const res = await makeRequest({
        requestUrl: '/users',
        method: 'post',
        axiosInstance: http,
        requestConfig: {
            data: {
                firstName,
                lastName,
                email,
            },
        },
        errorFunc: (e: IError) => handleError(e, null, true),
        finallyFunc: () => setSubmitting(false),
    })

    // If we canceled, we don't want to do anything, so return
    if (!res || axios.isCancel(res)) return

    // If we're not sorted by createdAt & desc on page 1,
    // new user is probably not visible. Return for now.
    const { currentUsersTableUrl } = store.getState().userState

    if (
        (currentUsersTableUrl.includes('page=') && !currentUsersTableUrl.includes('page=1')) ||
        !currentUsersTableUrl.includes('desc=true') ||
        !currentUsersTableUrl.includes('sortBy=createdAt')
    ) {
        return
    }

    // Otherwise, add new user to store.
    const { user } = res

    if (!user) {
        throw new Error('Error adding new user')
    }

    const currentUsers = store.getState().userState.usersData.data

    const allUsersData = [user, ...currentUsers]

    // @ts-expect-error ts-migrate(2345) FIXME: Type 'any' is not assignable to type 'never'.
    setUsers(allUsersData)
}

/**
 * @param {string} userId Id of the user to be blocked
 */
export const blockUser = async ({ userId }: { userId: string }) => {
    try {
        const res = await http.patch(`/users/${userId}/block`)

        if (!res) throw new Error('Something went wrong!')

        updateUser(userId, { blocked: true })

        return res
    } catch (e) {
        return handleError(e)
    }
}

/**
 * @param {string} userId Id of the user to be unblocked
 */
export const unblockUser = async ({ userId }: { userId: string }) => {
    try {
        const res = await http.patch(`/users/${userId}/unblock`)

        if (!res) throw new Error('Something went wrong!')

        updateUser(userId, { blocked: false })

        return res
    } catch (e) {
        return handleError(e)
    }
}

/**
 *
 * @param {string} id
 */
export const getUser = async (id: string) => {
    try {
        setSingleUserLoading(true)

        const res = await http.get<any, ObjectType>(`/users/${id}`)

        if (!res) throw new Error('Something went wrong!')

        const { user, errors } = res
        const { roles } = user

        if (errors?.length) {
            errors.forEach((e: any) => {
                throw new Error(e.error)
            })
        }

        const voltaxSitesList = store.getState().globalState?.voltaxSitesList.data

        let siteIds = getSiteIdsFromRoles(roles)
        const voltaxSites = voltaxSitesList.filter((site: VoltaxSiteObject) => {
            return siteIds.includes(site.voltaxId)
        })

        let userRolesWithBlogIds: any = []
        if (voltaxSites.length) {
            siteIds = siteIds.flatMap((site) =>
                voltaxSites.map((voltaxSite: VoltaxSiteObject) => {
                    if (voltaxSite.voltaxId === site) {
                        return voltaxSite.id
                    }
                    return site
                }),
            )

            userRolesWithBlogIds = rolesWithBlogIds(roles, voltaxSitesList)
        }

        const modUser = {
            ...user,
            roles: userRolesWithBlogIds.length ? userRolesWithBlogIds : roles,
            sites: siteIds,
        }

        setSingleUser(modUser)
    } catch (e) {
        return handleError(e, setSingleUserLoading, true)
    }
}

/**
 *
 * @param {string} id
 * @param {string} redirect
 */
export const getPayoneerRegistrationLink = async (id: string, redirect: string) => {
    if (process.env.NODE_ENV === 'development') return ''

    try {
        const res = await http.get<any, ObjectType>(`/users/${id}/payoneerLink`, {
            params: {
                redirect,
            },
        })

        if (!res) throw new Error('Something went wrong!')

        const { link } = res

        return link
    } catch (e) {
        return handleError(e, null)
    }
}

/**
 *
 * Add one or many user roles of one type (primary|secondary) on one or many sites
 *
 * @param {object} params
 * @param {(string|number)} params.id
 * @param {string[]} params.roles
 * @param {string[]} params.sites
 * @param {func} [params.setSubmitting] takes bool - Formik func
 * @param {number} params.currentUserId
 */
export const addUserRoles = async (args: any) => {
    const { id, roles, sites, type = 'secondary', setSubmitting } = args

    if (type === 'primary' && roles.length > 1) throw new Error('A user can only have one primary role per site')

    const voltaxSitesList = store.getState().globalState?.voltaxSitesList.data
    const voltaxSlug = getVoltaxFirstId(sites[0], voltaxSitesList)

    const updates = {
        sites,
        roles,
        type,
        voltaxSlug,
    }

    try {
        const res = await http.post<typeof updates, ObjectType>(`/users/${id}/roles`, updates)

        const { roles: addedRoles, sitesMeta } = res

        await updateUserWithNewRoles({
            addedRoles,
            sitesMeta,
            type,
            voltaxSlug,
            voltaxSitesList,
            ...args,
        })

        toast.success(
            `Role${roles.length > 1 ? 's' : ''} added.${
                type === 'primary' ? ' Please confirm pay settings in User Profile' : ''
            }.`,
        )
    } catch (e) {
        return handleError(e, setSubmitting)
    }
}
/**
 *
 * Delete one or many user roles on a single site
 *
 * @param {object} params
 * @param {(string|number)} params.id
 * @param {string[]} params.roles
 * @param {string} params.siteId
 * @param {func} [params.setSubmitting] takes bool - Formik func
 * @param {number} params.currentUserId
 */
export const deleteUserRoles = async (args: any) => {
    const { id, roles, siteId, type = 'primary', setSubmitting } = args

    const voltaxSitesList = store.getState().globalState?.voltaxSitesList.data
    const voltaxSlug = getVoltaxFirstId(siteId, voltaxSitesList)

    const updates = {
        siteId,
        roles,
        voltaxSlug,
    }

    try {
        const res = await http.patch<typeof updates, ObjectType>(`/users/${id}/roles`, updates)

        const { roles: deletedRoles, sitesMeta } = res

        await updateUserWithNewRoles({
            deletedRoles,
            sitesMeta,
            sites: siteId,
            type,
            voltaxSlug,
            voltaxSitesList,
            ...args,
        })

        toast.success(
            `Role${roles.length > 1 ? 's' : ''} deleted${
                type === 'primary' ? ', along with payment setting on site' : ''
            }.`,
        )
    } catch (e) {
        return handleError(e, setSubmitting)
    }
}
/**
 *
 *
 * @param {string} id
 * @param {string} title
 * @param {string} siteId
 * @param {object[]} roles
 * @param {boolean} [noToast]
 */
export const updateUserTitle = async (
    id: string,
    title: string,
    siteId: string,
    roles: UserRoles,
    noToast?: boolean,
) => {
    try {
        const { titles } = getUserData(id)
        let updatedTitles = titles
        const voltaxSitesList = store.getState().globalState?.voltaxSitesList.data
        const voltaxSite = getVoltaxFirstId(siteId, voltaxSitesList)
        const newSiteId = voltaxSite.length ? voltaxSite[0] : siteId
        await http.patch(`/users/${id}/titles`, { title, siteId: newSiteId })

        const rolesWithUpdatedTitle = roles.map((role: any) =>
            role.siteId === siteId && role.type === 'primary' ? { ...role, title } : role,
        )

        if (!title) {
            delete updatedTitles[siteId]
        }
        if (title) {
            updatedTitles = { ...updatedTitles, [siteId]: title }
        }
        updateUser(id, { roles: rolesWithUpdatedTitle, titles: updatedTitles })

        if (!noToast) {
            toast.success(`Title successfully updated`)
        }
    } catch (e) {
        return handleError(e)
    }
}

/**
 *
 * @param {number} id id of user to update
 * @param {object} values updated values
 * @param {object} initialValues old values
 * @param {func} [setSubmitting] takes bool - Formik func
 */
export const updateUserDetails = async (
    id: string,
    values: ObjectType,
    showToast: boolean,
    initialValues?: ObjectType,
    setSubmitting?: ILoadingFunc,
) => {
    try {
        const updates = Object.keys(values).reduce((acc, key) => {
            if (initialValues && initialValues[key] === values[key]) return acc
            // handle dropdown fields
            if (['country', 'state'].includes(key)) {
                return {
                    ...acc,
                    [key]: values[key].value,
                }
            }

            return {
                ...acc,
                [key]: values[key],
            }
        }, {})

        const res = await http.patch<typeof updates, ObjectType>(`/users/${id}/update`, updates)

        if (!res) throw new Error('Something went wrong!')

        const { errors, ...newUpdates } = res

        if (errors?.length) {
            errors.forEach((e: any) => {
                throw new Error(e.error)
            })
        }

        if (setSubmitting) setSubmitting(false)

        updateUser(id, { updatedAt: new Date(), ...newUpdates })
        showToast && toast.success('Successfully updated!')
    } catch (e) {
        return handleError(e, setSubmitting)
    }
}

/**
 *
 * @param {(string|number)} userId
 * @param {object} values
 * @param {func} setSubmitting takes bool - Formik func
 */
export const updateUserPassword = async ({
    userId,
    values,
    setSubmitting,
}: {
    userId: string
    values: ObjectType
    setSubmitting: ILoadingFunc
}) => {
    const { newPassword: password } = values

    try {
        await http.patch<typeof values, ObjectType>(`/users/${userId}/update`, { password })

        if (setSubmitting) setSubmitting(false)
    } catch (e) {
        return handleError(e, setSubmitting, true)
    }
}

/**
 *
 * @param {file} image
 * @param {string} userId
 */
export const uploadImage = async (image: File, userId: string, setLoading: ILoadingFunc) => {
    try {
        setLoading(true)

        const formData = new FormData()
        formData.append('image', image)

        const res = await http.post<typeof formData, ObjectType>(`users/${userId}/imageUpload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })

        const { profilePic } = res

        updateUser(userId, { profilePic })

        const currentUserData = getCurrentUserFromStore()

        if (userId === currentUserData.id) {
            setCurrentUser({ ...currentUserData, profilePic })
        }

        toast.success('Successfully changed avatar!')
        setLoading(false)
    } catch (e) {
        return handleError(e, setLoading)
    }
}

/**
 * Updates user's payment meta
 * @param {string} userId
 * @param {array} sitesMeta
 */
export const updateUserPaymentMeta = async ({ userId, sitesMeta }: any) => {
    try {
        const res = await http.patch(`/users/${userId}/paymentsMeta`, { sitesMeta })

        toast.success('Successfully updated!', {
            toastId: 'updatePaymentsMeta',
        })

        return res
    } catch (e) {
        return handleError(e)
    }
}

/**                                                  *
 * -------------- Multi User Methods --------------  *
 *                                                   */

/**
 *
 * @param {number} [perPage=20] # of users to display per page
 * @param {number} [page=1] current page number
 * @param {object} options
 */
export const getUsers = async ({
    perPage = 20,
    page = 1,
    options,
}: {
    perPage?: number
    page?: number
    options: ObjectType
}) => {
    setUsersLoading(true)
    try {
        const res = await makeRequest({
            requestUrl: '/users',
            axiosInstance: http,
            requestConfig: {
                params: {
                    perPage,
                    page,
                    ...options,
                },
                paramsSerializer: (params: any) => stringify(params),
            },
            errorFunc: (e: IError) => handleError(e, setUsersLoading),
        })

        // If we canceled, we don't want to do anything, so return
        if (!res || axios.isCancel(res)) return

        const {
            users,
            meta: { ...paginationDetails },
        } = res

        if (!users || !users.length) {
            setUsers(users, paginationDetails)

            return toast.info('No Users Found')
        }

        setUsers(users, paginationDetails)
    } catch (e) {
        return handleError(e, setUsersLoading)
    }
}

/**
 *
 * @param {number} [perPage=20] # of users to display per page
 * @param {number} [page=1] current page number
 * @param {object} options
 */
export const getUsersWithPaymentMeta = async ({
    perPage = 20,
    page = 1,
    options,
}: {
    perPage: number
    page: number
    options: ObjectType
}) => {
    try {
        const res = await makeRequest({
            requestUrl: '/users/paymentsMeta',
            axiosInstance: http,
            requestConfig: {
                params: {
                    perPage,
                    page,
                    ...options,
                },
                paramsSerializer: (params: any) => stringify(params),
            },
            errorFunc: (e: IError) => handleError(e, setUsersLoading),
        })

        // If we canceled, we don't want to do anything, so return
        if (!res || axios.isCancel(res)) return

        return res
    } catch (e) {
        return handleError(e)
    }
}

/**
 * Get users that have roles site_admin or system_admin
 *
 * @param {array} usernames
 * @returns {object} response
 */
export const getSiteAndSystemAdmins = async (page = 1) => {
    const params = {
        perPage: 100,
        page,
        roles: ['site_admin', 'system_admin'],
        desc: '-1',
    }

    return http
        .get('/users/', {
            params,
        })
        .then((data) => {
            return data
        })
        .catch(handleError)
}

/**
 *
 * @param {object} options filtering state
 */
export const getUsersCsv = async (options: ObjectType) => {
    try {
        const {
            users,
            meta: { pages },
        } = await http.get<any, ObjectType>('/users', {
            params: {
                perPage: 100,
                page: 1,
                includePostCounts: 1,
                ...options,
            },
            paramsSerializer: (params) => stringify(params),
        })

        let totalUsers = users

        /**
         * Now that we've gotten the first 100 or fewer users,
         * we check if there are more pages.
         * If yes, we need to fetch the remainder.
         */
        if (pages > 1) {
            let usersBuffer: any = []

            for (let i = 2; i < pages + 1; i++) {
                const usersPromise = http.get('/users', {
                    params: {
                        perPage: 100,
                        page: i,
                        includePostCounts: 1,
                        ...options,
                    },
                    paramsSerializer: (params) => stringify(params),
                })

                usersBuffer = [...usersBuffer, usersPromise]
            }

            const responses = await Promise.all(usersBuffer)

            // @ts-expect-error ts-migrate(2769) FIXME: Type 'unknown' is not assignable to type '{ users:... Remove this comment to see the full error message
            totalUsers = responses.reduce((acc, { users }) => [...acc, ...users], totalUsers)
        }

        const stringifiedUsers = totalUsers.map((u: any) => ({
            ...u,
            roles: JSON.stringify(u.roles).replace(/,/g, ';'),
            sitesMeta: JSON.stringify(u.sitesMeta).replace(/,/g, ';'),
            titles: JSON.stringify(u.titles).replace(/,/g, ';'),
            wordpressMeta: JSON.stringify(u.wordpressMeta).replace(/,/g, ';'),
        }))

        return stringifiedUsers
    } catch (e) {
        return handleError(e)
    }
}

/**                                                  *
 * -------------- Role Methods -------------- *
 *                                                   */

/**
 * Get active users for site
 *
 * @param {string} siteId
 * @param {string} startDate
 * @param {string} endDate
 *
 * @returns {object} response
 */
export const getActiveUsers = async ({
    siteId,
    startDate,
    endDate,
}: {
    siteId: string
    startDate: string
    endDate: string
}) => {
    try {
        const { users } = await http.get<any, ObjectType>(`/users/activeUsers/${siteId}`, {
            params: {
                startDate,
                endDate,
            },
        })

        if (!users) throw new Error('Something went wrong!')

        return users
    } catch (e) {
        return handleError(e, null, false)
    }
}

/**
 * Get total post count on site for month
 *
 * @param {string} siteId
 * @param {string} date
 * @returns {object} response
 */
export const getSitePostCount = async ({ siteId, date }: { siteId: string; date: string }) => {
    try {
        const { postCount } = await http.get<any, ObjectType>(`/users/sitePostCount/${siteId}`, {
            params: {
                date,
            },
        })

        return postCount
    } catch (e) {
        return handleError(e, null, false)
    }
}
