import { useEffect } from 'react'

import { initialize } from './googleAnalytics'

const GAInit = () => {
    useEffect(() => {
        if (window.location.host.includes('app.') || window.location.host.includes('app-test.')) {
            initialize()
        }
    }, [])
    return null
}

export default GAInit
