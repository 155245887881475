import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

import { UsersDetailContext } from '../../UsersDetailContext'
import api from '../../../../../api'
import { customUserDeleteToast } from '../../../../../lib/utils'
import { Button, Frame } from '../../../../../lib/framework'

import styles from './additionalOptionsTab.module.scss'

const AdditionalOptionsTab = () => {
    const { userId, blocked, primaryNameToDisplay } = useContext(UsersDetailContext)

    const blockUserFunc = () => api.users.blockUser({ userId }).catch((e) => e)

    const handleUnblockUser = () => api.users.unblockUser({ userId }).catch((e) => e)

    const handleBlockUser = () =>
        customUserDeleteToast({
            name: primaryNameToDisplay,
            deleteUserFunc: blockUserFunc,
            isBlock: true,
        })

    return (
        <>
            <Helmet>
                <title>FS App Console - Additional Options</title>
            </Helmet>

            <Frame className={styles.frame} heading="Block">
                {blocked ? (
                    <div>
                        Blocked users can no longer access any FanSided sites, including this portal. Unblock to
                        allow access.
                    </div>
                ) : (
                    <div>
                        Block a user to suspend their access to all FanSided sites, including this portal. Note
                        that deactivation will not remove or unpublish any previously published content.
                    </div>
                )}

                <Button
                    type="danger"
                    onClick={blocked ? handleUnblockUser : handleBlockUser}
                    aria-label={blocked ? 'unblockUser' : 'blockUser'}
                >
                    {blocked ? 'Unblock User' : 'Block User'}
                </Button>
            </Frame>
        </>
    )
}

export default AdditionalOptionsTab
