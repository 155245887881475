import React from 'react'

type OwnProps = {
    fill?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof BackArrow.defaultProps
/* eslint-disable */
// @ts-expect-error ts-migrate(7022) FIXME: 'BackArrow' implicitly has type 'any' because it d... Remove this comment to see the full error message
const BackArrow = ({ fill, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 8 10" fill={fill} {...rest}>
        <polygon points="2.8,0.8 5.6,3.6 5.6,3.6 " />
        <polygon points="0,5 8,10 8,6.7 8,0 " />
    </svg>
)

BackArrow.defaultProps = {
    fill: '',
}

export default BackArrow
