import React from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/react-headroom` if it exis... Remove this comment to see the full error message
import Headroom from 'react-headroom'

import { Popover } from '../../../../framework'
import { CAN_AUDIT_INVOICES, CAN_MANAGE_USERS, CAN_VIEW_SITE_ANALYTICS } from '../../../../utils/permissions'
import {
    imageService,
    BuildNavItems,
    usersNavItems,
    managePaymentsNavItems,
    paymentsNavItems,
    isAuthorized,
    getProfilePic,
} from '../../../../utils'
import UserIconMenu from './ui/UserIconMenu'
import Image from '../../../../framework/Image'
import Icon from '../../../../framework/Icon'

import styles from './headerNav.module.scss'

type OwnHeaderNavProps = {
    title?: string
    profilePic?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'HeaderNavProps' circularly references ... Remove this comment to see the full error message
type HeaderNavProps = OwnHeaderNavProps & typeof HeaderNav.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'HeaderNav' implicitly has type 'any' because it d... Remove this comment to see the full error message
export const HeaderNav = ({ title, profilePic }: HeaderNavProps) => {
    const profileImg = getProfilePic(profilePic)

    const navItemsOptions = {
        users: [usersNavItems, CAN_MANAGE_USERS],
        'manage-payments': [managePaymentsNavItems, CAN_AUDIT_INVOICES],
        payments: [paymentsNavItems, CAN_VIEW_SITE_ANALYTICS],
    }

    const [navItems, navItemsPermission] = navItemsOptions[title.toLowerCase()]
        ? navItemsOptions[title.toLowerCase()]
        : []

    const showNavItems = navItems && navItemsPermission && isAuthorized({ permission: navItemsPermission })

    const validTitles = ['users', 'manage-payments', 'analytics', 'payments']
    let displayTitle = validTitles.some((t) => t === title) ? title.replace('-', ' ') : 'FS App Console'

    const location = useLocation()
    const isProfile = location.pathname.split('/')[2] === 'myprofile'

    if (isProfile) {
        displayTitle = 'My Profile'
    }

    return (
        <>
            <Headroom disableInlineStyles className={styles.headerNavContainer}>
                <header className={styles.headerNav}>
                    <div className={styles.navLeft}>
                        <Link to="/" className={styles.logoContainer}>
                            <Icon icon="FansidedAppLogo" size={3} />
                        </Link>

                        <h2 className={styles.pageTitle} data-testid="headerTitle">
                            {displayTitle}
                        </h2>
                        {showNavItems && (
                            <div className={styles.secondaryNav}>
                                <BuildNavItems navItems={navItems} activeClassName={styles.active} />
                            </div>
                        )}
                    </div>
                    <div className={styles.navRight}>
                        <Popover
                            render={() => <UserIconMenu />}
                            // @ts-ignore
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            key="userIcon"
                        >
                            <Image
                                src={imageService(profileImg, 32, 32)}
                                alt="My profile"
                                className={styles.userIcon}
                                height={32}
                                width={32}
                                data-testid="headerAvatarButton"
                            />
                        </Popover>
                    </div>
                </header>
            </Headroom>
        </>
    )
}

HeaderNav.defaultProps = {
    title: '',
    profilePic: '',
}

const stateToProps = ({ globalState }: any) => ({
    profilePic: globalState.currentUser?.data?.profilePic,
})

export default connect(stateToProps)(HeaderNav)
