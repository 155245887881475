import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Routes, Route, NavLink, Navigate, useLocation } from 'react-router-dom'

import api from '../../../api'
import GlobalStats from './ui/GlobalStats'
import BySiteStats from './ui/BySiteStats'
import { ButtonGroup, Button } from '../../../lib/framework'
import { useSiteList } from '../../../lib/utils/hooks'
import { CAN_AUDIT_INVOICES } from '../../../lib/utils/permissions'

import styles from './invoiceStats.module.scss'

type InvoiceStatsProps = {
    permissions: any
}

const InvoiceStats = ({ permissions }: InvoiceStatsProps) => {
    const [globalInvoiceStats, setGlobalInvoiceStats] = useState([])
    const [siteInvoiceStats, setSiteInvoiceStats] = useState([])
    const location = useLocation()

    const [siteList] = useSiteList()

    useEffect(() => {
        const getGlobal = async () => {
            const data = await api.payments.invoices.getGlobalStats()

            if (!data) return console.error('No global invoice data returned') // eslint-disable-line

            // @ts-expect-error ts-migrate(2345) FIXME: Type 'unknown' is not assignable to type 'never'.
            setGlobalInvoiceStats(Object.values(data).reverse())
        }

        const getBySite = async () => {
            let siteIds = permissions[CAN_AUDIT_INVOICES]

            siteIds = siteIds?.includes('0') ? [] : siteIds

            const data = await api.payments.invoices.getStatsBySite(siteIds)

            if (!data) return console.error('No site invoice data returned') // eslint-disable-line

            setSiteInvoiceStats(Object.values(data))
        }

        const getStatsOnMount = () =>
            location.pathname.includes('global')
                ? !globalInvoiceStats.length && getGlobal()
                : !siteInvoiceStats.length && getBySite()

        getStatsOnMount()
    }, [globalInvoiceStats.length, location.pathname, permissions, siteInvoiceStats.length])

    return (
        <>
            <Helmet>
                <title>FS App Console - Invoice Stats</title>
            </Helmet>
            <section className="contentBox">
                <ButtonGroup className={styles.viewButtons}>
                    <Button>
                        <NavLink to="global" activeClassName={styles.active}>
                            All
                        </NavLink>
                    </Button>
                    <Button>
                        <NavLink to="sites" activeClassName={styles.active}>
                            By Site
                        </NavLink>
                    </Button>
                </ButtonGroup>

                <Routes>
                    <Route
                        path="global"
                        element={<GlobalStats data={globalInvoiceStats} loading={!globalInvoiceStats.length} />}
                    />
                    <Route
                        path="sites"
                        element={
                            <BySiteStats
                                data={siteInvoiceStats}
                                loading={!siteInvoiceStats.length}
                                siteList={siteList}
                            />
                        }
                    />
                    <Route path="*" element={<Navigate to="sites" />} />
                </Routes>
            </section>
        </>
    )
}

const stateToProps = ({ globalState }: any) => ({
    permissions: globalState.currentUser.data.permissions,
})

export default connect(stateToProps)(InvoiceStats)
