import React, { MouseEvent } from 'react'
import cx from 'classnames'

import styles from './button.module.scss'
import SubmitButton from './SubmitButton'
import Icon from '../Icon'

type ButtonTypes = {
    size?: 'small' | 'normal' | 'large'
    iconSize?: number
    fill?: string
    type?:
        | 'button'
        | 'reset'
        | 'submit'
        | 'primary'
        | 'secondary'
        | 'success'
        | 'info'
        | 'warning'
        | 'danger'
        | 'plus'
        | 'close'
    disabled?: boolean
    children?: any
    className?: string
    linkStyle?: boolean
    onClick?: (e: MouseEvent<HTMLElement>) => void
    style?: ObjectType
}

const Button: React.FC<ButtonTypes> = (props) => {
    const {
        size = 'normal',
        iconSize = 1,
        fill = '',
        type: customType = 'primary',
        disabled = false,
        children,
        className = '',
        linkStyle = false,
        ...rest
    } = props

    if (customType === 'submit') return <SubmitButton buttonProps={props}>{children}</SubmitButton>

    if (customType === 'plus' || customType === 'close')
        return (
            <button
                className={cx(className, styles.button, customType === 'plus' ? styles.plus : styles.close)}
                type="button"
                disabled={disabled}
                {...rest}
            >
                <Icon icon="plus" size={iconSize} fill={fill && fill} />
            </button>
        )

    return (
        <button
            className={cx(className, styles.button, {
                [styles.primary]: customType === 'primary',
                [styles.secondary]: customType === 'secondary',
                [styles.success]: customType === 'success',
                [styles.info]: customType === 'info',
                [styles.warning]: customType === 'warning',
                [styles.danger]: customType === 'danger',
                [styles.small]: size === 'small',
                [styles.large]: size === 'large',
                [styles.linkStyle]: linkStyle,
            })}
            type="button"
            disabled={disabled}
            {...rest}
        >
            {children}
        </button>
    )
}

export default Button
