import { createAction } from 'redux-act'
import camelCase from 'lodash/camelCase'
import { subMonths, parseISO } from 'date-fns'
import store from '../../../store'
import { getDateOptions } from '../getDateOptions'

/**
 *
 * @param {string} constant SCREAMING_SNAKE_CASED string to identify action.
 *
 * @returns {object} An object with two properties to be spread into actions:
 *                  1) CamelCased constant key for constant-named action
 *                  2) The same key + 'Loading' to allow changing loading flag
 */
const makeAsyncAction = (constant: any) => {
    if (!constant.length) throw new Error('You must supply a constant to name your asyncAction')

    const camelCased = camelCase(constant)
    return {
        [camelCased]: createAction(constant),
        [`${camelCased}Loading`]: createAction(`${constant}_LOADING`),
    }
}

/**
 *
 * @param {string} key The string corresponding to the piece of state created.
 *                     Creates property with data & isLoading keys.
 * @param {*} [initialData={}] Initial state property data
 *
 * @returns {object} An object with the one key, to be spread into state, containing:
 *                  1) data property optionally set to initialData
 *                  2) isLoading flag defaulting to false
 */
const makeAsyncStateProperty = ({ key, initialData = {}, initialLoadingState = true }: any) => {
    if (!key.length) throw new Error('You must supply a key to name your asyncState property')

    return {
        [key]: {
            data: initialData,
            isLoading: initialLoadingState,
        },
    }
}

/**
 *
 * @param {object} actions Your redux actions
 *
 * @param {func} actionKey The action that will be dispatched to call the reducer function
 *
 * @param {string} stateKey The string corresponding to the piece of state to be updated
 *
 * @returns {object} An object with two keys, corresponding to the action and the loading action, and to be
 *                  spread into reducer, containing:
 *                  1) function to update supplied key.data with data argument
 *                  2) function to update supplied key.isLoading to bool argument
 */
const makeAsyncReducer = ({ actions, actionKey, stateKey }: any) => {
    if (!actions) throw new Error('You must supply actions to your asyncReducer')
    if (!actionKey.length) throw new Error('You must supply an action to your asyncReducer')
    if (!stateKey.length) throw new Error('You must supply a key to your asyncReducer')

    return {
        [actions[actionKey]]: (state: any, data: any) => ({
            ...state,
            [stateKey]: {
                ...state[stateKey],
                data,
            },
        }),
        [actions[`${actionKey}Loading`]]: (state: any, bool: any) => ({
            ...state,
            [stateKey]: {
                ...state[stateKey],
                isLoading: bool,
            },
        }),
    }
}

const getMonth = (offset = 0) => `0${new Date().getMonth() + offset}`.slice(-2)

const getYear = () => new Date().getFullYear()

const getDateForLastMonth = () => subMonths(parseISO(`${getYear()}-${getMonth(1)}-01`), 1)

const setDateOptions = () => {
    const dates = getDateOptions(null, null)

    // Hardcoding option to fetch all invoices before April 2019
    let dateOptions = [...dates, { value: '2019-03-31', label: 'Pre April 2019' }]

    return dateOptions
}

const getCurrentUserFromStore = (): UserData => store.getState().globalState.currentUser.data

const getRoleListFromStore = (): IRoleList => {
    const {
        roleList: { data },
    } = store.getState().globalState as any
    return data
}

const getPermissionListFromStore = (): IPermissionList => {
    const {
        permissionList: { data },
    } = store.getState().globalState as any
    return data
}

const initialUserDataShape: UserData = {
    id: '',
    firstName: '',
    lastName: '',
    fullName: '',
    displayName: '',
    email: '',
    bio: '',
    facebook: '',
    instagram: '',
    profilePic: '',
    sitesMeta: [],
    rolesMeta: [],
    wordpressMeta: [],
    roles: [],
    sites: [],
    twitter: '',
    createdAt: '',
    deletedAt: '',
}

export {
    makeAsyncAction,
    makeAsyncReducer,
    makeAsyncStateProperty,
    getMonth,
    getYear,
    getDateForLastMonth,
    setDateOptions,
    initialUserDataShape,
    getCurrentUserFromStore,
    getRoleListFromStore,
    getPermissionListFromStore,
}
