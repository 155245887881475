import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { Prompt, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'
import cx from 'classnames'

import api from '../../../../../api'
import { Button, InputFormik, ImageUpload, DropdownFormik } from '../../../../../lib/framework'
import {
    imageService,
    isAuthorized,
    UserDetailsSchema,
    getProfilePic,
    customClearProfileToast,
} from '../../../../../lib/utils'
import { CAN_MANAGE_USERS, CAN_VIEW_USERS, CAN_EDIT_USERS } from '../../../../../lib/utils/permissions'
import { formatUserDetails } from '../../../../../lib/utils/formatUserDetails'
import { countryData, stateData } from '../../../../../lib/utils/countryStateData'
import Image from '../../../../../lib/framework/Image'
import Icon from '../../../../../lib/framework/Icon'

import { UsersDetailContext } from '../../UsersDetailContext'

import styles from './userDetailsTab.module.scss'

type OwnUserDetailsTabProps = {
    initialValues?: {
        profilePic?: string
        email?: string
        firstName?: string
        lastName?: string
        bio?: string
        displayName?: string
        streetAddress1?: string
        streetAddress2?: string
        city?: string
        state?: string
        zip?: string
        country?: string
        twitter?: string
        facebook?: string
        instagram?: string
    }
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'UserDetailsTabProps' circularly refere... Remove this comment to see the full error message
type UserDetailsTabProps = OwnUserDetailsTabProps & typeof UserDetailsTab.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'UserDetailsTab' implicitly has type 'any' because... Remove this comment to see the full error message
const UserDetailsTab = ({ initialValues }: UserDetailsTabProps) => {
    const nav = useNavigate()
    const { profilePic, firstName, lastName, displayName } = initialValues

    const { userId, isDeactivated, isOwnProfile, currentUserId } = useContext(UsersDetailContext)

    const canViewUsers = isAuthorized({ permission: CAN_VIEW_USERS })

    const profileImg = getProfilePic(profilePic)

    const customDisplayName = displayName !== `${firstName} ${lastName}`

    /**
     *
     *
     * @param {object} values
     * @param {{ setSubmitting }} Formik functions
     */
    const handleSubmit = (values: any, { setSubmitting }: any) => {
        api.users.updateUserDetails(
            userId,
            { ...values, fullName: values.firstName + ' ' + values.lastName },
            true,
            initialValues,
            setSubmitting,
        )
    }

    const handleCountryChange = (e: any, setFieldValue: any) => {
        setFieldValue('country', e)

        if (e.value !== 'United States') {
            setFieldValue('country', e)
            setFieldValue('state', { label: '', value: '' })
        }
    }

    const handleNameChange = (e: any, setFieldValue: any, values: any) => {
        const { name, value } = e.target
        const { firstName: currentFirstName, lastName: currentLastName } = values

        setFieldValue(name, value)

        if (!customDisplayName) {
            const newDisplayName =
                name === 'firstName' ? `${value} ${currentLastName}` : `${currentFirstName} ${value}`
            setFieldValue('displayName', newDisplayName)
        }
    }

    const handleClearProfile = () => {
        const clearedValues = {
            twitter: '',
            facebook: '',
            instagram: '',
            profilePic: 'https://app.fansided.com/media/default-avatar.png',
            bio: '',
        }
        api.users.updateUserDetails(
            userId,
            { ...clearedValues, fullName: firstName + ' ' + lastName },
            true,
            initialValues,
        )
    }

    return (
        <>
            <Helmet>
                <title>FS App Console - {isOwnProfile ? 'My Profile' : 'User Details'}</title>
            </Helmet>
            <Formik
                initialValues={initialValues}
                validationSchema={UserDetailsSchema}
                enableReinitialize
                validateOnChange={false}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, values, dirty, setFieldValue }) => {
                    const isNotAdmin = !isAuthorized({ permission: CAN_MANAGE_USERS, siteId: '0' })
                    const canEdit = isAuthorized({ permission: CAN_EDIT_USERS })

                    const checkIfDisabled = () => {
                        let disabled = true

                        if (isDeactivated) return disabled

                        if (userId === currentUserId || isOwnProfile) {
                            disabled = false
                        } else if (!canViewUsers) {
                            // If this is not their profile page,
                            // and they don't have permission to view users,
                            // redirect.
                            toast.error('You are not authorized to view that page')
                            nav('/')
                        }

                        if (canEdit) {
                            disabled = false
                        }

                        return disabled
                    }

                    const isDisabled = checkIfDisabled()

                    return (
                        <Form autoComplete="off">
                            <Prompt
                                when={dirty}
                                message="You have unsaved changes, are you sure you want to leave?"
                            />
                            <div className={styles.userCard}>
                                <fieldset disabled={isDisabled} className={styles.picContainer}>
                                    <Image
                                        src={imageService(profileImg, 150, 150)}
                                        placeholder={imageService(profileImg, 16, 16)}
                                        alt="Profile"
                                        width={150}
                                        height={150}
                                        className={styles.thumbnail}
                                    />
                                    {!isDisabled && <ImageUpload name="profilePic" userId={userId} />}
                                </fieldset>

                                <fieldset disabled={isDisabled} className={styles.allNamesContainer}>
                                    <InputFormik
                                        name="firstName"
                                        label="First Name"
                                        onChange={(e: any) => handleNameChange(e, setFieldValue, values)}
                                        disabled={isDisabled}
                                    />
                                    <InputFormik
                                        name="lastName"
                                        label="Last Name"
                                        onChange={(e: any) => handleNameChange(e, setFieldValue, values)}
                                        disabled={isDisabled}
                                    />
                                    <InputFormik name="displayName" label="Display Name" disabled={isNotAdmin} />
                                    {isNotAdmin && (
                                        <span className={styles.description}>
                                            * Contact your director to customize display name.
                                        </span>
                                    )}
                                    <InputFormik name="email" label="Email" type="email" disabled={isNotAdmin} />
                                    <InputFormik name="bio" type="textarea" label="Bio" disabled={isDisabled} />
                                    <div className={styles.socialIcon}>
                                        <Icon icon="Twitter" fill="#fff" size={1.7} />
                                        <InputFormik
                                            name="twitter"
                                            type="text"
                                            label="Twitter Handle"
                                            placeholder="@example"
                                            containerClassName={styles.inputSpacing}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                    <div className={styles.socialIcon}>
                                        <Icon icon="Facebook" fill="#fff" size={1.7} />
                                        <InputFormik
                                            name="facebook"
                                            type="text"
                                            label="Facebook Username"
                                            placeholder="janedoe1"
                                            containerClassName={styles.inputSpacing}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                    <div className={styles.socialIcon}>
                                        <Icon icon="Instagram" fill="#fff" size={1.7} />
                                        <InputFormik
                                            name="instagram"
                                            type="text"
                                            label="Instagram Username"
                                            placeholder="johnsmith2"
                                            containerClassName={styles.inputSpacing}
                                            disabled={isDisabled}
                                        />
                                    </div>

                                    <DropdownFormik
                                        name="country"
                                        label="Country"
                                        options={countryData}
                                        onChange={(e: any) => handleCountryChange(e, setFieldValue)}
                                        isDisabled={isNotAdmin}
                                        containerClassName={styles.dropdowns}
                                    />

                                    <DropdownFormik
                                        name="state"
                                        label="State"
                                        options={stateData}
                                        isDisabled={isNotAdmin}
                                        containerClassName={cx(
                                            styles.dropdowns,
                                            values.country?.value !== 'United States' && styles.hideStateField,
                                        )}
                                    />
                                    <div className={styles.buttonContainer}>
                                        <Button
                                            type="submit"
                                            disabled={isSubmitting || isDisabled}
                                            className={styles.formButton}
                                        >
                                            Updat{isSubmitting ? 'ing' : 'e'} Profile
                                        </Button>
                                        <Button
                                            type="secondary"
                                            className={styles.formButton}
                                            onClick={() =>
                                                customClearProfileToast({
                                                    handleClear: handleClearProfile,
                                                })
                                            }
                                        >
                                            Clear Profile
                                        </Button>
                                    </div>
                                </fieldset>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

UserDetailsTab.defaultProps = {
    initialValues: {
        profilePic: '',
        bio: '',
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        email: '',
        firstName: '',
        lastName: '',
        displayName: '',
        twitter: '',
        facebook: '',
        instagram: '',
    },
}

const stateToProps = ({ userState, globalState }: any) => ({
    initialValues: userState.singleUserData.data ? formatUserDetails(userState.singleUserData.data) : {},
})

export default connect(stateToProps)(UserDetailsTab)
