import { handleError } from '../../lib/utils'
import paymentsHttp from '../../ManagePayments/utils/paymentsHttp'

interface IGetUserInvoices {
    (args: { userId: string; startDate: string; endDate: string; setPaymentsLoading?: Function }): Promise<any>
}

export const getUserInvoices: IGetUserInvoices = async ({ userId, startDate, endDate, setPaymentsLoading }) => {
    try {
        const { data = [] } = await paymentsHttp.get(`payments/contractor/invoices/${userId}`, {
            params: { startDate, endDate },
        })

        return data
    } catch (e) {
        // @ts-expect-error ts-migrate(2345) FIXME: Type 'Function' is not assignable to type 'null'.
        return handleError(e, setPaymentsLoading, true)
    }
}
