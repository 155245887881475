/* eslint-disable react/prop-types */
import React from 'react'

const globalInvoiceStatsColumns = [
    {
        Header: 'Date',
        headerClassName: 'sortable',
        id: 'date',
        width: 150,
        Cell: ({ original: { date } }: any) => <time dateTime={date}>{date}</time>,
    },
    {
        Header: 'Total Invoices',
        headerClassName: 'sortable',
        id: 'totalInvoices',
        accessor: (row: any) => row.invoices,
        width: 150,
        Cell: ({ original: { invoices } }: any) => invoices,
    },
    {
        Header: 'Amount',
        headerClassName: 'sortable',
        id: 'amount',
        accessor: (row: any) => row.amount,
        width: 150,
        Cell: ({ original: { amount } }: any) =>
            new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' }).format(amount),
    },
]

export default globalInvoiceStatsColumns
