import React, { useEffect, useState } from 'react'
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/react-flex-columns` if it ... Remove this comment to see the full error message
import { Columns, Column } from 'react-flex-columns'

import DetailItem from '../DetailItem'
import TopPosts from '../TopPosts'
import Comments from '../Comments'
import api from '../../../api'
import { setComment } from '../../../state/invoiceState'
import { UserIdTooltip } from '../../../lib/utils'

import styles from './styles.module.scss'

type Props = {
    details: any
}

const TRDetailView = ({ details }: Props) => {
    const [loading, setLoading] = useState(true)
    let { posts = [], comments = [] } = details

    useEffect(() => {
        if (!posts?.length && !comments?.length) {
            const cb = async () => {
                setLoading(true)
                await api.payments.invoices.getInvoiceDetails(details.id)
                setLoading(false)
            }
            cb()
        } else {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [comments.length, posts.length, details.id])

    const userComments = comments?.filter((comment: any) => comment.type === 'note' || comment.type === 'user')

    const systemComments = comments
        ?.filter((comment: any) => comment.type === 'system')
        // Show most recent first
        ?.sort((a: any, b: any) => b.createdAt - a.createdAt)

    const submitComment = (newComment: any) => {
        setComment(details.id, newComment)
        api.payments.invoices.createComment(details.id, newComment).catch((e) => e)
        console.log(`Submit Comment For ${details.id}`, newComment) // eslint-disable-line
    }

    return (
        <div className={styles.trDetailView}>
            <Columns gutterSize={2} className={styles.columns}>
                <Column flex>
                    <h3 className={styles.headerExpanded}>Additional Info</h3>
                    <hr />
                    <DetailItem label="Invoice ID">{details.id}</DetailItem>
                    <DetailItem label="WP Username">{details.username}</DetailItem>
                    <DetailItem label="User ID">
                        <UserIdTooltip userId={details.userId} />
                    </DetailItem>
                    <DetailItem label="Site ID">{details.siteId}</DetailItem>
                    <DetailItem label="Payment Model">{details.paymentModel}</DetailItem>
                    <DetailItem label="Transaction Type">{details.transactionType}</DetailItem>
                    <DetailItem label="Payout ID">{details.payoutId}</DetailItem>                    
                </Column>
                <Column flex>{loading ? <p>Loading...</p> : <TopPosts posts={posts} />}</Column>
                <Column flex>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <Comments
                            enableCommenting
                            title="Comments"
                            comments={userComments}
                            onSubmit={submitComment}
                            siteId={details.siteId}
                        />
                    )}
                </Column>
                <Column flex>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <Comments
                            title="System Log"
                            comments={systemComments}
                            onSubmit={submitComment}
                            siteId={details.siteId}
                        />
                    )}
                </Column>
            </Columns>
        </div>
    )
}

export default TRDetailView
