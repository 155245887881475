interface IApplyStyle {
    (applyIf: boolean | number | string | null, style: string): string
    (applyIf: string, style?: string): string
}

const applyStyle: IApplyStyle = (applyIf: any, style: any) => {
    if (!style) {
        return applyIf ? ' ' + applyIf : ''
    }

    return applyIf ? ' ' + style : ''
}

export default applyStyle
