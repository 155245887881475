import React, { useRef } from 'react'

import Input from '../Input'
import { InputTypes } from '../Input/Input'
import Button from '../Button'

import styles from './searchInput.module.scss'

const SearchInput: React.FC<
    InputTypes & { updateSearchState: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void }
> = ({ className, updateSearchState, ...rest }) => {
    const ref = useRef<any>()
    // const handleClear = () => {
    //     setSearchText('')
    // }

    return (
        <div ref={ref} className={`${styles.container} ${className}`}>
            <Input icon="Search" {...rest} />
            {/* <Button size="small" onClick={handleClear} linkStyle className={`${styles.button} ${styles.x}`}>
                X
            </Button> */}

            <Button onClick={updateSearchState} size="small" className={styles.button}>
                Go
            </Button>
        </div>
    )
}

export default SearchInput
