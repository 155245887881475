import camelCase from 'lodash/camelCase'

// @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
const camelCaseKeys = (obj: ObjectType) => {
    const ignoreKeys = ['permissions']

    if (Array.isArray(obj)) {
        return obj.map((v) => camelCaseKeys(v))
    }
    if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) =>
                !ignoreKeys.includes(key)
                    ? {
                          ...result,
                          [camelCase(key)]: camelCaseKeys(obj[key]),
                      }
                    : {
                          ...result,
                          [key]: obj[key],
                      },
            {},
        )
    }
    return obj
}

export default camelCaseKeys
