import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import snakeCase from 'lodash/snakeCase'

import Image from '../../../../../../lib/framework/Image'
import PrimaryRoleSelect from '../../../../../ui/PrimaryRoleSelect'
import SecondaryRoleSelect from '../../../../../ui/SecondaryRoleSelect'
import DeleteSite from '../../../../../ui/DeleteSite'
import EditableInputField from '../ui/EditableInputField/EditableInputField'
import { Dropdown } from '../../../../../../lib/framework'

import api from '../../../../../../api'

import { getDefaultTitleFromRoleSlug } from '../../../../../../lib/utils'
import { imageService, isAuthorized, getLastPublishDate } from '../../../../../../lib/utils'
import { CAN_MANAGE_USERS, CAN_EDIT_USERS } from '../../../../../../lib/utils/permissions'

import useManageContractorsUpdates from '../../../../ManageContractors/hooks/useManageContractorsUpdates'

import styles from '../siteDetailsTab.module.scss'

interface Props {
    item: SiteListSiteObject
    isOwnProfile: boolean
    isDeactivated: boolean
    sitesMeta: SitesMeta
    i: number
    userId: string
    roles: UserRoles
    userData: UserData
    setUserData: (a: any) => void
    roleList: IRoleList
}

const SiteRow: React.FC<Props> = ({
    item,
    isOwnProfile,
    isDeactivated,
    sitesMeta,
    i,
    userId,
    roles,
    userData,
    setUserData,
    roleList,
}) => {
    const { handleContractorCheck, handleRevShareChange } = useManageContractorsUpdates()

    const authorized = isAuthorized({
        permission: CAN_MANAGE_USERS,
        siteId: item.id,
    })

    const disabled = !authorized || isOwnProfile || isDeactivated
    const { revShare = 'none', contractor = false } = sitesMeta.find(({ id }) => id === item.id) || {}
    const { wordpressMeta = [] } = userData
    const lastPublishDate = getLastPublishDate(wordpressMeta, item.id)

    const revShareOptions = ['None', 'Contributor', 'Site Expert'].map((s) => ({ label: s, value: snakeCase(s) }))

    /**
     * Returns true if title is a custom title
     * @param {string} value
     * @return {bool}
     */
    const clearable = (value: string) => {
        if (roleList.map((r) => r.name).includes(value)) {
            return false
        }

        return true
    }

    /**
     * Filters through roles array to see if there's a custom title
     * Otherwise, use default title
     * @param {object} item
     * @return {string}
     */
    const getTitlesFromRoles = useCallback(
        (item: ObjectType) => {
            let { title = '', slug = '' } =
                roles.find((role) => role.siteId === item.id && role.type === 'primary') || {}
            if (!title) {
                title = getDefaultTitleFromRoleSlug(slug)
            }

            return title
        },
        [roles],
    )

    /**
     *
     *
     * @param {string} value
     * @param {string} newValue
     * @param {function} setNewValue
     * @param {string} siteId
     * @returns {Promise}
     */
    const handleTitleSubmit = async (
        value: string,
        newValue: string,
        setNewValue: (a: string) => void,
        siteId: string,
    ) => {
        if (newValue.length < 1 && !clearable(value)) {
            toast.error("You can't clear a default title! Try updating it?")
            return setNewValue(value)
        }

        if (newValue.length < 1 && clearable(value)) {
            const { slug } = roles.find((role) => role.title === value) || {}
            const defaultTitle = getDefaultTitleFromRoleSlug(slug)

            setNewValue(defaultTitle)
            api.users.updateUserTitle(userId, '', siteId, roles)
            return
        }

        api.users.updateUserTitle(userId, newValue, siteId, roles)
    }

    return (
        <div key={item.id}>
            <section className={`${styles.siteRow} horizontal-owl-extra-large`}>
                <div className={styles.nameAndLogoContainer}>
                    <div className={styles.nameAndLogoContainerImage}>
                        {!disabled && (
                            <DeleteSite siteId={item.id} siteName={item.name} userId={userId} key="deleteSite" />
                        )}
                        {item.logo && (
                            <Image
                                src={imageService(item.logo, 150, 150)}
                                placeholder={imageService(item.logo, 16, 16)}
                                alt={'Site Logo'}
                                width={48}
                                height={48}
                                className={styles.thumbnail}
                            />
                        )}
                    </div>
                    <div className={styles.siteNameContainer}>
                        <h4 className={styles.siteName}>{item.name}</h4>

                        {item.id !== '0' &&
                        isAuthorized({
                            permission: CAN_EDIT_USERS,
                            siteId: item.id,
                        }) ? (
                            <EditableInputField
                                siteId={item.id}
                                className={styles.title}
                                icon="Pencil"
                                maxLength={15}
                                handleSubmit={handleTitleSubmit}
                                value={getTitlesFromRoles(item)}
                                disabled={disabled}
                            />
                        ) : (
                            <span className={styles.title}>{getTitlesFromRoles(item)}</span>
                        )}
                    </div>
                </div>

                <div className={`${styles.column} ${styles.rolesContainer}`}>
                    <PrimaryRoleSelect
                        userRoles={roles}
                        userId={userId}
                        siteId={item.id}
                        siteName={item.name}
                        isDisabled={disabled}
                        containerClassName={styles.trRoleSelect}
                        hideDropdownIndicator={isOwnProfile}
                    />
                </div>
                <div className={`${styles.column} ${styles.rolesContainer} ${styles.secondaryRolesContainer}`}>
                    <SecondaryRoleSelect
                        userRoles={roles}
                        userId={userId}
                        siteId={item.id}
                        siteName={item.name}
                        isDisabled={disabled}
                        hideDropdownIndicator={isOwnProfile}
                    />
                </div>
                <div className={`${styles.column} ${styles.lastPost}`}>{lastPublishDate}</div>
                <div className={`${styles.column} ${styles.contractor}`}>
                    <input
                        type="checkbox"
                        disabled={disabled}
                        checked={contractor}
                        onChange={() => handleContractorCheck(userId, item.id, [userData], setUserData)}
                    />
                </div>
                <div className={`${styles.column} ${styles.revShare}`}>
                    <Dropdown
                        name="revShare"
                        options={revShareOptions}
                        isDisabled={!contractor || disabled}
                        value={revShareOptions.find((o) => o.value === revShare) || {}}
                        onChange={({ value }) =>
                            handleRevShareChange(userId, item.id, value, [userData], setUserData)
                        }
                    />
                </div>
            </section>
        </div>
    )
}

export default SiteRow
