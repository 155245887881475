/* eslint-disable react/forbid-prop-types */

import React from 'react'
import { useField } from 'formik'

type OwnProps = {
    options?: any[]
    disabled?: boolean
    required?: boolean
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof RadioSelectGroup.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'RadioSelectGroup' implicitly has type 'any' becau... Remove this comment to see the full error message
const RadioSelectGroup = (props: Props) => {
    const { options, disabled, required } = props
    const [{ name, value, onBlur }, , { setValue }] = useField(props)

    return (
        <fieldset disabled={disabled}>
            {options.map((type: any) => (
                <div key={type.label} style={{ paddingBottom: '0.4rem', paddingLeft: '0.5rem' }}>
                    <label htmlFor={type.value}>
                        <input
                            name={name}
                            type="radio"
                            id={type.value}
                            checked={type.value === value}
                            onChange={(option) => setValue(option.currentTarget.id)}
                            onBlur={onBlur}
                            value={value}
                            required={required}
                            style={{ verticalAlign: 'text-top' }}
                        />
                        <span style={{ marginLeft: '1rem' }}>{type.label}</span>
                    </label>
                </div>
            ))}
        </fieldset>
    )
}

RadioSelectGroup.defaultProps = {
    options: [],
    disabled: false,
    required: false,
}

export default RadioSelectGroup
