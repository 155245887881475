import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import cx from 'classnames'

import { Modal, Button, InputFormik } from '../../../lib/framework'
import api from '../../../api'
import { UpdatePasswordSchema } from '../../../lib/utils'
import styles from './updatePassword.module.scss'

type Props = {
    handleModal: (...args: any[]) => any
    isOwnProfile: boolean
}

const UpdatePassword = ({ handleModal, isOwnProfile }: Props) => {
    const [showRequirements, setShowRequirements] = useState(false)
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'globalState' does not exist on type 'Def... Remove this comment to see the full error message
    const { id: userId } = useSelector(({ globalState }) => globalState.currentUser.data)

    const handleSubmit = (values: any, { setSubmitting }: any) => {
        api.users.updateUserPassword({ userId, values, setSubmitting }).then(() => {
            handleModal()
            toast.success('Password update successful!')
        })
    }

    /**
     * Requirements for password
     *
     * @param {string} className
     */
    const requirements = (className: any) => (
        <ul className={cx(styles.requirementsList, className)}>
            <li>12 characters minimum</li>
            <li>at least one lowercase letter</li>
            <li>at least one uppercase letter</li>
            <li>at least one number</li>
        </ul>
    )

    // Not allowing admin password updates right now
    return isOwnProfile ? (
        <Modal handleModal={handleModal} className={styles.modal} ariaLabel="Update Password">
            <Formik
                initialValues={{
                    newPassword: '',
                    confirmPassword: '',
                }}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={UpdatePasswordSchema}
            >
                {({ isSubmitting }) => (
                    <Form className={styles.form}>
                        <h3>Select a strong, unique password.</h3>
                        <p>
                            Passwords must be at least 12 characters and must include at least one lowercase
                            letter, one uppercase letter, and one number.
                        </p>
                        <div className={styles.requirements}>
                            <InputFormik
                                containerClassName={styles.changePasswordInput}
                                label="New Password"
                                name="newPassword"
                                type="password"
                                required
                                onFocus={() => setShowRequirements(true)}
                                onBlur={() => setShowRequirements(false)}
                                autoComplete="new-password"
                            />
                            {requirements(showRequirements && styles.show)}
                        </div>

                        <InputFormik
                            containerClassName={styles.changePasswordInput}
                            label="Confirm New Password"
                            name="confirmPassword"
                            type="password"
                            required
                        />
                        <Button type="submit" disabled={isSubmitting} className={styles.submit}>
                            Updat{!isSubmitting ? 'e' : 'ing'} Password
                        </Button>
                    </Form>
                )}
            </Formik>
        </Modal>
    ) : null
}

export default UpdatePassword
