import React from 'react'
import { useAuth0 } from '../../../react-auth0-spa'

import AuthorizedLayout from '../../layouts/AuthorizedLayout'

/**
 * Ensures the HeaderNav is shown if we're logged in
 */
const SuspenseComponent = () => {
    const { isAuthenticated } = useAuth0()

    return isAuthenticated ? <AuthorizedLayout loading={true} component={() => null} /> : null
}

export default SuspenseComponent
