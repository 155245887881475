/* eslint-disable */
import React from 'react'

type OwnProps = {
    fill?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof MySites.defaultProps;
/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7022) FIXME: 'MySites' implicitly has type 'any' because it doe... Remove this comment to see the full error message
const MySites = ({ fill, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28.1 24" fill={fill} {...rest}>
        <path
            d="M23,17V6H0v11c0,1.1,0.9,2,2,2h19C22.1,19,23,18.1,23,17z M23,2c0-1.1-0.9-2-2-2H2C0.9,0,0,0.9,0,2v3h23V2z
			 M3,4C2.4,4,2,3.6,2,3s0.4-1,1-1s1,0.4,1,1S3.6,4,3,4z M6,4C5.4,4,5,3.6,5,3s0.4-1,1-1s1,0.4,1,1S6.6,4,6,4z M9,4C8.4,4,8,3.6,8,3
			s0.4-1,1-1s1,0.4,1,1S9.6,4,9,4z M26,5h-1.5C24.2,5,24,5.2,24,5.5V17c0,1.7-1.3,3-3,3H5.5C5.2,20,5,20.2,5,20.5V22
			c0,1.1,0.9,2,2,2h19c1.1,0,2-0.9,2-2V7C28,5.9,27.1,5,26,5z"
        />
    </svg>
)

MySites.defaultProps = {
    fill: '',
}

export default MySites
