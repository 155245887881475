import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import cx from 'classnames'
import FocusTrap from 'focus-trap-react'

import { Button } from '..'
import { useKeyPress } from '../../utils/hooks'
import styles from './modal.module.scss'

type Props = {
    children: React.ReactNode[] | React.ReactNode
    className?: string
    childOf?: any | React.ReactNode
    handleModal: (...args: any[]) => any
    ariaLabel?: string
    disableClose?: boolean
}

const Modal: React.FC<Props> = (props) => {
    const { children, className, childOf, handleModal, ariaLabel, disableClose, ...rest } = props
    const escPressed = useKeyPress('Escape')

    useEffect(() => {
        if (escPressed && !disableClose) {
            handleModal()
        }
    }, [escPressed, handleModal, disableClose])

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [])

    return createPortal(
        <FocusTrap active={!disableClose}>
            <aside
                className={styles.modalBackground}
                onClick={() => !disableClose && handleModal()} // Close on click outside of modal
                role="presentation"
                {...rest}
            >
                <div // eslint-disable-line
                    className={cx(styles.relative, styles.modalBox, styles.boxContent, className && className)}
                    aria-modal="true"
                    onClick={(e) => e.stopPropagation()}
                    tab-index={-1}
                    role="dialog"
                    aria-label={ariaLabel}
                >
                    <Button
                        className={disableClose ? styles.hide : styles.closeModal}
                        type="close"
                        onClick={handleModal}
                        aria-label="Close Modal"
                    />
                    {children}
                </div>
            </aside>
        </FocusTrap>,
        childOf,
    )
}

Modal.defaultProps = {
    className: '',
    childOf: document.querySelector('#root'),
    ariaLabel: '',
}

export default Modal
