import React, { useState } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'

import api from '../../../api'
import DropdownIndicator from '../DropdownIndicator'
import { Dropdown } from '../../../lib/framework'
import { checkPermsAndFormatOptions } from '../../../lib/utils'
import { formatCurrentRoles } from '../../../lib/utils/formatCurrentRolesAsDropdownValues'

import styles from './primaryRoleSelect.module.scss'

type Props = {
    userRoles: UserRoles
    siteId: string
    roleList: IRoleList
    className?: string
    containerClassName?: string
    siteName: string
    userId: string
    isDisabled?: boolean
    isLoading?: boolean
    hideDropdownIndicator?: boolean
}

const PrimaryRoleSelect: React.FC<Props> = ({
    userRoles,
    siteId,
    roleList,
    className,
    containerClassName,
    siteName,
    userId,
    isDisabled,
    isLoading,
    hideDropdownIndicator,
    ...rest
}) => {
    const [loading, setLoading] = useState(false)

    // These are the values that display in the input, AKA the user's current roles
    const userPrimaryRole = roleList.length
        ? formatCurrentRoles(userRoles, roleList, siteId, 'primary')
        : [{ label: 'Loading...', value: 'loading' }]

    /**
     *
     * @param {string} action
     * @param {string} removedValue
     * @param {string} option
     */
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'roleSlug' implicitly has an 'any'... Remove this comment to see the full error message
    const updateRole = async ({ value: roleSlug }) => {
        if (userPrimaryRole.some(({ value }: any) => value === roleSlug)) return

        setLoading(true)

        await api.users.addUserRoles({
            id: userId,
            roles: [roleSlug],
            sites: [siteId],
            type: 'primary',
        })

        setLoading(false)
    }

    const getPermittedRoles = checkPermsAndFormatOptions({
        roleList,
        siteId,
        type: 'primary',
    })

    return (
        <Dropdown
            name={`PrimaryRoleSelect - ${siteName}`}
            className={cx(styles.primaryRoleSelect, className && className)}
            // value takes an array of objects with value and label keys
            value={userPrimaryRole}
            options={getPermittedRoles}
            onChange={(newRole) => updateRole(newRole)}
            components={{ ...{ DropdownIndicator: !hideDropdownIndicator ? DropdownIndicator : null } }}
            isLoading={loading || isLoading}
            isDisabled={loading || isDisabled}
            containerClassName={cx(containerClassName && containerClassName)}
            {...rest}
        />
    )
}

PrimaryRoleSelect.defaultProps = {
    className: '',
    containerClassName: '',
    isDisabled: false,
    isLoading: false,
    hideDropdownIndicator: false,
}

const stateToProps = ({ globalState }: any) => ({
    roleList: globalState.roleList.data,
})

export default connect(stateToProps)(PrimaryRoleSelect)
