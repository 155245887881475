import React from 'react'

import styles from './thumbnail.module.scss'

type Props = {
    height?: number
    width?: number
    image: string
    alt: string
    className?: string
}

const Thumbnail: React.FC<Props> = (props) => {
    const { height, width, image, alt, className, ...rest } = props

    return (
        <img
            className={className ? `${styles.thumbnail} ${className}` : `${styles.thumbnail}`}
            src={image}
            alt={alt}
            height={height}
            width={width}
            {...rest}
        />
    )
}

Thumbnail.defaultProps = {
    className: '',
    height: 100,
    width: 100,
}

export default Thumbnail
