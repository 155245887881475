import React, { useRef, useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import 'react-tippy/dist/tippy.css'
import { Helmet } from 'react-helmet'
import { Tooltip } from 'react-tippy'
import { format, startOfMonth } from 'date-fns'

import { ManagePaymentsContext } from './ManagePaymentsContext'

import {
    getMetaData,
    selectAll as storeSelectAll,
    selectNone,
    updateSelectedStatus,
    deselectOne,
    setStatus as setInvoiceStatus,
} from '../../../state/invoiceState'
import { setExpanded } from '../../../state/filteringState'
import paymentsTableColumns from '../../utils/paymentsTableColumns'
import { customSubmitPaymentToast, isAuthorized } from '../../../lib/utils'
import { CAN_MANAGE_INVOICES, CAN_AUDIT_INVOICES } from '../../../lib/utils/permissions'

import PrimaryHeader from '../PrimaryHeader'
import { Table, Button } from '../../../lib/framework'
import Loading from '../../../lib/framework/Loading'
import TRDetailView from '../../ui/TRDetailView'
import Icon from '../../../lib/framework/Icon'
import RequireCommentModal from '../../ui/RequireCommentModal'
import { usePaymentsSiteList, useQueryString } from '../../../lib/utils/hooks'
import { hasPermission } from './submitForPaymentPermissions'
import api from '../../../api'

import styles from './dashboardHome.module.scss'

type OwnDashboardHomeProps = {
    data?: any[]
    metaData?: any
    expanded: any
    currentUserId: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'DashboardHomeProps' circularly referen... Remove this comment to see the full error message
type DashboardHomeProps = OwnDashboardHomeProps & typeof DashboardHome.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'DashboardHome' implicitly has type 'any' because ... Remove this comment to see the full error message
const DashboardHome = ({ data, metaData, expanded, currentUserId }: DashboardHomeProps) => {
    const [pageSize, setPageSize] = useState(25)
    const selectTable = useRef()

    const initialDate = format(startOfMonth(new Date()), 'yyyy-MM-dd')
    const initialSite = '-1'
    const initialStatus = 'all'
    const initialVertical = 'all'
    const initialInvoiceType = 'all'

    const [date, setDate] = useQueryString('date', initialDate)
    const [site, setSite] = useQueryString('site', initialSite)
    const [status, setStatus] = useQueryString('status', initialStatus)
    const [vertical, setVertical] = useQueryString('vertical', initialVertical)
    const [invoiceType, setInvoiceType] = useQueryString('type', initialInvoiceType)
    const [search, setSearch] = useState('')

    const [modal, setModal] = useState(null)

    /**
     * Get list of all sites and verticals
     */
    const withUnassigned = true
    const [siteOptions, sitesLoading, verticalOptions] = usePaymentsSiteList(withUnassigned)

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        if (params.get('date')) return

        setDate(initialDate)
        // eslint-disable-next-line
    }, [date])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        if (params.get('site')) return

        setSite(initialSite)
        // eslint-disable-next-line
    }, [site])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        if (params.get('status')) return

        setStatus(initialStatus)
        // eslint-disable-next-line
    }, [status])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        if (params.get('vertical')) return

        setVertical(initialVertical)
        // eslint-disable-next-line
    }, [vertical])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        if (params.get('invoiceType')) return

        setInvoiceType(invoiceType)
    }, [invoiceType, setInvoiceType])

    /**
     * Close rows on filter change
     */
    useEffect(() => {
        setExpanded({})
    }, [date, site, status, vertical, invoiceType])

    const handleRowExpanded = (_: any, index: any) => {
        // Set to false if already exists (i.e., it's open), else true (open)
        setExpanded({ [index]: !expanded[index] })
    }

    /**
     * Selects add invoices on the current page
     */
    const selectAll = () => {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        const { page, pageSize, sortedData } = selectTable.current.getResolvedState()
        const startIndex = pageSize * page
        const endIndex = pageSize * page + pageSize - 1
        storeSelectAll(startIndex, endIndex, sortedData)
    }

    const loading = !Array.isArray(data) || sitesLoading
    let useData = loading ? [] : data

    if (search) {
        useData = useData.filter(
            (row: any) =>
                row.name.toLowerCase().includes(search.toLowerCase()) || row.userId.toString().includes(search),
        )
    }

    const toolTipText = {
        ineligible: 'Contributor or expert does not meet eligibility criteria',
        flagged: 'Invoice has been flagged for review',
        error: 'Invoice could not be paid out through Payoneer',
        noAccount: 'This user does not have a Payoneer account',
        pending: 'Invoice is pending an audit',
        audited: 'Invoice has been audited by a director',
        approved: 'Invoice has been approved by ops team',
        export: 'Invoice is ready to export to Payoneer',
        paid: 'Invoice has been paid',
    }

    /**
     * @param {object} option Date object
     */
    const filterDate = (option: any) => setDate(option.value)

    /**
     * @param {object} option Site object
     */
    const filterSite = (option: any) => setSite(option.value)

    /**
     * @param {object} option Status object
     */
    const filterStatus = (option: any) => setStatus(option.value)

    /**
     * @param {object} option Vertical object
     */
    const filterVertical = (option: any) => setVertical(option.value)

    const filterInvoiceType = (option: any) => setInvoiceType(option.value)

    const filterSearch = (e: any) => setSearch(e.target.value)

    const handleClear = useCallback(() => {
        setDate(initialDate)
        setSite(initialSite)
        setStatus(initialStatus)
        setVertical(initialVertical)
        setInvoiceType(initialInvoiceType)
        // eslint-disable-next-line
    }, [])

    const contextValues = {
        date,
        site,
        status,
        search,
        vertical,
        invoiceType,
        filterDate,
        filterSite,
        filterStatus,
        filterVertical,
        filterInvoiceType,
        filterSearch,
        handleClear,
    }

    const handleRequireComment = (invoiceId: any, value: any) => {
        // @ts-expect-error ts-migrate(2345) FIXME: Object literal may only specify known properties, ... Remove this comment to see the full error message
        setModal({ invoiceId, value })
    }

    const handleStatusChange = (invoiceId: any, value: any) => {
        if (value === 'flagged') {
            handleRequireComment(invoiceId, value)
        } else {
            setInvoiceStatus(invoiceId, value)
        }
    }

    const handleSubmitForPayment = () => {
        api.payments.invoices.submitForPayment()
    }

    return (
        <ManagePaymentsContext.Provider value={contextValues}>
            <PrimaryHeader siteOptions={siteOptions} verticalOptions={verticalOptions} />
            <div className={`${styles.dashboardHome} contentBox`}>
                <Loading loading={loading} />
                <Helmet>
                    <title>FS App Console - Payments</title>
                </Helmet>
                <Table
                    loading={loading}
                    loadingText={''}
                    data={useData}
                    columns={paymentsTableColumns(handleStatusChange, siteOptions)}
                    collapseOnDataChange={false}
                    // If this pagination is turned on, be sure to also make
                    // changes in CSS for this table (documented at the CSS
                    // file). Also, I couldn't figure out how to actually turn
                    // off pagination. The `showPagination` only determines if
                    // it's shown. So I had to add a big number to `defaultPageSize`
                    // in order to ensure pagination doesn't happen.
                    // showPagination={false}
                    defaultPageSize={pageSize}
                    // Don't make a bunch of blank rows when we are lacking data
                    minRows={5}
                    noDataText={<span>No invoices found</span>}
                    expanded={expanded}
                    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
                    onExpandedChange={(newExpanded, index, event) => handleRowExpanded(newExpanded, index, event)}
                    SubComponent={({ row: { _original: original } }: any) => <TRDetailView details={original} />}
                    onPageChange={() => setExpanded({})}
                    onPageSizeChange={(size: any) => setPageSize(size)}
                    getTrProps={(_: any, rowInfo: any) => {
                        if (!rowInfo) return {}

                        let background

                        if (rowInfo.original.selected) background = '#d9d9fd'

                        return {
                            ...(rowInfo.original.selected && { onClick: () => deselectOne(rowInfo.index) }),
                            style: { background },
                        }
                    }}
                    ref={selectTable}
                />
                {!loading && (
                    <footer className={`${styles.footer} horizontal-owl`}>
                        <div className="horizontal-owl">
                            <Button className="button" onClick={selectAll}>
                                Select All
                            </Button>
                            <Button className="button" onClick={selectNone}>
                                Select None
                            </Button>
                            {isAuthorized({ permission: CAN_AUDIT_INVOICES }) && (
                                <Button
                                    className="button"
                                    type="success"
                                    disabled={metaData.selectedRows === undefined || metaData.selectedRows === 0}
                                    onClick={() => updateSelectedStatus('audited')}
                                    key="markAudit"
                                >
                                    Mark Audited
                                </Button>
                            )}
                            {isAuthorized({ permission: CAN_MANAGE_INVOICES, siteId: site.id }) && (
                                <Button
                                    className="button"
                                    type="success"
                                    disabled={metaData.selectedRows === undefined || metaData.selectedRows === 0}
                                    onClick={() => updateSelectedStatus('approved')}
                                    key="markApproved"
                                >
                                    Mark Approved
                                </Button>
                            )}
                            <Button className="button" type="success" key="createInvoice">
                                <Link to={`invoice`} style={{ color: '#fff' }}>
                                    Create Invoice
                                </Link>
                            </Button>
                            {hasPermission(currentUserId) && (
                                <Button
                                    className="button"
                                    type="success"
                                    key="submitForPayment"
                                    onClick={() =>
                                        customSubmitPaymentToast({
                                            onConfirm: handleSubmitForPayment,
                                        })
                                    }
                                >
                                    Submit for Payment
                                </Button>
                            )}
                        </div>
                        <div>
                            {!!metaData.totalRows && (
                                <span>
                                    <strong>
                                        {metaData.selectedRows} of {metaData.totalRows}
                                    </strong>{' '}
                                    Records Selected
                                </span>
                            )}
                        </div>
                    </footer>
                )}
                <div className={styles.iconContainer}>
                    <ul className={styles.legend}>
                        <li>
                            <Tooltip title={toolTipText.ineligible}>
                                <div className={styles.legendItem}>
                                    <Icon icon="ineligible-circle" color="red" />
                                    <span>Ineligible</span>
                                </div>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={toolTipText.flagged}>
                                <div className={styles.legendItem}>
                                    <Icon icon="flag" color="red" className={styles.flagged} />
                                    <span>Flagged</span>
                                </div>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={toolTipText.error}>
                                <div className={styles.legendItem}>
                                    <Icon icon="error-circle" color="red" />
                                    <span>Error</span>
                                </div>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={toolTipText.noAccount}>
                                <div className={styles.legendItem}>
                                    <Icon icon="error-circle" color="orange" />
                                    <span>No Account</span>
                                </div>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={toolTipText.pending}>
                                <div className={styles.legendItem}>
                                    <Icon icon="pending-circle" color="orange" />
                                    <span>Pending</span>
                                </div>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={toolTipText.audited}>
                                <div className={styles.legendItem}>
                                    <Icon icon="audited-circle" color="green" />
                                    <span>Audited</span>
                                </div>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={toolTipText.approved}>
                                <div className={styles.legendItem}>
                                    <Icon icon="approved-circle" color="green" />
                                    <span>Approved</span>
                                </div>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={toolTipText.export}>
                                <div className={styles.legendItem}>
                                    <Icon icon="export-circle" color="blue" />
                                    <span>Export</span>
                                </div>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title={toolTipText.paid}>
                                <div className={styles.legendItem}>
                                    <Icon icon="approved-circle" color="blue" />
                                    <span>Paid</span>
                                </div>
                            </Tooltip>
                        </li>
                    </ul>
                </div>
            </div>
            {/* @ts-expect-error ts-migrate(2345) FIXME: Type 'boolean' is not assignable to type 'null'. */}
            {modal && <RequireCommentModal handleModal={() => setModal((b) => !b)} data={modal} />}
        </ManagePaymentsContext.Provider>
    )
}

DashboardHome.defaultProps = {
    data: null,
    metaData: {},
}

const stateToProps = ({ globalState, paymentsState: { invoiceState, filteringState } }: any) => ({
    data: invoiceState.invoiceData.data,
    metaData: getMetaData(invoiceState.invoiceData),
    expanded: filteringState.expanded,
    currentUserId: globalState.currentUser.data.id,
})

export default connect(stateToProps)(DashboardHome)
