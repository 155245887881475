/* eslint-disable */
import React from 'react'

type OwnProps = {
    fill?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Users.defaultProps;
/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7022) FIXME: 'Users' implicitly has type 'any' because it does ... Remove this comment to see the full error message
const Users = ({ fill, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 28 18" fill={fill} {...rest}>
        <g>
            <path
                d="M9.1,11c-0.3-0.1-0.6-0.3-1-0.4c-0.3-0.1-0.5-0.3-0.6-0.6c-0.1-0.4,0-0.9,0.1-1c0,0,0.8-0.9,0.8-2.9
			c0-0.9,0.1-3.6-3-3.6c-3.1,0-3,2.7-3,3.6c0,2,0.8,2.9,0.8,3C3.4,9.2,3.6,9.7,3.5,10c-0.1,0.2-0.3,0.4-0.6,0.6
			c-3,1-2.8,2.5-2.8,3.1v1c0,0.5,0.4,0.8,0.8,0.8h1.3c0.1-0.3,0.2-0.7,0.5-1c0,0,1.4-1.6,5.2-3C8.2,11.3,8.6,11.2,9.1,11z
			 M17.7,11.7c-0.6-0.3-1.1-0.6-1.2-1C16.3,9.9,17.1,9,17.1,9c0.1-0.1,1.4-1,1.4-3.9C18.5,2.9,17.9,0,14,0c-3.9,0-4.5,2.9-4.5,5.2
			C9.5,8,10.9,9,10.9,9c0,0,0.8,0.9,0.6,1.7c-0.1,0.4-0.5,0.8-1.2,1c-4.6,1.4-7.3,3-7.3,4.4v1.1C3,17.7,3.5,18,3.9,18h20.2
			c0.4,0,0.9-0.3,0.9-0.9v-1.1C25,14.8,22.3,13.1,17.7,11.7z M25.2,10.6c-0.4-0.2-0.6-0.4-0.6-0.6c-0.1-0.4,0.1-0.8,0.2-0.9
			c0,0,0.8-1,0.8-3c0-0.9,0.1-3.6-3-3.6c-3.1,0-3,2.7-3,3.6c0,2,0.8,2.9,0.8,2.9c0.1,0.1,0.2,0.6,0.1,1c-0.1,0.2-0.3,0.4-0.6,0.6
			c-0.4,0.1-0.7,0.3-1,0.4c0.5,0.2,0.9,0.3,1.4,0.5c3.7,1.4,5.2,3,5.2,3c0.2,0.3,0.4,0.7,0.5,1h1.3c0.4,0,0.8-0.3,0.8-0.8v-1
			C28,13.1,28.1,11.6,25.2,10.6z"
            />
        </g>
    </svg>
)

Users.defaultProps = {
    fill: '',
}

export default Users
