import React from 'react'

type OwnProps = {
    fill?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ViewListOptions.defaultProps

/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7022) FIXME: 'ViewListOptions' implicitly has type 'any' becaus... Remove this comment to see the full error message
const ViewListOptions = ({ fill, ...rest }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={fill} {...rest}>
        <path d="M4 14h4v-4H4v4zm0 5h4v-4H4v4zM4 9h4V5H4v4zm5 5h12v-4H9v4zm0 5h12v-4H9v4zM9 5v4h12V5H9z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
)

ViewListOptions.defaultProps = {
    fill: '',
}

export default ViewListOptions
