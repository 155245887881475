/* eslint-disable */
import React from 'react'

type OwnProps = {
    fill?: string;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Twitch.defaultProps;
/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7022) FIXME: 'Twitch' implicitly has type 'any' because it does... Remove this comment to see the full error message
const Twitch = ({ fill, ...rest }: Props) => (
    <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill} {...rest}>
        <title>Twitch icon</title>
        <path d="M2.089 0L.525 4.175v16.694h5.736V24h3.132l3.127-3.132h4.695l6.26-6.258V0H2.089zm2.086 2.085H21.39v11.479l-3.652 3.652H12l-3.127 3.127v-3.127H4.175V2.085z" />
        <path d="M9.915 12.522H12v-6.26H9.915v6.26zm5.735 0h2.086v-6.26H15.65v6.26z" />
    </svg>
)

Twitch.defaultProps = {
    fill: '',
}

export default Twitch
