import React from 'react'
import { Button } from '../../framework'
import { customToast } from '.'

import styles from './customToast.module.scss'

interface RoleDeleteTypes {
    closeToast: (...args: any[]) => any
    removeFunction: (...args: any[]) => void
    siteName: string
    userName: string
    roleName?: string
}

/**
 *
 * @param {object} props All our props.
 * @param {func} props.closeToast Func supplied by react-toastify.
 * @param {func} props.removeFunction Custom func to remove the role.
 * @param {string} props.siteName The name of the site for this role.
 * @param {string} props.userName The name of the user selected.
 * @returns {node}
 */
const RoleDelete = ({ closeToast, removeFunction, siteName, userName, roleName = '' }: RoleDeleteTypes) => (
    <div className={styles.modal}>
        <h4>
            {roleName
                ? `Are you sure you want to remove ${userName} as a ${roleName} on ${siteName}?`
                : `Are you sure you want to remove all roles from ${userName} on ${siteName}?`}
        </h4>
        <div className={styles.buttonContainer}>
            <Button type="danger" onClick={removeFunction} className={styles.buttonYes}>
                Yes, remove
            </Button>
            <Button type="primary" onClick={closeToast} className={styles.buttonNo}>
                {'No, cancel'}
            </Button>
        </div>
    </div>
)

const customRoleDeleteToast = (props: any) =>
    customToast(
        { Component: RoleDelete, ...props },
        {
            autoClose: false,
            className: styles.deleteSite,
        },
    )

export default customRoleDeleteToast
