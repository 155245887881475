import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from '../hooks'

const ScrollToTop = ({ children }: any) => {
    const { pathname } = useLocation()
    const prevLocation = usePrevious(pathname)

    useEffect(() => {
        if (prevLocation !== pathname) {
            window.scrollTo(0, 0)
        }
    }, [pathname, prevLocation])

    return children || null
}

export default ScrollToTop
