import React from 'react'
import cx from 'classnames'
import { useTransition, animated } from 'react-spring'

import styles from './errorComponent.module.scss'

type Props = {
    focused: boolean
    error?: string
    showErrorsInPopover?: boolean
    errorClassName?: string
}

const ErrorComponent = ({ focused, error, showErrorsInPopover, errorClassName }: Props) => {
    const transitions = useTransition(error, null, {
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    })

    const errorPopoverStyles = showErrorsInPopover
        ? {
              height: '4rem',
              display: 'flex',
              alignItems: 'center',
              background: '#f0f0f0',
              borderRadius: '.3rem',
              padding: '.5rem',
              boxShadow: focused
                  ? '0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)'
                  : '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
          }
        : {}

    return transitions.map(
        ({ item, props, key }) =>
            item && (
                <div key={key} className={cx(errorClassName && errorClassName, styles.error)}>
                    <animated.div
                        style={{
                            position: 'absolute',
                            height: '100%',
                            willChange: 'opacity',
                            width: '100%',
                            ...errorPopoverStyles,
                            ...props,
                        }}
                    >
                        <em>{item}</em>
                    </animated.div>
                </div>
            ),
    )
}

export default ErrorComponent
