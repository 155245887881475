import React from 'react'
import { Helmet } from 'react-helmet'

import styles from './notFound.module.scss'
import yeti from './yeti-404.png'

const NotFound = () => (
    <div className={`${styles.unauthorizedLayout} contentBox`}>
        <Helmet>
            <title>FS App Console - Page Not Found</title>
        </Helmet>
        <img src={yeti} alt="404 Yeti" data-testid="404" />
        <h1>Oh no!</h1>
        <h4>That page was not found.</h4>
    </div>
)

export default NotFound
