import React, { useEffect } from 'react'
import { toast } from 'react-toastify'

import { useKeyPress } from '../../utils/hooks'

type OwnCustomToastProps = {
    Component: (...args: any[]) => any
    closeToast: (...args: any[]) => any
}

const CustomToast = ({ Component, closeToast, ...props }: OwnCustomToastProps) => {
    const escPressed = useKeyPress('Escape')

    useEffect(() => {
        if (escPressed) {
            closeToast()
        }
    }, [closeToast, escPressed])

    return (
        <div>
            <Component {...props} />
        </div>
    )
}

/**
 *
 * @param {object} props
 * @param {object} config

 * @returns {string} toast id
 */
export const customToast = ({ Component, ...props }: any, config: any) =>
    toast(<CustomToast Component={Component} {...props} />, config)

export default customToast
