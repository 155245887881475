import React from 'react'

type OwnProps = {
    fill?: string
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof YouTube.defaultProps

/* eslint-disable max-len */
// @ts-expect-error ts-migrate(7022) FIXME: 'YouTube' implicitly has type 'any' because it doe... Remove this comment to see the full error message
const YouTube = ({ fill, ...rest }: Props) => (
    <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={fill} {...rest}>
        <title>YouTube icon</title>
        <path d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z" />
    </svg>
)

YouTube.defaultProps = {
    fill: '',
}

export default YouTube
