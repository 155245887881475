import { Route, Routes } from 'react-router-dom'
import React, { lazy } from 'react'
import { useSelector } from 'react-redux'

import { AuthenticatedRoute } from '.'
import Dashboard from '../../layouts/Dashboard'
import UsersRouter from '../../../Users'
import ManagePaymentsRouter from '../../../ManagePayments/ManagePaymentsRouter'
import PaymentsRouter from '../../../Analytics/AnalyticsRouter'
import SSORedirect from '../components/SSORedirect'
import Logout from '../components/Logout'

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ '../../layouts/NotFound'))

const BuildRoutes = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'globalState' does not exist on type 'Def... Remove this comment to see the full error message
    const { deletedAt } = useSelector(({ globalState }) => globalState.currentUser?.data || {})

    return (
        <Routes>
            {/* Index - Dashboard */}
            <AuthenticatedRoute path="/" component={Dashboard} />

            {/* SSO Routing */}
            <AuthenticatedRoute path="/sso" component={SSORedirect} />

            <Route path="/logout" element={<Logout />} />

            {!deletedAt && (
                <>
                    {/* Microservice Routes */}
                    <AuthenticatedRoute path="users/*" component={UsersRouter} />
                    <AuthenticatedRoute path="manage-payments/*" component={ManagePaymentsRouter} />
                    <AuthenticatedRoute path="payments/*" component={PaymentsRouter} />
                </>
            )}

            {/* 404 Routing */}
            <AuthenticatedRoute path="*" component={NotFound} />
        </Routes>
    )
}

export default BuildRoutes
