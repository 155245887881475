import React, { useState } from 'react'
import cx from 'classnames'
import PopoverUI from '@material-ui/core/Popover'

import styles from './popover.module.scss'

type Props = {
    children: React.ReactNode[] | React.ReactNode
    className?: string
    render: (...args: any[]) => any
    handleToggleOpen?: ((...args: any[]) => any) | null
    open?: boolean | undefined
    anchorOrigin?: {
        vertical: number | 'top' | 'center' | 'bottom'
        horizontal: number | 'center' | 'left' | 'right'
    }
}

const Popover: React.FC<Props> = ({ render, children, className, open, handleToggleOpen, ...rest }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const isOpen = open !== undefined ? open : Boolean(anchorEl)

    const handleClick = ({ currentTarget }: any) => {
        setAnchorEl(currentTarget)
        handleToggleOpen && handleToggleOpen()
    }

    const handleClose = () => {
        setAnchorEl(null)
        handleToggleOpen && handleToggleOpen()
    }

    const id = isOpen ? 'simple-popover' : undefined

    const newChildren = React.Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement<any>, {
            onClick: handleClick,
            variant: 'contained',
            'aria-owns': id,
            'aria-haspopup': true,
        }),
    )

    const PaperProps = {
        className: cx(styles.paper, className && className),
    }

    return (
        <>
            {newChildren}

            <PopoverUI
                id={id}
                open={isOpen}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={PaperProps}
                {...rest}
            >
                {render()}
            </PopoverUI>
        </>
    )
}

Popover.defaultProps = {
    className: '',
    open: undefined,
    handleToggleOpen: null,
}

export default Popover
